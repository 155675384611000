import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Input, Button, FormFeedback } from 'reactstrap';
import { Alert } from 'reactstrap';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import SyncLoader from 'react-spinners/SyncLoader';
import '../ManageConsent/manage-consent.scss';
import { SIGNED_DPA_QUERY } from '../../graphql/signedDpaQuery';
import { SIGN_DPA } from '../../graphql/signDpa';
import Footer from '../../components/Footer.js/Footer';
import FontLoader from '../LegalSite/FontLoader';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import Moment from 'react-moment';
import ZoomIn from 'react-icons/lib/md/add';
import ZoomOut from 'react-icons/lib/md/remove';
import AddSignature from '../../components/Modals/AddSignature';
import DocumentSigned from '../../components/Modals/DocumentSigned';
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';

class SignedDpa extends Component {
  static propTypes = {};

  state = {
    subdomain: '',
    isConfirmationModalVisible: false,
    isDocShowing: true,
    isZoomedIn: false,
    isShowSignatureModalVisible: false,
    signature: null,
    formErrors: {},
    isDocumentModalVisible: false,
    token: null,
    dpaLink: null,
  };

  componentDidMount() {
    let subdomain = this.props.subdomain;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');

    this.setState({
      token,
    });

    if (
      this.props.location.state &&
      this.props.location.state.isConfirmation === true
    ) {
      this.setState({
        isDocShowing: false,
        isConfirmationModalVisible: true,
      });
    }

    this.setState(
      {
        subdomain,
      },
      () => {
        this.props.data.refetch({ subdomain: this.state.subdomain });
      }
    );
  }

  showSignatureModal = () => {
    this.setState({
      isShowSignatureModalVisible: !this.state.isShowSignatureModalVisible,
    });
  };

  showDocumentModal = () => {
    this.setState({
      isDocumentModalVisible: !this.state.isDocumentModalVisible,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalVisible: !this.state.isConfirmationModalVisible,
    });
  };

  formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    date = dd + '/' + mm + '/' + yyyy;
    return date;
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const fullName = formData.get('fullName');

    let hasFormErrors = false;
    let formErrors = {};

    if (!fullName) {
      hasFormErrors = true;
      formErrors.fullName = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .signDPA({
        variables: {
          dpaToken: this.state.token,
          commencementDate: new Date(),
          signature: this.state.signature,
          name: fullName,
        },
      })
      .then(({ data }) => {
        this.setState({
          isDocShowing: false,
          dpaLink: data.signDpa.downloadLink,
        });

        this.showDocumentModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { loading, error } = this.props.data;

    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    const actualLegalsitePages =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.actualPages;

    const createdDate =
      actualLegalsitePages &&
      actualLegalsitePages.map((item) =>
        item.title === 'Data Processing Agreement' ? item.createdAt : null
      );

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="manage-consent">
        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.titleFont
              ? publicLegalsiteCustomizationSettings.titleFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyFont
              ? publicLegalsiteCustomizationSettings.bodyFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.buttonFont
              ? publicLegalsiteCustomizationSettings.buttonFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.subtitleFont
              ? publicLegalsiteCustomizationSettings.subtitleFont
              : 'Roboto'
          }
        />

        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
        />

        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font4={'Roboto'}
        />

        <LegalsiteNavbar subdomain={this.props.subdomain} />

        {!this.state.isDocShowing ? (
          <div
            className="manage-consent__container"
            style={{
              background: publicLegalsiteCustomizationSettings.background2
                ? publicLegalsiteCustomizationSettings.background2
                : null,
            }}
          >
            <div
              className="manage-consent__header"
              style={{
                background: publicLegalsiteCustomizationSettings.background1
                  ? publicLegalsiteCustomizationSettings.background1
                  : null,
              }}
            >
              <div className="container">
                <h1
                  className="manage-consent__header-title"
                  style={{
                    color: publicLegalsiteCustomizationSettings.titleColor
                      ? publicLegalsiteCustomizationSettings.titleColor
                      : null,
                    fontSize: publicLegalsiteCustomizationSettings.titleSize
                      ? publicLegalsiteCustomizationSettings.titleSize
                      : 56,
                    fontFamily: publicLegalsiteCustomizationSettings.titleFont
                      ? publicLegalsiteCustomizationSettings.titleFont
                      : null,
                  }}
                >
                  Signed Data Processing Agreement
                </h1>
                <p
                  className="manage-consent__header-text"
                  style={{
                    color: publicLegalsiteCustomizationSettings.bodyColor
                      ? publicLegalsiteCustomizationSettings.bodyColor
                      : null,
                    fontSize: publicLegalsiteCustomizationSettings.bodySize
                      ? publicLegalsiteCustomizationSettings.bodySize
                      : 16,
                    fontFamily: publicLegalsiteCustomizationSettings.bodyFont
                      ? publicLegalsiteCustomizationSettings.bodyFont
                      : null,
                  }}
                >
                  If you want to receive a signed copy, complete this form to
                  download a copy of
                  <br />
                  our Data Processing Agreement, already signed by us and ready
                  for your signature
                </p>
              </div>
            </div>

            {!this.state.signature ? (
              <div className="manage-consent__content container bg-transparent">
                <Form className="manage-consent__form">
                  <h2
                    className="manage-consent__subtitle"
                    style={{
                      color: publicLegalsiteCustomizationSettings.subtitleColor
                        ? publicLegalsiteCustomizationSettings.subtitleColor
                        : null,
                      fontSize: publicLegalsiteCustomizationSettings.subtitleSize
                        ? publicLegalsiteCustomizationSettings.subtitleSize
                        : null,
                      fontFamily: publicLegalsiteCustomizationSettings.subtitleFont
                        ? publicLegalsiteCustomizationSettings.subtitleFont
                        : null,
                    }}
                  >
                    Personal information
                  </h2>
                  <p
                    className="manage-consent__subtext"
                    style={{
                      color: publicLegalsiteCustomizationSettings.bodyColor
                        ? publicLegalsiteCustomizationSettings.bodyColor
                        : null,
                      fontSize: publicLegalsiteCustomizationSettings.bodySize
                        ? publicLegalsiteCustomizationSettings.bodySize
                        : 16,
                      fontFamily: publicLegalsiteCustomizationSettings.bodyFont
                        ? publicLegalsiteCustomizationSettings.bodyFont
                        : null,
                    }}
                  >
                    Fill in the fields with your data
                  </p>

                  <FormGroup>
                    <Input type="text" name="name" placeholder="Full name" />
                  </FormGroup>

                  <FormGroup>
                    <Input type="email" name="email" placeholder="E-mail" />
                  </FormGroup>

                  <div className="d-flex">
                    <FormGroup className="mr-3">
                      <Input type="text" name="name" placeholder="Company" />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" name="name" placeholder="Job title" />
                    </FormGroup>
                  </div>
                </Form>
              </div>
            ) : null}
            <ConfirmationModal
              isConfirmationModalVisible={this.state.isConfirmationModalVisible}
              showConfirmationModal={this.showConfirmationModal}
              subdomain={this.props.subdomain}
            />
          </div>
        ) : (
          <div
            className={`${this.state.isZoomedIn ? '-has-zoom' : ''} signed-dpa`}
          >
            <div className="signed-dpa__doc container position-relative">
              <div
                className="signed-dpa__banner"
                style={{
                  backgroundColor:
                    publicAccountInfo && publicAccountInfo.brandColor
                      ? publicAccountInfo.brandColor
                      : '#7450c8',
                }}
              />
              <div className="d-flex justify-content-between align-items-center">
                {publicAccountInfo ? (
                  publicAccountInfo.companyLogo ? (
                    <div className="legalsite__logo-container">
                      <img src={publicAccountInfo.companyLogo} alt="logo" />
                    </div>
                  ) : (
                    <span className="legalsite__logo" />
                  )
                ) : null}

                <p className="mb-0">
                  <Moment format="DD MMMM YYYY">
                    {createdDate.toString()}
                  </Moment>
                </p>
              </div>

              <h2 className="signed-dpa__doc-title">
                Data Processing Agreement
              </h2>

              {actualLegalsitePages &&
                actualLegalsitePages.map((item) =>
                  item.title === 'Data Processing Agreement' ? (
                    <div className="signed-dpa__doc-content" key={item.id}>
                      {item.sections.map((section) => (
                        <Fragment key={section.id}>
                          <h3 className="signed-dpa__doc-title mb-4">
                            {section.title}
                          </h3>
                          <p className="signed-dpa__doc-text">{section.text}</p>
                        </Fragment>
                      ))}
                    </div>
                  ) : null
                )}

              <Form onSubmit={this.handleSubmit}>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="mr-5">
                    <img
                      className="signed-dpa__signature"
                      src={
                        publicAccountInfo && publicAccountInfo.pagesSignature
                          ? publicAccountInfo.pagesSignature
                          : ''
                      }
                      alt="signature"
                    />

                    <p className="signed-dpa__signed-name mt-3">
                      {publicAccountInfo && publicAccountInfo.fullName
                        ? publicAccountInfo.fullName
                        : ''}
                    </p>
                  </div>

                  <div className="d-flex flex-column">
                    <p>Signatory's full name</p>
                    <FormGroup>
                      <Input
                        type="text"
                        name="fullName"
                        className="mb-0"
                        placeholder="Enter full name"
                        invalid={this.state.formErrors.fullName}
                      />
                      <FormFeedback>Please provide your full name</FormFeedback>
                    </FormGroup>

                    {this.state.signature ? (
                      <img
                        className="signed-dpa__signature mt-3 mb-3"
                        src={this.state.signature}
                        alt="signature"
                      />
                    ) : null}

                    <Button
                      className="btn--secondary mt-4"
                      onClick={this.showSignatureModal}
                    >
                      {this.state.signature
                        ? 'Change Signature'
                        : 'Add Signature'}
                    </Button>
                  </div>
                </div>
                <div className="signed-dpa__doc-zoom">
                  <span onClick={() => this.setState({ isZoomedIn: true })}>
                    <ZoomIn size={20} />
                  </span>
                  <span onClick={() => this.setState({ isZoomedIn: false })}>
                    <ZoomOut size={20} />
                  </span>
                </div>

                <div className="d-flex signed-dpa__doc-submit">
                  <Button
                    type="submit"
                    className="btn--primary mt-5"
                    disabled={!this.state.signature}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}

        <AddSignature
          isShowSignatureModalVisible={this.state.isShowSignatureModalVisible}
          showSignatureModal={this.showSignatureModal}
          refetchData={() => {}}
          getSignature={(signature) => this.setState({ signature })}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
        />

        <DocumentSigned
          isDocumentModalVisible={this.state.isDocumentModalVisible}
          showDocumentModal={this.showDocumentModal}
          dpaLink={this.state.dpaLink}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
        />

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />
      </div>
    );
  }
}

export default compose(
  graphql(SIGNED_DPA_QUERY),
  graphql(SIGN_DPA, {
    name: 'signDPA',
  })
)(SignedDpa);
