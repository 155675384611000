import React from 'react';
import LockIcon from '../../components/Icons/LockIcon';
import PropTypes from 'prop-types';
import ShieldCheck from '../../components/Icons/ShieldCheck';
import Comments from '../../components/Icons/Comments';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import MdArrow from 'react-icons/lib/ti/arrow-right';

LegalsitePage.propTypes = {
  legalsite: PropTypes.object,
  item: PropTypes.object,
  isContact: PropTypes.bool,
  publicLegalsiteCustomizationSettings: PropTypes.object,
};

function LegalsitePage(props) {
  const {
    legalsite,
    item,
    publicAccountInfo,
    publicLegalsiteCustomizationSettings,
    isContact,
  } = props;

  return (
    <Col
      md={12}
      lg={4}
      className="legalsite__options-info"
      style={{
        background:
          publicLegalsiteCustomizationSettings &&
          publicLegalsiteCustomizationSettings.cardsBg
            ? publicLegalsiteCustomizationSettings.cardsBg
            : '#fafafa',
      }}
    >
      {item && item.title === 'Privacy Policy' ? (
        <LockIcon
          color={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsIconsColor
              ? publicLegalsiteCustomizationSettings.cardsIconsColor
              : '#7450c8'
          }
        />
      ) : item && item.title === 'Terms & Conditions' ? (
        <ShieldCheck
          color={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsIconsColor
              ? publicLegalsiteCustomizationSettings.cardsIconsColor
              : '#7450c8'
          }
        />
      ) : (
        <Comments
          color={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsIconsColor
              ? publicLegalsiteCustomizationSettings.cardsIconsColor
              : '#7450c8'
          }
        />
      )}

      <h4
        className="legalsite__info-title"
        style={{
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleColor
              ? publicLegalsiteCustomizationSettings.cardsTitleColor
              : '#333',
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleSize
              ? publicLegalsiteCustomizationSettings.cardsTitleSize
              : null,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : null,
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleAlign !== null
              ? publicLegalsiteCustomizationSettings.cardsTitleAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleBold !== null
              ? publicLegalsiteCustomizationSettings.cardsTitleBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleItalic !== null
              ? publicLegalsiteCustomizationSettings.cardsTitleItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        {isContact ? 'Contact us' : item.title}
      </h4>

      <p
        className="legalsite__info-text"
        style={{
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyColor
              ? publicLegalsiteCustomizationSettings.cardsBodyColor
              : '#969696',
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodySize
              ? publicLegalsiteCustomizationSettings.cardsBodySize
              : null,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : null,
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyAlign !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyBold !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        {isContact
          ? 'Click to send a message and we’ll get in touch soon.'
          : item.description}
      </p>
      <Link
        to={
          isContact
            ? {
                pathname: '/requests',
                state: {
                  publicAccountInfo,
                  id: publicAccountInfo.id,
                  isDefault: true,
                },
              }
            : {
                pathname: `/page?page=${props.formatPageName(item.title)}`,
                state: { page: item, publicAccountInfo, uid: props.uid },
              }
        }
        className="legalsite__info-more"
        style={{
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnSize
              ? publicLegalsiteCustomizationSettings.cardsBtnSize
              : null,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : null,
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnBg
              ? publicLegalsiteCustomizationSettings.cardsBtnBg
              : '#7450c8',
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnBold !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        See more <MdArrow size={20} className="ml-2 my-0" />
      </Link>
    </Col>
  );
}

export default LegalsitePage;
