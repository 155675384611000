import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FOOTER_QUERY } from "../../graphql/footer";
import * as compose from "lodash.flowright";
import { COOKIE_POPUP_QUERY } from "../../graphql/cookiePopupQuery";
import { Row, Col } from "reactstrap";
import { graphql } from "react-apollo";
import uuid from "react-uuid";
import "./footer.scss";
import FontLoader from "../../containers/LegalSite/FontLoader";

class Footer extends Component {
  state = {
    uid: uuid(),
  };

  formatPageName = (title) => {
    return title.replace("&", "").replace(/\s/g, "");
  };

  render() {
    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicDataProtectionOfficer =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.dataProtectionOfficer;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    const publicLegalInformation =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.legalInformation;

    const actualLegalsitePages =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.actualPages;

    const publicConsents =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.consents;

    const cookiePopup =
      this.props.cookiePopup &&
      this.props.cookiePopup.publicLegalsiteCustomizationPopup
        ? this.props.cookiePopup.publicLegalsiteCustomizationPopup
        : null;

    const customizationDontSellMyData =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationDontSellMyData;

    return (
      <div
        className="footer"
        style={{
          background:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerBg
              ? publicLegalsiteCustomizationSettings.footerBg
              : "#dfe4ee",
        }}
      >
        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : "Roboto"
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : "Roboto"
          }
          font3={
            this.props.data &&
            this.props.data.publicLegalsite &&
            this.props.data.publicLegalsite.customizationDontSellMyData &&
            this.props.data.publicLegalsite.customizationDontSellMyData.textFont
              ? this.props.data.publicLegalsite.customizationDontSellMyData
                  .textFont
              : "Roboto"
          }
          font4="Roboto"
        />
        <div
          className="footer__container"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.footerBg
                ? publicLegalsiteCustomizationSettings.footerBg
                : "#dfe4ee",
          }}
        >
          <Row className="w-100">
            <Col sm={12} md={3} className="d-flex flex-column footer__logo">
              <Link
                to="/"
                className="d-flex align-items-center text-decoration-none"
              >
                {publicAccountInfo && publicAccountInfo.companyLogo ? (
                  <div className="legalsite__logo-container">
                    <img src={publicAccountInfo.companyLogo} alt="logo" />
                  </div>
                ) : (
                  <span className="legalsite__logo" />
                )}
              </Link>
            </Col>

            <Col sm={12} md={3} className="d-flex flex-column footer__col">
              <p
                className="mb-4"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextColor
                      ? publicLegalsiteCustomizationSettings.footerTextColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextSize
                      ? publicLegalsiteCustomizationSettings.footerTextSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextFont
                      ? publicLegalsiteCustomizationSettings.footerTextFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextBold !== null
                      ? publicLegalsiteCustomizationSettings.footerTextBold ===
                        true
                        ? 900
                        : 500
                      : 500,

                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                <strong>Website</strong>
              </p>
              <Link
                to="/"
                className="mb-4"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksColor
                      ? publicLegalsiteCustomizationSettings.footerLinksColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksSize
                      ? publicLegalsiteCustomizationSettings.footerLinksSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksFont
                      ? publicLegalsiteCustomizationSettings.footerLinksFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                Homepage
              </Link>
              {publicAccountInfo && publicAccountInfo.companyWebsite ? (
                <a
                  href={`//${publicAccountInfo.companyWebsite}`}
                  target="_blank"
                  className="mb-4"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksColor
                        ? publicLegalsiteCustomizationSettings.footerLinksColor
                        : "#333",
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksSize
                        ? publicLegalsiteCustomizationSettings.footerLinksSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksFont
                        ? publicLegalsiteCustomizationSettings.footerLinksFont
                        : null,
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                          true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                          true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  Go back to main website
                </a>
              ) : null}
              {(publicDataProtectionOfficer &&
                publicDataProtectionOfficer.show) ||
              publicLegalInformation ? (
                <Link
                  className="mb-4"
                  to={"/legal"}
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksColor
                        ? publicLegalsiteCustomizationSettings.footerLinksColor
                        : "#333",
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksSize
                        ? publicLegalsiteCustomizationSettings.footerLinksSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksFont
                        ? publicLegalsiteCustomizationSettings.footerLinksFont
                        : null,
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                          true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                          true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  Legal Information
                </Link>
              ) : null}
            </Col>

            <Col sm={12} md={3} className="d-flex flex-column footer__col">
              <p
                className="mb-4"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextColor
                      ? publicLegalsiteCustomizationSettings.footerTextColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextSize
                      ? publicLegalsiteCustomizationSettings.footerTextSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextFont
                      ? publicLegalsiteCustomizationSettings.footerTextFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextBold !== null
                      ? publicLegalsiteCustomizationSettings.footerTextBold ===
                        true
                        ? 900
                        : 500
                      : 500,

                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                <strong>Legal Pages</strong>
              </p>
              {actualLegalsitePages
                ? actualLegalsitePages.map((item) => (
                    <Link
                      to={{
                        pathname: `/page?page=${this.formatPageName(
                          item.title
                        )}`,
                        state: {
                          page: item,
                          publicAccountInfo,
                          uid: this.state.uid,
                        },
                      }}
                      className="mb-4 legalsite__nav-link"
                      key={item.id}
                      style={{
                        color:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksColor
                            ? publicLegalsiteCustomizationSettings.footerLinksColor
                            : "#333",
                        fontSize:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksSize
                            ? publicLegalsiteCustomizationSettings.footerLinksSize
                            : null,
                        fontFamily:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksFont
                            ? publicLegalsiteCustomizationSettings.footerLinksFont
                            : null,
                        textAlign:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksAlign !==
                            null
                            ? publicLegalsiteCustomizationSettings.footerLinksAlign
                            : null,
                        fontWeight:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksBold !==
                            null
                            ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                              true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.footerLinksItalic !==
                            null
                            ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                              true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      {item.title}
                    </Link>
                  ))
                : null}
            </Col>

            <Col sm={12} md={3} className="d-flex flex-column footer__col">
              <p
                className="mb-4"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextColor
                      ? publicLegalsiteCustomizationSettings.footerTextColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextSize
                      ? publicLegalsiteCustomizationSettings.footerTextSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextFont
                      ? publicLegalsiteCustomizationSettings.footerTextFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextBold !== null
                      ? publicLegalsiteCustomizationSettings.footerTextBold ===
                        true
                        ? 900
                        : 500
                      : 500,

                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerTextItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerTextItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                <strong>Manage your information</strong>
              </p>
              {publicConsents && publicConsents.length && cookiePopup ? (
                <Link
                  className="mb-4"
                  to={{
                    pathname: "/manage-consent",
                    state: { publicConsents: publicConsents },
                  }}
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksColor
                        ? publicLegalsiteCustomizationSettings.footerLinksColor
                        : "#333",
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksSize
                        ? publicLegalsiteCustomizationSettings.footerLinksSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksFont
                        ? publicLegalsiteCustomizationSettings.footerLinksFont
                        : null,
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                          true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.footerLinksItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                          true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  Manage Consent
                </Link>
              ) : null}
              <Link
                className="mb-4"
                to={{
                  pathname: "/requests",
                  state: {
                    publicAccountInfo,
                    id: publicAccountInfo ? publicAccountInfo.id : null,
                    isDefault: true,
                  },
                }}
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksColor
                      ? publicLegalsiteCustomizationSettings.footerLinksColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksSize
                      ? publicLegalsiteCustomizationSettings.footerLinksSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksFont
                      ? publicLegalsiteCustomizationSettings.footerLinksFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                Send request
              </Link>

              {customizationDontSellMyData ? (
                <div
                  className={`d-flex ${
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksAlign ===
                        "center"
                        ? "justify-content-center"
                        : publicLegalsiteCustomizationSettings.footerLinksAlign ===
                          "left"
                        ? "justify-content-start"
                        : "justify-content-end"
                      : null
                  }`}
                >
                  <Link
                    className={`${
                      (customizationDontSellMyData &&
                        customizationDontSellMyData.buttonType ===
                          "contained") ||
                      (customizationDontSellMyData &&
                        !customizationDontSellMyData.buttonType)
                        ? "-is-contained"
                        : ""
                    } mb-4`}
                    style={{
                      background:
                        (customizationDontSellMyData &&
                          customizationDontSellMyData.buttonType ===
                            "contained") ||
                        (customizationDontSellMyData &&
                          !customizationDontSellMyData.buttonType)
                          ? customizationDontSellMyData &&
                            customizationDontSellMyData.color
                            ? customizationDontSellMyData.color
                            : "#7450c8"
                          : null,
                      color:
                        customizationDontSellMyData &&
                        customizationDontSellMyData.textColor
                          ? customizationDontSellMyData.textColor
                          : "#fff",
                      fontSize:
                        customizationDontSellMyData &&
                        customizationDontSellMyData.textSize
                          ? parseInt(customizationDontSellMyData.textSize)
                          : 14,
                      fontFamily:
                        customizationDontSellMyData &&
                        customizationDontSellMyData.textFont
                          ? customizationDontSellMyData.textFont
                          : "Roboto",
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.footerLinksAlign !==
                          null &&
                        customizationDontSellMyData &&
                        customizationDontSellMyData.buttonType !== "contained"
                          ? publicLegalsiteCustomizationSettings.footerLinksAlign
                          : null,
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.footerLinksBold !==
                          null
                          ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                            true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.footerLinksItalic !==
                          null
                          ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                            true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                    to={{
                      pathname: "/requests",
                      state: {
                        publicAccountInfo,
                        id: publicAccountInfo ? publicAccountInfo.id : null,
                        isSell: true,
                      },
                    }}
                  >
                    Do Not Sell My Info
                  </Link>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>

        <div
          className="footer-text"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.footerBg
                ? publicLegalsiteCustomizationSettings.footerBg
                : "#dfe4ee",
          }}
        >
          <span>
            <span
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextColor
                    ? publicLegalsiteCustomizationSettings.footerTextColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextSize
                    ? publicLegalsiteCustomizationSettings.footerTextSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextFont
                    ? publicLegalsiteCustomizationSettings.footerTextFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextAlign !== null
                    ? publicLegalsiteCustomizationSettings.footerTextAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextBold !== null
                    ? publicLegalsiteCustomizationSettings.footerTextBold ===
                      true
                      ? 900
                      : 500
                    : 500,

                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.footerTextItalic !== null
                    ? publicLegalsiteCustomizationSettings.footerTextItalic ===
                      true
                      ? "italic"
                      : "normal"
                    : "normal",
              }}
            >
              © {new Date().getFullYear()}{" "}
              {publicAccountInfo && publicAccountInfo.companyName
                ? publicAccountInfo.companyName
                : ""}
              . All rights reserved.
            </span>{" "}
            {publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.showLegalsiteBranding ? (
              <span
                onClick={() => window.open("http://legalsite.co/", "_blank")}
                className="footer-get"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksColor
                      ? publicLegalsiteCustomizationSettings.footerLinksColor
                      : null,
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksSize
                      ? publicLegalsiteCustomizationSettings.footerLinksSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksFont
                      ? publicLegalsiteCustomizationSettings.footerLinksFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.footerLinksItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.footerLinksItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                <u>Get your LegalSite!</u>
              </span>
            ) : null}
          </span>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(FOOTER_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(COOKIE_POPUP_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
        category: "Cookies Policy",
      },
      fetchPolicy: "no-cache",
    }),
    name: "cookiePopup",
  })
)(Footer);
