import gql from 'graphql-tag';

export const ACTUAL_LEGALSITE_QUERY = gql`
  query($subdomain: String!, $category: String!) {
    publicLegalsite(subdomain: $subdomain) {
      companyLogo
      id
      access
      legalInformation {
        id
      }
      customizationLegalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnBg
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        cardsBg
        cardsBodyAlign
        cardsBodyBold
        cardsBodyColor
        cardsBodyFont
        cardsBodyItalic
        cardsBodySize
        cardsBtnAlign
        cardsBtnBg
        cardsBtnBold
        cardsBtnColor
        cardsBtnFont
        cardsBtnItalic
        cardsBtnSize
        cardsIconsColor
        cardsTitleAlign
        cardsTitleBold
        cardsTitleColor
        cardsTitleFont
        cardsTitleItalic
        cardsTitleSize
        footerLinksFont
        footerTextFont
        headerBg
        homepageTitle
        homepageDescription
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        navigationLinksFont
      }
      actualPages {
        description
        id
        title
        version
        updatedAt
        sections {
          id
          title
          position
          text
        }
      }
      consents {
        categoryName
        completeDescription
        id
        manageableByVisitor
        smallDescription
        dataProcessorConsents {
          id
          consentName
          dataProcessorCompanyName
          dataProcessorLogo
          purpose
        }
      }
      dataProtectionOfficer {
        id
        show
      }
    }
    publicLegalsiteCustomizationPopup(
      subdomain: $subdomain
      category: $category
    ) {
      backgroundColor
      body
      bodyColor
      bodyFont
      bodySize
      bodyAlign
      bodyBold
      bodyItalic
      buttonColor
      buttonFont
      buttonShapes
      buttonBgColor
      buttonSize
      buttonText
      catchEmail
      catchSignature
      destinationPage
      overlay
      popupPosition
      popupSide
      secondaryButtonColor
      secondaryButtonFont
      secondaryButtonSize
      secondaryButtonText
      template
      title
      titleColor
      titleFont
      titleSize
      titleAlign
      titleBold
      titleItalic
      showLegalsiteBranding
      verifyUser
      buttonBold
      buttonItalic
    }
  }
`;
