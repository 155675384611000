import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import Email from '../../images/Request Access/email.svg';
import './modals.scss';

export default class CheckEmail extends Component {
  static propTypes = {
    isCheckEmailModalVisible: PropTypes.bool.isRequired,
    showCheckEmailModal: PropTypes.func.isRequired,
    showComplaint: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    const { publicLegalsiteCustomizationSettings } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isCheckEmailModalVisible}
          toggle={this.props.showCheckEmailModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={() => {
                this.props.showCheckEmailModal();
                this.props.showComplaint();
              }}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p
              className="modal-assign__title mt-3"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Check your e-mail!
            </p>
            <p
              className="modal-assign__message mb-4"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              We need to verify your e-mail. Please, click in the verification
              link we just sent in your e-mail to confirm.
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
