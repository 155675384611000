import gql from 'graphql-tag';

export const ACCEPT_AGREEMENT = gql`
  mutation(
    $subdomain: String!
    $uid: String!
    $category: String!
    $ipNumber: String!
    $userAgent: String!
    $originUrl: String!
    $clientMutationId: String
    $email: String
  ) {
    dataSubjectAcceptAgreement(
      input: {
        subdomain: $subdomain
        uid: $uid
        clientMutationId: $clientMutationId
        category: $category
        ipNumber: $ipNumber
        userAgent: $userAgent
        originUrl: $originUrl
        email: $email
      }
    ) {
      errors
    }
  }
`;
