import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '../../components/Icons/WhiteCheck';
import declined from '../../images/Request Access/declined.svg';
import Moment from 'react-moment';
import './requests.scss';

UserInfo.propTypes = {
  selectedRequest: PropTypes.object,
};

function UserInfo(props) {
  const { selectedRequest } = props;

  return (
    <div className="inbox__data-user d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="inbox__data-user-avatar">
          {selectedRequest &&
          selectedRequest.dataSubject &&
          selectedRequest.dataSubject.avatar ? (
            <img src={selectedRequest.dataSubject.avatar} alt="avatar" />
          ) : (
            <span className="inbox__data-user-avatar-placeholder" />
          )}
        </div>

        <div className="d-flex flex-column">
          <span className="inbox__data-user-name">
            {selectedRequest &&
            selectedRequest.dataSubject &&
            selectedRequest.dataSubject.name
              ? selectedRequest.dataSubject.name
              : ''}
          </span>

          <span className="inbox__data-user-email">
            {selectedRequest &&
            selectedRequest.dataSubject &&
            selectedRequest.dataSubject.email
              ? selectedRequest.dataSubject.email
              : ''}
          </span>

          <span className="inbox__data-user-email d-flex align-items mt-2">
            {selectedRequest &&
            selectedRequest.dataSubject &&
            selectedRequest.dataSubject.verified ? (
              <Fragment>
                <CheckIcon className="mr-2" /> User Verified
              </Fragment>
            ) : (
              <Fragment>
                <img src={declined} alt="declined" className="mr-2" /> User
                Unverified
              </Fragment>
            )}
          </span>
        </div>
      </div>

      <div className="text-right">
        <span className="inbox__data-user-date">
          <Moment format="D MMM YYYY">
            {selectedRequest && selectedRequest.receivedDate
              ? selectedRequest.receivedDate
              : ''}
          </Moment>
        </span>
      </div>
    </div>
  );
}

export default UserInfo;
