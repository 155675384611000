import gql from "graphql-tag";

export const CREATE_QUESTION = gql`
  mutation(
    $accountId: ID!
    $email: String!
    $fullName: String!
    $phone: String
    $clientMutationId: String
    $message: String!
  ) {
    dataSubjectCreateQuestion(
      input: {
        fullName: $fullName
        email: $email
        accountId: $accountId
        phone: $phone
        clientMutationId: $clientMutationId
        message: $message
      }
    ) {
      errors
    }
  }
`;
