import React, { Component } from 'react';
import './requests.scss';

import { Alert, Button } from 'reactstrap';
import { REQUEST_QUERY } from '../../graphql/requests';
import SyncLoader from 'react-spinners/SyncLoader';
import FontLoader from '../../containers/LegalSite/FontLoader';

import RequestData from './RequestData';
import { graphql } from 'react-apollo';

class Requests extends Component {
  static propTypes = {};

  state = {
    isDataAccess: false,
    showThankYou: false,
    token: null,
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');

    this.setState({
      token,
    });
  }

  render() {
    const { loading, error } = this.props.data;

    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    const actualLegalsitePages =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.actualPages;

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );
    return (
      <div className="requests">
        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.titleFont
              ? publicLegalsiteCustomizationSettings.titleFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyFont
              ? publicLegalsiteCustomizationSettings.bodyFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.buttonFont
              ? publicLegalsiteCustomizationSettings.buttonFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.subtitleFont
              ? publicLegalsiteCustomizationSettings.subtitleFont
              : 'Roboto'
          }
        />
        <RequestData
          actualLegalsitePages={actualLegalsitePages}
          publicAccountInfo={publicAccountInfo}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          subdomain={this.props.subdomain}
          token={this.state.token}
        />
      </div>
    );
  }
}
export default graphql(REQUEST_QUERY)(Requests);
