import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import Email from '../../images/Request Access/Document.svg';
import DownloadIcon from '../Icons/DownloadIcon';
import axios from 'axios';
import './modals.scss';

export default class DocumentSigned extends Component {
  static propTypes = {
    isDocumentModalVisible: PropTypes.bool.isRequired,
    showDocumentModal: PropTypes.func.isRequired,
  };

  state = {};

  download = () => {
    const name = 'dpa',
      url = this.props.dpaLink;

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.props.showDocumentModal();
  };

  render() {
    const { publicLegalsiteCustomizationSettings } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isDocumentModalVisible}
          toggle={this.props.showDocumentModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={() => {
                this.props.showDocumentModal();
              }}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p
              className="modal-assign__title mt-3"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Document signed!
            </p>
            <p
              className="modal-assign__message mb-2"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              The signed document was sent by e-mail to both parties. You can
              also download it
            </p>
            <p
              className="modal-assign__link mb-3"
              onClick={this.download}
              style={{
                cursor: 'pointer',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnSize
                    ? publicLegalsiteCustomizationSettings.cardsBtnSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnFont
                    ? publicLegalsiteCustomizationSettings.cardsBtnFont
                    : null,
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBg
                    ? publicLegalsiteCustomizationSettings.cardsBtnBg
                    : '#7450c8',
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              <DownloadIcon
                color={
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBg
                    ? publicLegalsiteCustomizationSettings.cardsBtnBg
                    : '#7450c8'
                }
                className="mr-2"
              />
              Download
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
