import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

DpoContent.propTypes = {
  dpo: PropTypes.object,
  publicLegalsiteCustomizationSettings: PropTypes.object,
  publicAccountInfo: PropTypes.object,
};

function DpoContent(props) {
  const {
    dpo,
    publicLegalsiteCustomizationSettings,
    publicAccountInfo,
  } = props;

  return (
    <div>
      <p
        className="dpo__name dpo__title"
        style={{
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleColor
              ? publicLegalsiteCustomizationSettings.bodyTitleColor
              : '#333',
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleSize
              ? publicLegalsiteCustomizationSettings.bodyTitleSize
              : 36,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Bookmania Semibold',
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleAlign !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleBold !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleItalic !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        Data Protection Officer (DPO)
      </p>
      <div className="dpo__content">
        {dpo && dpo.avatar ? (
          <div className="dpo__avatar">
            <img src={dpo.avatar} alt="avatar" />
          </div>
        ) : null}
        {dpo ? (
          <div>
            <div className="dpo__info">
              <p
                className="dpo__name"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleColor
                      ? publicLegalsiteCustomizationSettings.bodyTitleColor
                      : '#333',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleSize
                      ? publicLegalsiteCustomizationSettings.bodyTitleSize
                      : 36,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleFont
                      ? publicLegalsiteCustomizationSettings.bodyTitleFont
                      : 'Bookmania Semibold',
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleAlign !== null
                      ? publicLegalsiteCustomizationSettings.bodyTitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleBold !== null
                      ? publicLegalsiteCustomizationSettings.bodyTitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.bodyTitleItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {dpo.name}
              </p>
              <p
                className="dpo__description"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyColor
                      ? publicLegalsiteCustomizationSettings.cardsBodyColor
                      : '#969696',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodySize
                      ? publicLegalsiteCustomizationSettings.cardsBodySize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyFont
                      ? publicLegalsiteCustomizationSettings.cardsBodyFont
                      : null,

                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {dpo.description}
              </p>
            </div>
            <div>
              <p
                className="dpo__name"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleColor
                      ? publicLegalsiteCustomizationSettings.bodyTitleColor
                      : '#333',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleSize
                      ? publicLegalsiteCustomizationSettings.bodyTitleSize
                      : 36,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleFont
                      ? publicLegalsiteCustomizationSettings.bodyTitleFont
                      : 'Bookmania Semibold',
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleAlign !== null
                      ? publicLegalsiteCustomizationSettings.bodyTitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleBold !== null
                      ? publicLegalsiteCustomizationSettings.bodyTitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyTitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.bodyTitleItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                Contact
              </p>
              <p
                className="dpo__description mb-0"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyColor
                      ? publicLegalsiteCustomizationSettings.cardsBodyColor
                      : '#969696',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodySize
                      ? publicLegalsiteCustomizationSettings.cardsBodySize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyFont
                      ? publicLegalsiteCustomizationSettings.cardsBodyFont
                      : null,

                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                If you want to contact our Data Protection Officer, please send
                an e-mail to{' '}
                <a href={`mailto:${dpo ? dpo.email : ''}`}>{dpo.email}</a>. You
                can also get in touch with us and send requests regarding your
                data on our{' '}
                <Link
                  to={{
                    pathname: '/requests',
                    state: {
                      publicAccountInfo,
                      id: publicAccountInfo.id,
                    },
                    isDefault: true,
                  }}
                >
                  Requests
                </Link>{' '}
                section.{' '}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DpoContent;
