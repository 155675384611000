import PropTypes from 'prop-types';
import React from 'react';

const DownloadIcon = props => (
    <svg
        height={17}
        viewBox='0 0 20 14'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: 'middle',
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1257.000000, -1061.000000)" fill={props.color} fillRule="nonzero">
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-4" transform="translate(0.000000, 52.000000)">
                            <g id="down-arrow" transform="translate(1076.000000, 5.000000)">
                                <path d="M15.2,9.6 C14.7581722,9.6 14.4,9.9581722 14.4,10.4 L14.4,13.6 C14.4,14.0418278 14.0418278,14.4 13.6,14.4 L2.4,14.4 C1.9581722,14.4 1.6,14.0418278 1.6,13.6 L1.6,10.4 C1.6,9.9581722 1.2418278,9.6 0.8,9.6 C0.3581722,9.6 0,9.9581722 0,10.4 L0,13.6 C0,14.9254834 1.0745166,16 2.4,16 L13.6,16 C14.9254834,16 16,14.9254834 16,13.6 L16,10.4 C16,9.9581722 15.6418278,9.6 15.2,9.6 Z M7.432,10.968 C7.50808297,11.0408325 7.59779895,11.0979245 7.696,11.136 C7.8896319,11.2215814 8.1103681,11.2215814 8.304,11.136 C8.40220105,11.0979245 8.49191703,11.0408325 8.568,10.968 L11.768,7.768 C12.0816977,7.45430226 12.0816977,6.94569774 11.768,6.63200001 C11.4543023,6.31830227 10.9456977,6.31830227 10.632,6.632 L8.8,8.472 L8.8,0.8 C8.8,0.3581722 8.4418278,0 8,0 C7.5581722,0 7.2,0.3581722 7.2,0.8 L7.2,8.472 L5.368,6.632 C5.16507315,6.42907313 4.86930081,6.34982117 4.59209756,6.42409755 C4.31489432,6.49837393 4.09837393,6.71489432 4.02409755,6.99209756 C3.94982117,7.26930081 4.02907313,7.56507315 4.232,7.768 L7.432,10.968 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

DownloadIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default DownloadIcon;