import gql from 'graphql-tag';

export const CUSTOMIZED_POPUP_QUERY = gql`
  query($uuid: String!, $subdomain: String!) {
    popupByUuid(uuid: $uuid) {
      subdomain
      category
      backgroundColor
      body
      bodyColor
      bodyFont
      bodySize
      buttonColor
      buttonFont
      buttonShapes
      buttonBgColor
      buttonSize
      buttonText
      catchEmail
      catchSignature
      destinationPage
      overlay
      popupPosition
      popupSide
      secondaryButtonColor
      secondaryButtonFont
      secondaryButtonSize
      secondaryButtonText
      template
      title
      titleColor
      titleFont
      titleSize
      verifyUser
      lastPublication {
        id
        title
        version
        description
        sections {
          id
          position
          text
          title
        }
      }
    }
    publicLegalsite(subdomain: $subdomain) {
      customizationLegalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnBg
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        cardsBg
        cardsBodyAlign
        cardsBodyBold
        cardsBodyColor
        cardsBodyFont
        cardsBodyItalic
        cardsBodySize
        cardsBtnAlign
        cardsBtnBg
        cardsBtnBold
        cardsBtnColor
        cardsBtnFont
        cardsBtnItalic
        cardsBtnSize
        cardsIconsColor
        cardsTitleAlign
        cardsTitleBold
        cardsTitleColor
        cardsTitleFont
        cardsTitleItalic
        cardsTitleSize
        footerBg
        footerLinksAlign
        footerLinksBold
        footerLinksColor
        footerLinksFont
        footerLinksItalic
        footerLinksSize
        footerLogo
        footerTextAlign
        footerTextBold
        footerTextColor
        footerTextFont
        footerTextItalic
        footerTextSize
        headerBg
        homepageTitle
        homepageDescription
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        navigationBg
        navigationLinksAlign
        navigationLinksBold
        navigationLinksColor
        navigationLinksFont
        navigationLinksItalic
        navigationLinksSize
      }
      consents {
        categoryName
        completeDescription
        id
        manageableByVisitor
        smallDescription
        dataProcessorConsents {
          id
          consentName
          dataProcessorCompanyName
          dataProcessorLogo
          purpose
        }
      }
      actualPages {
        description
        id
        title
        updatedAt
        version
        sections {
          id
          position
          title
          text
        }
      }
    }
  }
`;
