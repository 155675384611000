import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Alert } from "reactstrap";
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { CUSTOMIZED_POPUP_QUERY } from '../../graphql/customizedPopup';
import { ACCEPT_AGREEMENT } from '../../graphql/dataSubjectAcceptAgreement';
import { ADD_DATA_SUBJECT_TO_LEGALSITE } from '../../graphql/addDataSubjectToLegalsite';
import SyncLoader from 'react-spinners/SyncLoader';
import MdClose from 'react-icons/lib/md/close';
import { Button } from 'reactstrap';
import FontLoader from '../../containers/LegalSite/FontLoader';
import { getIp } from '../../api/getIp';
import Cookies from 'universal-cookie';
import PopupContent from '../../components/Page/PopupContent';
import uuid from 'react-uuid';
import { closeWidget, setCookie } from '../../index';

const cookies = new Cookies();

class Widget extends Component {
  state = {
    refetched: false,
    ip: 'Undefined',
    email: null,
    versions: null,
    page: 'Cookies Policy',
  };

  componentWillMount() {
    window.addEventListener('message', this.receiveMessage, false);
    console.log('Widget component is loading.');
    // this.showIp();
  }

  componentDidMount() {
    const visitorTracked = cookies.get('UUID');

    if (typeof visitorTracked === 'undefined') {
      this.props.addDataSubjectToLegalsite().then(({ data }) => {
        cookies.set('UUID', data.addDataSubjectToLegalsite.uid, { path: '/' });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.refetched &&
      nextProps.popup.variables.subdomain != 'startupz.localhost:3001'
    ) {
      this.refetchQuery(nextProps.popup.popupByUuid);
    }
  }

  receiveMessage = (event) => {
    if (event.origin != 'http://localhost:8080') {
      // something from an unknown domain, let's ignore it
      return;
    }
    console.log('Received on legalsites: ' + event.data);

    event.source.postMessage('width: 100%', event.origin);
  };

  refetchQuery = (popupByUuid) => {
    this.props.popup
      .refetch({
        uuid: this.props.match.params.id,
        subdomain: popupByUuid.subdomain,
      })
      .then(this.setState({ refetched: true }));
  };

  showIp = async () => {
    try {
      const ip = await getIp();

      this.setState({
        ip,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getPage = (title) => {
    this.setState({
      page: title,
    });
  };

  closePopup = () => {
    closeWidget();
  };

  handleCookieAccept = () => {
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

    const category = this.props.popup.popupByUuid.title;
    const ipNumber = this.state.ip;
    const userAgent = navigator.userAgent;
    const originUrl = window.location.origin;
    const host = `${this.props.popup.popupByUuid.subdomain}.legalsites.app`;

    // this.props
    //   .acceptAgreement({
    //     variables: {
    //       subdomain: host,
    //       uid: cookies.get("UUID"),
    //       category,
    //       ipNumber,
    //       userAgent,
    //       originUrl,
    //       email: this.state.email ? this.state.email : ""
    //     }
    //   })
    //   .then(result => {
    //     console.log(result);
    if (
      this.props.popup.popupByUuid &&
      this.props.popup.popupByUuid.catchSignature
    ) {
      this.setState({
        isSignedConfirmation: true,
      });
    }
    console.log(this.props.popup.popupByUuid.lastPublication.title);
    cookies.set(`${this.props.popup.popupByUuid.lastPublication.title}`, true, {
      path: '/',
      expires: expireDate,
      sameSite: false,
      secure: true,
    });
    cookies.set(
      'pageVersion',
      this.props.popup.popupByUuid.lastPublication.version,
      {
        path: '/',
        expires: expireDate,
        sameSite: false,
        secure: true,
      }
    );
    // })
    // .catch(error => {
    //   console.log(error);
    // });

    if (
      this.props.popup.popupByUuid &&
      this.props.popup.popupByUuid.destinationPage
    ) {
      window.open(this.props.popup.popupByUuid.destinationPage, '_blank');
    }

    setCookie();
    closeWidget();
  };

  render() {
    const { popupByUuid, loading, error } = this.props.popup;

    const publicLegalsiteCustomizationSettings =
      this.props.popup &&
      this.props.popup.publicLegalsite &&
      this.props.popup.publicLegalsite.customizationLegalsite;

    const publicConsents =
      this.props.popup &&
      this.props.popup.publicLegalsite &&
      this.props.popup.publicLegalsite.consents;

    const actualLegalsitePages =
      this.props.popup &&
      this.props.popup.publicLegalsite &&
      this.props.popup.publicLegalsite.actualPages;

    const isAll = false;
    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return <h1 color="danger"> There was an error loading the data!</h1>;

    const actualLegalsitePage = actualLegalsitePages.filter(
      (item) => item.title === this.state.page
    );

    return (
      <div>
        <PopupContent
          publicLegalsiteCustomizationPopup={popupByUuid}
          getEmail={(email) => this.setState({ email })}
          email={this.state.email}
          isAll={true}
          actualLegalsitePage={actualLegalsitePages[0]}
          closePopup={this.closePopup}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          handleCookieAccept={this.handleCookieAccept}
          actualLegalsitePages={actualLegalsitePages}
          account={popupByUuid.subdomain}
          getPage={this.getPage}
          publicConsents={publicConsents}
          widget={true}
        />
      </div>
    );
  }
}

export default compose(
  graphql(CUSTOMIZED_POPUP_QUERY, {
    options: (props) => ({
      variables: {
        uuid: props.match.params.id,
        subdomain: 'startupz.localhost:3001',
      },
      fetchPolicy: 'no-cache',
    }),
    name: 'popup',
  }),
  graphql(ACCEPT_AGREEMENT, {
    name: 'acceptAgreement',
  }),
  graphql(ADD_DATA_SUBJECT_TO_LEGALSITE, {
    options: (props) => ({
      variables: {
        subdomain: 'startupz.localhost:3001',
      },
    }),
    name: 'addDataSubjectToLegalsite',
  })
)(Widget);
