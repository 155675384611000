import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Alert } from 'reactstrap';
import { graphql } from 'react-apollo';
import { ACTUAL_LEGALSITE_QUERY } from '../../graphql/actualLegalsite';
import { ACCEPT_AGREEMENT } from '../../graphql/dataSubjectAcceptAgreement';
import { REQUEST_SIGNED_DPA } from '../../graphql/requestSignedDpaOnCookie';
import { ADD_DATA_SUBJECT_TO_LEGALSITE } from '../../graphql/addDataSubjectToLegalsite';
import { COOKIE_POPUP_QUERY } from '../../graphql/cookiePopupQuery';
import ChatBubble from '../../components/Icons/ChatBubble';
import Consent from '../../components/Icons/Consent';
import SyncLoader from 'react-spinners/SyncLoader';
import Cookies from 'universal-cookie';
import './legalsite.scss';
import LegalsitePage from './LegalsitePage';
import CustomLegalsitePage from './CustomLegalsitePage';
import Popup from '../../components/Page/Popup';
import * as compose from 'lodash.flowright';
import { getIp } from '../../api/getIp';
import Footer from '../../components/Footer.js/Footer';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';
import Fonts from './Fonts';

const cookies = new Cookies();

class LegalSite extends Component {
  static propTypes = {};

  state = {
    accountId: 1,
    hasPopup: true,
    page: 'Cookies Policy',
    ip: 'Undefined',
    versions: null,
    hasDifferences: true,
    isSignedConfirmation: false,
    email: null,
    isClosed: false,
    isConfirmationModalVisible: false,
    updatedConsents: null,
  };

  componentDidMount() {
    const hasPopup = cookies.get('isAllAccepted');
    const visitorTracked = cookies.get('UUID');
    const versions = cookies.get('versions');

    const consents = cookies.get('consents');

    if (consents && consents.consents && consents.consents.length > 0) {
      this.setState({
        updatedConsents: consents.consents,
      });
    }

    if (typeof visitorTracked === 'undefined') {
      this.props.addDataSubjectToLegalsite().then(({ data }) => {
        cookies.set('UUID', data.addDataSubjectToLegalsite.uid, { path: '/' });
      });
    }

    this.setState({
      hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
      versions,
    });

    this.showIp();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.legalsite.publicLegalsite &&
      props.legalsite.publicLegalsite.consents &&
      state.updatedConsents
    ) {
      if (
        props.legalsite.publicLegalsite.consents.length !==
        state.updatedConsents.length
      ) {
        let subdomain = props.subdomain;

        props.legalsite.refetch({
          subdomain,
        });

        const consents = cookies.get('consents');

        const checked = consents
          ? consents.consents.filter((item) => item.checked)
          : [];

        const con = props.legalsite.publicLegalsite.consents.map(
          (obj) => checked.find((o) => o.id === obj.id) || obj
        );

        cookies.remove('Cookies Policy');
        cookies.remove('isAllAccepted');

        let expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

        cookies.set(
          'consents',
          {
            consents: con,
          },
          {
            path: '/',
            expires: expireDate,
          }
        );
        return {
          updatedConsents: con,
        };
      }
    }
  }

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalVisible: !this.state.isConfirmationModalVisible,
    });
  };

  handleCookieAccept = () => {
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

    const category = this.props.legalsite.publicLegalsiteCustomizationPopup
      .title;
    const ipNumber = this.state.ip;
    const userAgent = navigator.userAgent;
    const originUrl = window.location.origin;
    const versions = this.props.legalsite.publicLegalsite.actualPages.map(
      (item) => item.version
    );

    this.props
      .acceptAgreement({
        variables: {
          subdomain: this.props.subdomain,
          uid: cookies.get('UUID'),
          category,
          ipNumber,
          userAgent,
          originUrl,
          email: this.state.email ? this.state.email : '',
        },
      })
      .then(() => {
        cookies.set('isAllAccepted', true, { path: '/', expires: expireDate });
        cookies.set('versions', versions, { path: '/', expires: expireDate });
        cookies.set('acceptedAll', true, {
          path: '/',
          expires: expireDate,
        });
        if (
          this.props.legalsite.publicLegalsiteCustomizationPopup &&
          this.props.legalsite.publicLegalsiteCustomizationPopup.destinationPage
        ) {
          window.open(
            `//${this.props.legalsite.publicLegalsiteCustomizationPopup.destinationPage}`,
            '_blank'
          );
        }

        if (
          this.props.legalsite.publicLegalsiteCustomizationPopup &&
          this.props.legalsite.publicLegalsiteCustomizationPopup.catchSignature
        ) {
          this.props
            .requestDpa({
              variables: {
                accountId: this.props.legalsite.publicLegalsite.id,
                email: this.state.email,
              },
            })
            .then(() => {
              this.showConfirmationModal();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      hasPopup: true,
      hasDifferences: false,
    });
  };

  showIp = async () => {
    try {
      const ip = await getIp();

      this.setState({
        ip,
      });
    } catch (err) {
      console.log(err);
    }
  };

  closePopup = () => {
    this.setState({
      hasPopup: true,
      isClosed: true,
    });
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  getPage = (title) => {
    this.setState({
      page: title,
    });
  };

  formatPageName = (title) => {
    return title.replace('&', '').replace(/\s/g, '');
  };

  render() {
    const {
      loading,
      error,
      publicLegalsiteCustomizationPopup,
    } = this.props.legalsite;

    const publicAccountInfo =
      this.props.legalsite && this.props.legalsite.publicLegalsite;

    const publicLegalInformation =
      this.props.legalsite &&
      this.props.legalsite.publicLegalsite &&
      this.props.legalsite.publicLegalsite.legalInformation;

    const publicLegalsiteCustomizationSettings =
      this.props.legalsite &&
      this.props.legalsite.publicLegalsite &&
      this.props.legalsite.publicLegalsite.customizationLegalsite;

    const publicDataProtectionOfficer =
      this.props.legalsite &&
      this.props.legalsite.publicLegalsite &&
      this.props.legalsite.publicLegalsite.dataProtectionOfficer;

    const actualLegalsitePages =
      this.props.legalsite &&
      this.props.legalsite.publicLegalsite &&
      this.props.legalsite.publicLegalsite.actualPages;

    const publicConsents =
      this.props.legalsite &&
      this.props.legalsite.publicLegalsite &&
      this.props.legalsite.publicLegalsite.consents;

    const cookiePopup =
      this.props.cookiePopup &&
      this.props.cookiePopup.publicLegalsiteCustomizationPopup
        ? this.props.cookiePopup.publicLegalsiteCustomizationPopup
        : null;

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    const { hasPopup, isClosed } = this.state;
    

    const actualLegalsitePage = actualLegalsitePages.filter(
      (item) => item.title === this.state.page
    );

    const actualVersions = actualLegalsitePages.map((item) => item.version);
    const comparedArrays = this.state.versions
      ? JSON.stringify(this.state.versions) === JSON.stringify(actualVersions)
      : null;

    if (comparedArrays === false) {
      cookies.remove('isAllAccepted');
    }

    return (
      <div className="legalsite">
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />

        <LegalsiteNavbar subdomain={this.props.subdomain} isHomepage={true} />

        <div className="legalsite__container">
          <div
            className="legalsite__welcome"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBg
                  ? publicLegalsiteCustomizationSettings.headerBg
                  : '#dfe4ee',
            }}
          >
            <h1
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : '#333',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : 56,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : 'center',
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
              className="legalsite__welcome-title"
            >
              {publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.homepageTitle
                ? publicLegalsiteCustomizationSettings.homepageTitle
                : 'Welcome!'}
            </h1>
            <p
              className="legalsite__welcome-text"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : '#969696',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : 16,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              {publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.homepageDescription
                ? publicLegalsiteCustomizationSettings.homepageDescription
                : 'It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need.'}
            </p>
          </div>

          <div
            className="legalsite__options pb-4"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.bodyBg
                  ? publicLegalsiteCustomizationSettings.bodyBg
                  : '#f4f6fc',
            }}
          >
            <Row className="legalsite__options-content justify-content-center">
              {actualLegalsitePages.map((item) =>
                item.title === 'Terms & Conditions' ||
                item.title === 'Privacy Policy' ? (
                  <LegalsitePage
                    key={item.id}
                    item={item}
                    formatPageName={this.formatPageName}
                    legalsite={publicLegalsiteCustomizationSettings}
                    publicAccountInfo={publicAccountInfo}
                    publicLegalsiteCustomizationSettings={
                      publicLegalsiteCustomizationSettings
                    }
                  />
                ) : null
              )}

              <LegalsitePage
                isContact={true}
                publicLegalsiteCustomizationSettings={
                  publicLegalsiteCustomizationSettings
                }
                legalsite={publicLegalsiteCustomizationSettings}
                publicAccountInfo={publicAccountInfo}
                formatPageName={this.formatPageName}
              />
            </Row>

            <h3
              className="legalsite__options-subtitle"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleColor
                    ? publicLegalsiteCustomizationSettings.bodyTitleColor
                    : '#333',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleSize
                    ? publicLegalsiteCustomizationSettings.bodyTitleSize
                    : 36,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleFont
                    ? publicLegalsiteCustomizationSettings.bodyTitleFont
                    : 'Bookmania Semibold',
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.bodyTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.bodyTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTitleItalic !== null
                    ? publicLegalsiteCustomizationSettings.bodyTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Manage your information
            </h3>

            <p
              className="legalsite__options-subtitle-text"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextColor
                    ? publicLegalsiteCustomizationSettings.bodyTextColor
                    : '#969696',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextSize
                    ? publicLegalsiteCustomizationSettings.bodyTextSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextFont
                    ? publicLegalsiteCustomizationSettings.bodyTextFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextAlign !== null
                    ? publicLegalsiteCustomizationSettings.bodyTextAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextBold !== null
                    ? publicLegalsiteCustomizationSettings.bodyTextBold === true
                      ? 900
                      : 500
                    : 500,

                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyTextItalic !== null
                    ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              When you interact with our service, we probably manage information
              about you. You have the right to understand and manage how this
              information is handled by us.
            </p>

            <div className="d-flex justify-content-center flex-wrap mb-5 pb-3">
              <div className="d-flex flex-column align-items-center justify-content-between customization-site__info-details-item">
                <ChatBubble
                  color={
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyIconsColor
                      ? publicLegalsiteCustomizationSettings.bodyIconsColor
                      : '#7450c8'
                  }
                />
                <p
                  className="customization-site__info-title"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleColor
                        ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                        : '#333',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleSize
                        ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleFont
                        ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                        : 'Roboto',
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                          true
                          ? 900
                          : 500
                        : 500,

                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                >
                  Requests
                </p>
                <p
                  className="customization-site__info-text"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextColor
                        ? publicLegalsiteCustomizationSettings.bodyTextColor
                        : '#969696',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextSize
                        ? publicLegalsiteCustomizationSettings.bodyTextSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextFont
                        ? publicLegalsiteCustomizationSettings.bodyTextFont
                        : 'Roboto',
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.bodyTextAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextBold !== null
                        ? publicLegalsiteCustomizationSettings.bodyTextBold ===
                          true
                          ? 900
                          : 500
                        : 500,

                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyTextItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                >
                  Send data requests, questions or complaints
                </p>
                <Link
                  to={{
                    pathname: '/requests',
                    state: {
                      publicAccountInfo,
                      id: publicAccountInfo.id,
                      isDefault: true,
                    },
                  }}
                  className="customization-site__item-btn"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnColor
                        ? publicLegalsiteCustomizationSettings.bodyBtnColor
                        : '#fff',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnSize
                        ? publicLegalsiteCustomizationSettings.bodyBtnSize
                        : null,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnFont
                        ? publicLegalsiteCustomizationSettings.bodyBtnFont
                        : 'Roboto',
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnAlign
                        ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                        : null,
                    backgroundColor:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBg
                        ? publicLegalsiteCustomizationSettings.bodyBtnBg
                        : '#7450C8',
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBold
                        ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                          true
                          ? 900
                          : 300
                        : 300,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnItalic
                        ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                >
                  Request
                </Link>
              </div>
              {publicConsents && publicConsents.length && cookiePopup ? (
                <div className="d-flex flex-column align-items-center justify-content-between customization-site__info-details-item">
                  <Consent
                    color={
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyIconsColor
                        ? publicLegalsiteCustomizationSettings.bodyIconsColor
                        : '#7450c8'
                    }
                  />
                  <p
                    className="customization-site__info-title"
                    style={{
                      color:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleColor
                          ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                          : '#333',
                      fontSize:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleSize
                          ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                          : null,
                      fontFamily:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleFont
                          ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                          : 'Roboto',
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                          : null,
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                            true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                            true
                            ? 'italic'
                            : 'normal'
                          : 'normal',
                    }}
                  >
                    Manage Consent
                  </p>
                  <p
                    className="customization-site__info-text"
                    style={{
                      color:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextColor
                          ? publicLegalsiteCustomizationSettings.bodyTextColor
                          : '#969696',
                      fontSize:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextSize
                          ? publicLegalsiteCustomizationSettings.bodyTextSize
                          : null,
                      fontFamily:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextFont
                          ? publicLegalsiteCustomizationSettings.bodyTextFont
                          : 'Roboto',
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextAlign !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodyTextAlign
                          : null,
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextBold !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodyTextBold ===
                            true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyTextItalic !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                            true
                            ? 'italic'
                            : 'normal'
                          : 'normal',
                    }}
                  >
                    Review your consent settings here
                  </p>
                  <Link
                    className="customization-site__item-btn"
                    to={{
                      pathname: '/manage-consent',
                      state: { publicConsents },
                    }}
                    style={{
                      color:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnColor
                          ? publicLegalsiteCustomizationSettings.bodyBtnColor
                          : '#fff',
                      fontSize:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnSize
                          ? publicLegalsiteCustomizationSettings.bodyBtnSize
                          : null,
                      fontFamily:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnFont
                          ? publicLegalsiteCustomizationSettings.bodyBtnFont
                          : 'Roboto',
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnAlign
                          ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                          : null,
                      backgroundColor:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnBg
                          ? publicLegalsiteCustomizationSettings.bodyBtnBg
                          : '#7450C8',
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnBold
                          ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                            true
                            ? 900
                            : 300
                          : 300,
                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodyBtnItalic
                          ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                            true
                            ? 'italic'
                            : 'normal'
                          : 'normal',
                    }}
                  >
                    Review
                  </Link>
                </div>
              ) : null}
            </div>

            <div className="">
              {actualLegalsitePages.map((item) =>
                item.title !== 'Terms & Conditions' &&
                item.title !== 'Privacy Policy' ? (
                  <CustomLegalsitePage
                    item={item}
                    key={item.id}
                    formatPageName={this.formatPageName}
                    publicLegalsiteCustomizationSettings={
                      publicLegalsiteCustomizationSettings
                    }
                    publicAccountInfo={publicAccountInfo}
                  />
                ) : null
              )}
            </div>

            {publicDataProtectionOfficer || publicLegalInformation ? (
              <div className="">
                {(publicDataProtectionOfficer &&
                  publicDataProtectionOfficer.show) ||
                publicLegalInformation ? (
                  <CustomLegalsitePage
                    isDPO={true}
                    formatPageName={this.formatPageName}
                    publicLegalsiteCustomizationSettings={
                      publicLegalsiteCustomizationSettings
                    }
                    publicAccountInfo={publicAccountInfo}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          <Footer
            subdomain={this.props.subdomain}
            publicLegalsiteCustomizationSettings={
              publicLegalsiteCustomizationSettings
            }
            color={
              publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.subtitleColor
                : null
            }
            bg={
              publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.background1
                : null
            }
          />
        </div>

        <ConfirmationModal
          isConfirmationModalVisible={this.state.isConfirmationModalVisible}
          showConfirmationModal={this.showConfirmationModal}
          subdomain={this.props.subdomain}
        />

        {!hasPopup ||
        (comparedArrays === false && this.state.hasDifferences && !isClosed) ? (
          <Popup
            publicLegalsiteCustomizationPopup={
              publicLegalsiteCustomizationPopup
            }
            getEmail={(email) => this.setState({ email })}
            email={this.state.email}
            isAll={true}
            actualLegalsitePage={actualLegalsitePage[0]}
            closePopup={this.closePopup}
            publicLegalsiteCustomizationSettings={
              publicLegalsiteCustomizationSettings
            }
            handleCookieAccept={this.handleCookieAccept}
            actualLegalsitePages={actualLegalsitePages}
            account={publicAccountInfo.access}
            getPage={this.getPage}
            publicConsents={publicConsents}
          />
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(ACTUAL_LEGALSITE_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
        category: 'All pages in one pop-up',
      },
      fetchPolicy: 'no-cache',
    }),
    name: 'legalsite',
  }),
  graphql(COOKIE_POPUP_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
        category: 'Cookies Policy',
      },
      fetchPolicy: 'no-cache',
    }),
    name: 'cookiePopup',
  }),
  graphql(ACCEPT_AGREEMENT, {
    name: 'acceptAgreement',
  }),
  graphql(REQUEST_SIGNED_DPA, {
    name: 'requestDpa',
  }),
  graphql(ADD_DATA_SUBJECT_TO_LEGALSITE, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
      },
    }),
    name: 'addDataSubjectToLegalsite',
  })
)(LegalSite);
