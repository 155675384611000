import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

const host = window.location.host;
export function closeWidget() {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage("close_legalsite_widget", '*');
};

export function setCookie() {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage("set_cookie_legalsite_widget", '*');
};
// let subdomain = "";
// if(host.match(/([^.]+)/g)[1] == "legalsites"){
//   subdomain = host.match(/^([^.]+)/g)[0];
// }
// else{
//   console.log(host)
//   subdomain = host; 
// }

ReactDOM.render(<App subdomain={host} />, document.getElementById("root"));

serviceWorker.unregister();
