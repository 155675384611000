import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { LEGALSITE_NAVBAR_QUERY } from '../../graphql/legalsiteNavbar';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { ADD_DATA_SUBJECT_TO_LEGALSITE } from '../../graphql/addDataSubjectToLegalsite';

const cookies = new Cookies();

class LegalsiteNavbar extends Component {
  static propTypes = {};

  componentDidMount() {
    const visitorTracked = cookies.get('UUID');

    if (typeof visitorTracked === 'undefined') {
      this.props.addDataSubjectToLegalsite().then(({ data }) => {
        cookies.set('UUID', data.addDataSubjectToLegalsite.uid, { path: '/' });
      });
    }
  }

  static getDerivedStateFromProps(props) {
    if (
      props.data &&
      props.data.publicLegalsite &&
      props.data.publicLegalsite.companyName
    ) {
      var title = document.getElementsByTagName('title');
      title[0].innerText = props.data.publicLegalsite.companyName;
    }

    if (
      props.data &&
      props.data.publicLegalsite &&
      props.data.publicLegalsite.favicon
    ) {
      var link = document.querySelector("link[rel*='icon']");
      link.href = props.data.publicLegalsite.favicon;
    }
  }

  formatPageName = (title) => {
    return title.replace('&', '').replace(/\s/g, '');
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  render() {
    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;
      
    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    return (
      <div
        className={`legalsite__nav ${this.props.isPage ? '-page-scroll' : ''}`}
        style={{
          backgroundColor: publicLegalsiteCustomizationSettings
            ? publicLegalsiteCustomizationSettings.navigationBg
            : null,
        }}
      >
        <Link to="/" className="d-flex align-items-center text-decoration-none">
          {publicAccountInfo && publicAccountInfo.companyLogo ? (
            <div className="legalsite__logo-container">
              <img src={publicAccountInfo.companyLogo} alt="logo" />
            </div>
          ) : (
            <span className="legalsite__logo" />
          )}
        </Link>

        <div>
          {!this.props.isHomepage ? (
            <Link
              to="/"
              className="mb-0 legalsite__nav-link -home mr-5"
              style={{
                color: publicLegalsiteCustomizationSettings
                  ? publicLegalsiteCustomizationSettings.navigationLinksColor
                  : null,
                fontSize: publicLegalsiteCustomizationSettings
                  ? publicLegalsiteCustomizationSettings.navigationLinksSize
                  : null,
                fontFamily: publicLegalsiteCustomizationSettings
                  ? publicLegalsiteCustomizationSettings.navigationLinksFont
                  : null,
                textAlign: publicLegalsiteCustomizationSettings
                  ? publicLegalsiteCustomizationSettings.navigationLinksAlign
                  : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.navigationLinksBold
                    ? publicLegalsiteCustomizationSettings.navigationLinksBold ===
                      true
                      ? 900
                      : 300
                    : 600,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.navigationLinksItalic
                    ? publicLegalsiteCustomizationSettings.navigationLinksItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Back to homepage
            </Link>
          ) : null}
          <Link
            to={{
              pathname: '/requests',
              state: {
                publicAccountInfo,
                id: publicAccountInfo ? publicAccountInfo.id : null,
                isDefault: true,
              },
            }}
            style={{
              color: publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.navigationLinksColor
                : null,
              borderColor: publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.navigationLinksColor
                : null,
              fontSize: publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.navigationLinksSize
                : null,
              fontFamily: publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.navigationLinksFont
                : null,
              textAlign: publicLegalsiteCustomizationSettings
                ? publicLegalsiteCustomizationSettings.navigationLinksAlign
                : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.navigationLinksBold
                  ? publicLegalsiteCustomizationSettings.navigationLinksBold ===
                    true
                    ? 900
                    : 300
                  : 600,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.navigationLinksItalic
                  ? publicLegalsiteCustomizationSettings.navigationLinksItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
            className="mb-0 legalsite__nav-link -submit"
          >
            Submit a request
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(LEGALSITE_NAVBAR_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
      },
      fetchPolicy: 'no-cache',
    }),
  }),
  graphql(ADD_DATA_SUBJECT_TO_LEGALSITE, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
      },
    }),
    name: 'addDataSubjectToLegalsite',
  })
)(LegalsiteNavbar);
