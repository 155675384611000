import React, { Component, Fragment } from 'react';
import Footer from '../../components/Footer.js/Footer';
import { Button } from 'reactstrap';
import './requests.scss';
import { REQUEST_QUERY } from '../../graphql/requestQuery';
import img from '../../images/Request Access/Image.svg';
import { graphql } from 'react-apollo';
import DataAccess from './DataAccess';
import DataErasure from './DataErasure';
import LegalsiteNavbar from '../LegalsiteNavbar/LegalsiteNavbar';
import Fonts from '../../containers/LegalSite/Fonts';
import DataTransfer from './DataTransfer';

class RequestData extends Component {
  static propTypes = {};

  state = {
    isSolve: false,
    selectedRequestFile: [],
    isCertify: false,
  };

  setCategory = (value) => {
    this.props.setCategory(value);
  };

  showStatus = (status) => {
    if (status === 'SOLVED') return 'Solved';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'ACTION_REQUIRED') return 'Action required';
    else if (status === 'DELAYED') return 'Delayed';
    else return status;
  };

  onFiles = (res) => {
    this.setState({
      selectedRequestFile: res.filesUploaded,
    });
  };

  render() {
    const { publicLegalsiteCustomizationSettings } = this.props;
    const { requestByToken } = this.props.request;

    return (
      <Fragment>
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />

        <LegalsiteNavbar subdomain={this.props.subdomain} />

        <div
          className="requests__container"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodyBg
                ? publicLegalsiteCustomizationSettings.bodyBg
                : '#f4f6fc',
          }}
        >
          <div
            className="requests__header"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBg
                  ? publicLegalsiteCustomizationSettings.headerBg
                  : '#dfe4ee',
            }}
          >
            <div className="container">
              <h1
                className="requests__header-title"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleColor
                      ? publicLegalsiteCustomizationSettings.headerTitleColor
                      : '#333',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleSize
                      ? publicLegalsiteCustomizationSettings.headerTitleSize
                      : 56,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleFont
                      ? publicLegalsiteCustomizationSettings.headerTitleFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {!this.state.showThankYou
                  ? requestByToken && requestByToken.category === 'DATA_ACCESS'
                    ? 'Data Access Request'
                    : requestByToken &&
                      requestByToken.category === 'DATA_TRANSFER'
                    ? 'Data Transfer Request'
                    : 'Data Erasure Request'
                  : null}
              </h1>
              <p
                className="requests__header-text"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyColor
                      ? publicLegalsiteCustomizationSettings.headerBodyColor
                      : '#969696',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodySize
                      ? publicLegalsiteCustomizationSettings.headerBodySize
                      : 16,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyFont
                      ? publicLegalsiteCustomizationSettings.headerBodyFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {!this.state.showThankYou ? (
                  requestByToken &&
                  requestByToken.category === 'DATA_ACCESS' ? (
                    <span>
                      We have received a request from one of our users for data
                      access. We are already taking the procedures to send the
                      user’s data that is in our systems, but since you are our
                      data processor, we kindly ask that you also send us this
                      user’s data. <br />
                      Below you can see the user info and take action to solve
                      the request.
                    </span>
                  ) : requestByToken &&
                    requestByToken.category === 'DATA_TRANSFER' ? (
                    <span>
                      We have received a request from one of our users for data
                      transfer. We are already taking the procedures to send the
                      user’s data that is in our systems, but since you are our
                      data processor, we kindly ask that you also send us this
                      user’s data.
                      <br />
                      Below you can see the user info and take action to solve
                      the request.{' '}
                    </span>
                  ) : (
                    <span>
                      We have received a request from one of our users for data
                      erasure. We are already taking the procedures to erase the
                      user’s data that is in our systems, but since you are our
                      data processor, we kindly ask that you also erase this
                      data.
                      <br />
                      Below you can see the user info and take action to solve
                      the request.{' '}
                    </span>
                  )
                ) : null}
              </p>
            </div>
          </div>

          <div className="container d-flex flex-wrap justify-content-between align-items-start pb-5">
            {requestByToken && requestByToken.category === 'DATA_ACCESS' ? (
              <DataAccess
                showThankYou={(value) => this.setState({ showThankYou: value })}
                isShowThankYou={this.state.showThankYou}
                selectedRequest={requestByToken}
                token={this.props.token}
                refetchData={() => this.props.request.refetch()}
              />
            ) : requestByToken && requestByToken.category === 'DATA_TRANSFER' ? (
              <DataTransfer
                showThankYou={(value) => this.setState({ showThankYou: value })}
                isShowThankYou={this.state.showThankYou}
                selectedRequest={requestByToken}
                token={this.props.token}
                refetchData={() => this.props.request.refetch()}
              />
            ) : (
              <DataErasure
                showThankYou={(value) => this.setState({ showThankYou: value })}
                isShowThankYou={this.state.showThankYou}
                selectedRequest={requestByToken}
                token={this.props.token}
                refetchData={() => this.props.request.refetch()}
              />
            )}

            {!this.state.showThankYou ? (
              <div className="requests__learn position-relative">
                <p className="requests__learn-title">
                  Create your LegalSite account!
                </p>

                <p className="requests__learn-text">
                  Receive and solve all your clients data requests in a
                  centralized inbox. Comply to privacy laws and protect your
                  company against legal risks with our fast and user-friendly
                  solution.
                </p>

                <div className="requests__learn-btn">
                  <Button
                    className="btn--primary "
                    onClick={() =>
                      window.open('https://legalsite.co/', '_blank')
                    }
                  >
                    Learn more
                  </Button>
                </div>

                <img src={img} alt="learn" className="requests__learn-img" />

                <div className="requests__banner" />
              </div>
            ) : null}
          </div>
        </div>

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />
      </Fragment>
    );
  }
}

export default graphql(REQUEST_QUERY, {
  options: (props) => ({
    variables: {
      token: props.token,
    },
    fetchPolicy: 'no-cache',
  }),
  name: 'request',
})(RequestData);
