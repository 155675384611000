import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { CREATE_QUESTION } from '../../graphql/createQuestion';
import { CREATE_COMPLAINT } from '../../graphql/createComplaint';
import { Input, Button, Form, FormFeedback, FormGroup } from 'reactstrap';

class ComplaintForm extends Component {
  static propTypes = {
    showCheckEmailModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
  };

  state = {
    formErrors: {},
  };

  submitForm = e => {
    e.preventDefault();

    this.props.showCheckEmailModal();
  };

  submitRequest = e => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const fullName = formData.get('fullName'),
      email = formData.get('email'),
      message = formData.get('message'),
      phone = formData.get('phone').trim();

    let hasFormErrors = false;
    let formErrors = {};

    if (!fullName) {
      hasFormErrors = true;
      formErrors.fullName = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!message) {
      hasFormErrors = true;
      formErrors.message = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.type === 'question') {
      this.props
        .createQuestion({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
            message,
          },
        })
        .then(({ data }) => {
          this.props.showCheckEmailModal();
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.type === 'complaint') {
      this.props
        .createComplaint({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
            message,
          },
        })
        .then(({ data }) => {
          this.props.showCheckEmailModal();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { publicLegalsiteCustomizationSettings } = this.props;
    return (
      <Fragment>
        <h4
          className="request-access__help-title"
          style={{
            color:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleColor
                ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                : '#333',
            fontSize:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleSize
                ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                : null,
            fontFamily:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleFont
                ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                : 'Roboto',
            textAlign:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleAlign !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                : null,
            fontWeight:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleBold !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleBold === true
                  ? 900
                  : 500
                : 500,

            fontStyle:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleItalic !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                  true
                  ? 'italic'
                  : 'normal'
                : 'normal',
          }}
        >
          Send us a message
        </h4>
        <p
          className="request-access__help-text"
          style={{
            color:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyColor
                ? publicLegalsiteCustomizationSettings.cardsBodyColor
                : '#292e31',
            fontSize:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodySize
                ? publicLegalsiteCustomizationSettings.cardsBodySize
                : null,
            fontFamily:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyFont
                ? publicLegalsiteCustomizationSettings.cardsBodyFont
                : null,
            textAlign:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyAlign !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyAlign
                : null,
            fontWeight:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                  ? 900
                  : 500
                : 500,
            fontStyle:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyItalic === true
                  ? 'italic'
                  : 'normal'
                : 'normal',
          }}
        >
          Fill in the fields with your info and send us a question or complaint.
        </p>

        <Form onSubmit={this.submitRequest} className="request-access__form">
          <FormGroup className="position-relative mb-4">
            <Input
              type="text"
              name="fullName"
              className="mb-0"
              invalid={this.state.formErrors.fullName}
              placeholder="Full name"
            />

            <FormFeedback>Please provide your full name</FormFeedback>
          </FormGroup>
          <div className="d-flex mb-4 request-access__form-group">
            <FormGroup className="position-relative mb-0 w-100">
              <Input
                type="email"
                name="email"
                className="mb-0"
                invalid={this.state.formErrors.email}
                placeholder="E-mail"
              />
              <FormFeedback>Please provide your email</FormFeedback>
            </FormGroup>

            <Input
              type="tel"
              name="phone"
              className="ml-4"
              placeholder="Phone number"
            />
          </div>
          <FormGroup className="position-relative mb-4">
            <Input
              type="textarea"
              name="message"
              className="mb-0"
              invalid={this.state.formErrors.message}
              placeholder="Leave your message"
            />

            <FormFeedback>Please fill in your question</FormFeedback>
          </FormGroup>

          <Button
            type="submit"
            className="request-access__submit border-0"
            style={{
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnSize
                  ? publicLegalsiteCustomizationSettings.cardsBtnSize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnFont
                  ? publicLegalsiteCustomizationSettings.cardsBtnFont
                  : 'Roboto',
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnColor
                  ? publicLegalsiteCustomizationSettings.cardsBtnColor
                  : '#fff',
              backgroundColor:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnBg
                  ? publicLegalsiteCustomizationSettings.cardsBtnBg
                  : '#7450C8',
              textAlign:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnAlign
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnItalic === true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            Submit
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default compose(
  graphql(CREATE_QUESTION, {
    name: 'createQuestion',
  }),
  graphql(CREATE_COMPLAINT, {
    name: 'createComplaint',
  })
)(ComplaintForm);
