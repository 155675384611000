import React, { Component } from 'react';
import { Alert, Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import SyncLoader from 'react-spinners/SyncLoader';
import './data-access.scss';
import { DATA_ACCESS_QUERY } from '../../graphql/dataAccessQuery';
import Footer from '../../components/Footer.js/Footer';
import FontLoader from '../LegalSite/FontLoader';
import Image from '../../images/Request Access/Illustration.svg';
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';

class DataAccess extends Component {
  static propTypes = {};

  state = {
    subdomain: '',
  };

  componentDidMount() {
    let subdomain = this.props.subdomain;

    this.setState(
      {
        subdomain,
      },
      () => {
        this.props.data.refetch({ subdomain: this.state.subdomain });
      }
    );
  }

  download = () => {
    console.log('download');
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  render() {
    const { loading, error } = this.props.data;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="manage-consent">
        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.titleFont
              ? publicLegalsiteCustomizationSettings.titleFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyFont
              ? publicLegalsiteCustomizationSettings.bodyFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.buttonFont
              ? publicLegalsiteCustomizationSettings.buttonFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.subtitleFont
              ? publicLegalsiteCustomizationSettings.subtitleFont
              : 'Roboto'
          }
        />

        <FontLoader
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font4="Roboto"
        />
        <LegalsiteNavbar subdomain={this.props.subdomain} />

        <div
          className="data-access"
          style={{
            background: publicLegalsiteCustomizationSettings.background2
              ? publicLegalsiteCustomizationSettings.background2
              : null,
          }}
        >
          <div
            className="data-access__header"
            style={{
              background: publicLegalsiteCustomizationSettings.background1
                ? publicLegalsiteCustomizationSettings.background1
                : null,
            }}
          >
            <div className="container">
              <h1
                className="data-access__header-title"
                style={{
                  color: publicLegalsiteCustomizationSettings.titleColor
                    ? publicLegalsiteCustomizationSettings.titleColor
                    : null,
                  fontSize: publicLegalsiteCustomizationSettings.titleSize
                    ? publicLegalsiteCustomizationSettings.titleSize
                    : 56,
                  fontFamily: publicLegalsiteCustomizationSettings.titleFont
                    ? publicLegalsiteCustomizationSettings.titleFont
                    : null,
                }}
              >
                Data Access Request
              </h1>
              <p
                className="data-access__header-text"
                style={{
                  color: publicLegalsiteCustomizationSettings.bodyColor
                    ? publicLegalsiteCustomizationSettings.bodyColor
                    : null,
                  fontSize: publicLegalsiteCustomizationSettings.bodySize
                    ? publicLegalsiteCustomizationSettings.bodySize
                    : 16,
                  fontFamily: publicLegalsiteCustomizationSettings.bodyFont
                    ? publicLegalsiteCustomizationSettings.bodyFont
                    : null,
                }}
              >
                Send data requests, questions or complaints
              </p>
            </div>
          </div>

          <div className="data-access__container container">
            <img src={Image} alt="data-access" />

            <p className="data-access__text">
              Click to download your requested data. The data will be available
              to download
              <br />
              for 14 days.
            </p>

            <Button className="btn--primary" onClick={this.download}>
              Download
            </Button>
          </div>
        </div>

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />
      </div>
    );
  }
}

export default graphql(DATA_ACCESS_QUERY)(DataAccess);
