import gql from "graphql-tag";

export const CREATE_ACCESS_REQUEST = gql`
  mutation(
    $accountId: ID!
    $email: String!
    $fullName: String!
    $phone: String
    $clientMutationId: String
  ) {
    dataSubjectCreateDataAccessRequest(
      input: {
        fullName: $fullName
        email: $email
        accountId: $accountId
        phone: $phone
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
