import React from 'react';
import PropTypes from 'prop-types';

ImpressumContent.propTypes = {
  dpo: PropTypes.object,
  publicLegalsiteCustomizationSettings: PropTypes.object,
  publicLegalInformation: PropTypes.object,
};

function ImpressumContent(props) {
  const {
    dpo,
    publicLegalsiteCustomizationSettings,
    publicLegalInformation,
  } = props;

  return (
    <div>
      <p
        className="dpo__name dpo__title mb-5"
        style={{
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleColor
              ? publicLegalsiteCustomizationSettings.bodyTitleColor
              : '#333',
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleSize
              ? publicLegalsiteCustomizationSettings.bodyTitleSize
              : 36,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Bookmania Semibold',
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleAlign !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleBold !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleItalic !== null
              ? publicLegalsiteCustomizationSettings.bodyTitleItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        Impressum
      </p>

      {publicLegalInformation && publicLegalInformation.companyName ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Company Name:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyName}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.companyAddress ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Company Address:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyAddress}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.companyCity ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>City:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyCity}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.country ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Country:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.country}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.companyZipCode ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>ZIP Code:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyZipCode}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.companyWebsite ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Company website:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyWebsite}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.phoneNumber ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Company phone number:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.phoneNumber}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.vat ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Tax ID:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.vat}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.companyRegistration ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Company Registration Number:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.companyRegistration}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.fullName ? (
        <div className="d-flex dpo__impressum-info mb-4">
          <p
            className="d-flex dpo__impressum-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Legal representative full name:</strong>
          </p>
          <p
            className="dpo__impressum-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.fullName}
          </p>
        </div>
      ) : null}

      {publicLegalInformation && publicLegalInformation.email ? (
        <div
          className={`${
            dpo && dpo.show ? 'mb-4' : 'mb-0'
          } d-flex dpo__impressum-info`}
        >
          <p
            className={`${
              dpo && dpo.show ? '' : 'mb-0'
            } d-flex dpo__impressum-title`}
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <strong>Legal representative e-mail:</strong>
          </p>
          <p
            className={`${dpo && dpo.show ? '' : 'mb-0'} dpo__impressum-text`}
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,

              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {publicLegalInformation.email}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default ImpressumContent;
