import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Page from './components/Page/Page';
import RequestAccess from './components/RequestAccess/RequestAccess';
import LegalSite from './containers/LegalSite/LegalSite';
import Widget from './containers/Widget/Widget';
import NotFound from './containers/NotFound/NotFound';
import './App.scss';
import ManageConsent from './containers/ManageConsent/ManageConsent';
import DPO from './containers/DPO/DPO';
import SignedDpa from './containers/SignedDpa/SignedDpa';
import DataAccess from './containers/DataAccess/DataAccess';
import Requests from './components/Requests/Requests';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL
});

export default class App extends Component {
  state = {};
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <LegalSite {...props} subdomain={this.props.subdomain} />
              )}
            />
            <Route
              exact
              path="/data-access"
              render={props => (
                <DataAccess {...props} subdomain={this.props.subdomain} />
              )}
            />
            <Route
              exact
              path="/requests"
              render={props => (
                <RequestAccess {...props} subdomain={this.props.subdomain} />
              )}
            />
            <Route
              path={['/page?page=:pageName', '/page']}
              render={props => (
                <Page {...props} subdomain={this.props.subdomain} />
              )}
            />
            <Route
              exact
              path="/manage-consent"
              render={props => (
                <ManageConsent {...props} subdomain={this.props.subdomain} />
              )}
            />

            <Route
              exact
              path="/signed-dpa"
              render={props => (
                <SignedDpa {...props} subdomain={this.props.subdomain} />
              )}
            />

            <Route
              exact
              path="/legal"
              render={props => (
                <DPO {...props} subdomain={this.props.subdomain} />
              )}
            />

            <Route
              exact
              path="/request"
              render={props => (
                <Requests {...props} subdomain={this.props.subdomain} />
              )}
            />

            <Route
              exact
              path="/widget/:id"
              render={props => (
                <Widget {...props} subdomain={this.props.subdomain} />
              )}
            />
            <Route
              component={() => <NotFound subdomain={this.props.subdomain} />}
            />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}
