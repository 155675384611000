import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation(
    $requestId: String!
    $type: FileTypeEnum!
    $url: String!
    $verificationToken: String
    $clientMutationId: String
  ) {
    uploadFile(
      input: {
        requestId: $requestId
        type: $type
        url: $url
        verificationToken: $verificationToken
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
