import axios from 'axios'

export async function getIp () {
 	const apiUrl = window.encodeURI('https://api.ipify.org?format=json'),
	ip = await axios.get(apiUrl)
	    .catch(err => {
	    	console.log(err);
	    });

  	return ip.data.ip
}