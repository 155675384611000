import gql from 'graphql-tag';

export const SIGN_DPA = gql`
  mutation(
    $dpaToken: String!
    $commencementDate: String!
    $signature: String!
    $name: String!
    $clientMutationId: String
  ) {
    signDpa(
      input: {
        dpaToken: $dpaToken
        commencementDate: $commencementDate
        signature: $signature
        name: $name
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      downloadLink
    }
  }
`;
