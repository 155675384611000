import gql from 'graphql-tag';

export const ADD_DATA_SUBJECT_TO_LEGALSITE = gql`
  mutation(
    $subdomain: String!
  ) {
    addDataSubjectToLegalsite(
      input: {
        subdomain: $subdomain
      }
    ) {
      uid
    }
  }
`;
