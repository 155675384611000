import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { CREATE_ACCESS_REQUEST } from '../../graphql/dataSubjectCreateDataAccessRequest';
import { DATA_ERASURE } from '../../graphql/dataSubjectCreateDataErasureRequest';
import { DATA_SELL } from '../../graphql/dataSubjectCreateDontSellDataRequest';
import { CREATE_DATA_TRANSFER } from '../../graphql/dataSubjectCreateDataTransferRequest';
import { Input, Button, Form, FormGroup, FormFeedback } from 'reactstrap';

class RequestForm extends Component {
  static propTypes = {
    showCheckEmailModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
  };

  state = {
    formErrors: {},
    isAccept: false,
  };

  submitRequest = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const fullName = formData.get('fullName'),
      email = formData.get('email'),
      phone = formData.get('phone').trim();

    let hasFormErrors = false;
    let formErrors = {};

    if (!fullName) {
      hasFormErrors = true;
      formErrors.fullName = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.type === 'data access') {
      this.props
        .createAccess({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
          },
        })
        .then(() => {
          this.props.showCheckEmailModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.props.type === 'data erasure') {
      this.props
        .dataErasure({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
          },
        })
        .then(() => {
          this.props.showCheckEmailModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.props.type === 'sell') {
      this.props
        .createSell({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
          },
        })
        .then(() => {
          this.props.showCheckEmailModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.props.type === 'data transfer') {
      this.props
        .createDataTransfer({
          variables: {
            accountId: this.props.accountId,
            email,
            fullName,
            phone,
          },
        })
        .then(() => {
          this.props.showCheckEmailModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { publicLegalsiteCustomizationSettings, mail, type } = this.props;
    const { isAccept } = this.state;

    return (
      <Fragment>
        <h4
          className="request-access__help-title"
          style={{
            color:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleColor
                ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                : '#333',
            fontSize:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleSize
                ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                : null,
            fontFamily:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleFont
                ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                : 'Roboto',
            textAlign:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleAlign !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                : null,
            fontWeight:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleBold !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleBold === true
                  ? 900
                  : 500
                : 500,

            fontStyle:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodySubtitleItalic !== null
                ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                  true
                  ? 'italic'
                  : 'normal'
                : 'normal',
          }}
        >
          Personal information
        </h4>
        <p
          className="request-access__help-text"
          style={{
            color:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyColor
                ? publicLegalsiteCustomizationSettings.cardsBodyColor
                : '#292e31',
            fontSize:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodySize
                ? publicLegalsiteCustomizationSettings.cardsBodySize
                : null,
            fontFamily:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyFont
                ? publicLegalsiteCustomizationSettings.cardsBodyFont
                : null,
            textAlign:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyAlign !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyAlign
                : null,
            fontWeight:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                  ? 900
                  : 500
                : 500,
            fontStyle:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                ? publicLegalsiteCustomizationSettings.cardsBodyItalic === true
                  ? 'italic'
                  : 'normal'
                : 'normal',
          }}
        >
          Fill in the fields with your data.
        </p>

        <Form onSubmit={this.submitRequest} className="request-access__form">
          <FormGroup className="position-relative mb-4">
            <Input
              type="text"
              name="fullName"
              invalid={this.state.formErrors.fullName}
              className="mb-0"
              placeholder="Full name"
            />
            <FormFeedback>Please provide your full name</FormFeedback>
          </FormGroup>

          <div className="d-flex mb-4 request-access__form-group">
            <FormGroup className="position-relative mb-4 w-100">
              <Input
                name="email"
                type="email"
                className="mb-0"
                invalid={this.state.formErrors.email}
                placeholder="E-mail"
              />
              <FormFeedback>Please provide your email</FormFeedback>
            </FormGroup>
            <Input
              type="tel"
              className="ml-4"
              name="phone"
              placeholder="Phone number"
            />
          </div>

          {type === 'data erasure' ? (
            <div className="d-flex align-items-baseline mb-4">
              <span
                className={`${
                  this.state.isAccept ? '-is-active' : ''
                } request-access__check mr-3`}
                onClick={() =>
                  this.setState({
                    isAccept: !isAccept,
                  })
                }
              />
              I agree to have my personal data erasure from this company's
              systems, including their data processors.
            </div>
          ) : null}

          <Button
            type="submit"
            className="request-access__submit border-0"
            disabled={type === 'data erasure' ? !this.state.isAccept : false}
            style={{
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnSize
                  ? publicLegalsiteCustomizationSettings.cardsBtnSize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnFont
                  ? publicLegalsiteCustomizationSettings.cardsBtnFont
                  : 'Roboto',
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnColor
                  ? publicLegalsiteCustomizationSettings.cardsBtnColor
                  : '#fff',
              backgroundColor:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnBg
                  ? publicLegalsiteCustomizationSettings.cardsBtnBg
                  : '#7450C8',
              textAlign:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnAlign
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBtnItalic === true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            Submit
          </Button>
        </Form>

        {mail ? (
          <p className="request-access__email mt-5">
            Or send an e-mail to <a href={`mailto:${mail}`}>{mail}</a>
          </p>
        ) : null}
      </Fragment>
    );
  }
}

export default compose(
  graphql(DATA_ERASURE, {
    name: 'dataErasure',
  }),
  graphql(CREATE_ACCESS_REQUEST, {
    name: 'createAccess',
  }),
  graphql(CREATE_DATA_TRANSFER, {
    name: 'createDataTransfer',
  }),
  graphql(DATA_SELL, {
    name: 'createSell',
  })
)(RequestForm);
