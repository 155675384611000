import PropTypes from "prop-types";
import React from "react";

const Consent = props => (
  <svg
    height={27}
    viewBox="0 0 27 27"
    width={27}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="3.3--Cookies-preview-1---tooltip"
        transform="translate(-861.000000, -883.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-2">
          <g id="cards-2" transform="translate(485.000000, 882.000000)">
            <g id="manage-consent" transform="translate(308.000000, 1.000000)">
              <g id="comment-edit" transform="translate(68.000000, 0.000000)">
                <path
                  d="M26.6074104,3.67292456 L23.3267474,0.392554581 C22.8001706,-0.130851527 21.9497295,-0.130851527 21.4231528,0.392554581 L13.8897785,7.92525602 C13.6370641,8.18001635 13.4961843,8.52489823 13.498259,8.88371804 L13.498259,12.1505886 C13.498259,12.8961434 14.1027046,13.500535 14.8483261,13.500535 L18.1154884,13.500535 C18.4743403,13.5026096 18.819253,13.3617423 19.0740361,13.1090506 L26.6074104,5.57634912 C27.1308632,5.04981943 27.1308632,4.19945426 26.6074104,3.67292456 Z M17.5484603,10.8006421 L16.1983932,10.8006421 L16.1983932,9.45069556 L22.3817004,3.26794062 L23.7317675,4.61788711 L17.5484603,10.8006421 Z M22.9487286,13.500535 C22.2031071,13.500535 21.5986615,14.1049267 21.5986615,14.8504815 C21.5986615,20.0693656 17.3675422,24.300107 12.148192,24.300107 L4.60131699,24.300107 L5.46535991,23.4496407 C5.72094469,23.1961659 5.86470749,22.8511251 5.86470749,22.4911787 C5.86470749,22.1312323 5.72094469,21.7861916 5.46535991,21.5327167 C2.76225683,18.8301309 1.95358134,14.7654375 3.41645287,11.2341742 C4.87932439,7.70291086 8.32562196,5.40058028 12.148192,5.40085608 C12.8938134,5.40085608 13.498259,4.79646444 13.498259,4.05090958 C13.498259,3.30535472 12.8938134,2.70096309 12.148192,2.70096309 C7.48536017,2.71628913 3.24258887,5.39839536 1.22894158,9.60366591 C-0.784705697,13.8089365 -0.213997918,18.7955122 2.69772241,22.4371808 L0.389107702,24.6915915 C0.00603351013,25.0797831 -0.105822474,25.660317 0.105593616,26.1630332 C0.312698763,26.6671376 0.802630702,26.9972797 1.34765533,27 L12.148192,27 C18.8587851,27 24.2987957,21.5604753 24.2987957,14.8504815 C24.2987957,14.1049267 23.69435,13.500535 22.9487286,13.500535 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Consent.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default Consent;