import React from "react";
import PropTypes from "prop-types";
import PopupContent from './PopupContent';
// import "./popup.scss";

Popup.propTypes = {
  publicLegalsiteCustomizationPopup: PropTypes.object,
  actualLegalsitePage: PropTypes.object,
  closePopup: PropTypes.func,
  handleCookieAccept: PropTypes.func
};

function Popup(props) {
  const {
    publicLegalsiteCustomizationPopup,
    actualLegalsitePage,
    closePopup,
    handleCookieAccept,
    actualLegalsitePages,
    publicLegalsiteCustomizationSettings,
    account,
    publicConsents,
    email,
    getEmail,
    getPage
  } = props;

  return (
    <div
      className={`${
        publicLegalsiteCustomizationPopup.popupSide === "left" &&
        publicLegalsiteCustomizationPopup.template === "squared popup"
          ? "-is-left"
          : publicLegalsiteCustomizationPopup.popupSide === "right" &&
            publicLegalsiteCustomizationPopup.template === "squared popup"
          ? "-is-right"
          : (publicLegalsiteCustomizationPopup.popupPosition === "bottom" &&
              publicLegalsiteCustomizationPopup.template ===
                "vertical popup") ||
            !publicLegalsiteCustomizationPopup.template
          ? "-is-bottom"
          : publicLegalsiteCustomizationPopup.popupPosition === "top" &&
            publicLegalsiteCustomizationPopup.template === "vertical popup"
          ? "-is-top"
          : publicLegalsiteCustomizationPopup.overlay === "light"
          ? "-is-white"
          : publicLegalsiteCustomizationPopup.overlay === "dark"
          ? "-is-gray"
          : ""
      } popup__cookie-popup`}
    >
      <PopupContent
        publicLegalsiteCustomizationPopup={publicLegalsiteCustomizationPopup}
        getEmail={getEmail}
        email={email}
        isAll={true}
        actualLegalsitePage={actualLegalsitePage}
        closePopup={closePopup}
        publicLegalsiteCustomizationSettings={
          publicLegalsiteCustomizationSettings
        }
        handleCookieAccept={handleCookieAccept}
        actualLegalsitePages={actualLegalsitePages}
        account={account}
        getPage={getPage}
        publicConsents={publicConsents}
        widget={false}
      />
    </div>
  );
}

export default Popup;
