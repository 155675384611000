import gql from 'graphql-tag';

export const ACTUAL_PAGE_QUERY = gql`
  query($subdomain: String!, $publicationId: ID!, $category: String!) {
    publicLegalsite(subdomain: $subdomain) {
      consents {
        categoryName
        completeDescription
        id
        manageableByVisitor
        smallDescription
        dataProcessorConsents {
          id
          consentName
          dataProcessorCompanyName
          dataProcessorLogo
          purpose
        }
      }
      actualPages {
        description
        applicable
        id
        updatedAt
        title
        version
        sections {
          id
          applicable
          position
          title
          text
        }
      }
      customizationLegalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnBg
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        footerBg
        footerLinksFont
        footerTextFont
        headerBg
        homepageTitle
        homepageDescription
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        navigationBg
        navigationLinksFont
      }
    }
    actualLegalsitePage(publicationId: $publicationId) {
      id
      title
      updatedAt
      version
      description
      sections {
        id
        position
        text
        title
        applicable
      }
    }
    publicLegalsiteCustomizationPopup(
      subdomain: $subdomain
      category: $category
    ) {
      backgroundColor
      body
      bodyColor
      bodyFont
      bodySize
      bodyAlign
      bodyBold
      bodyItalic
      buttonBgColor
      buttonColor
      buttonFont
      buttonShapes
      buttonSize
      buttonText
      catchEmail
      catchSignature
      destinationPage
      overlay
      popupPosition
      popupSide
      secondaryButtonColor
      secondaryButtonFont
      secondaryButtonSize
      secondaryButtonText
      template
      title
      titleColor
      titleAlign
      titleBold
      titleItalic
      titleFont
      titleSize
      verifyUser
      showLegalsiteBranding
      buttonBold
      buttonItalic
    }
  }
`;
