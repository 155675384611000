import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { graphql } from 'react-apollo';
import SyncLoader from 'react-spinners/SyncLoader';
import ManageConsentItem from './ManageConsentItem';
import Cookies from 'universal-cookie';
import './manage-consent.scss';
import { MANAGE_CONSENT_QUERY } from '../../graphql/manageConsent';
import uuid from 'react-uuid';
import Footer from '../../components/Footer.js/Footer';
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';
import Fonts from '../LegalSite/Fonts';

const cookies = new Cookies();
let publicConsents;

class ManageConsent extends Component {
  static propTypes = {};

  state = {
    updatedConsents: null,
    pageId: null,
    subdomain: '',
    hasPopup: true,
    selectedItems: [],
    urls: [],
    uid: uuid(),
  };

  componentDidMount() {
    let subdomain = this.props.subdomain;

    // if (this.props.location.state) {
    //   this.setState({
    //     publicConsents: this.props.location.state.publicConsents,
    //   });
    // }

    this.setState(
      {
        subdomain,
      },
      () => {
        this.props.data.refetch({
          subdomain: this.state.subdomain,
        });
      }
    );

    const consents = cookies.get('consents');

    if (consents && consents.consents && consents.consents.length > 0) {
      this.setState({
        updatedConsents: consents.consents,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.data.publicLegalsite &&
      props.data.publicLegalsite.consents &&
      state.updatedConsents
    ) {
      if (
        props.data.publicLegalsite.consents.length !==
        state.updatedConsents.length
      ) {
        let subdomain = props.subdomain;

        props.data.refetch({
          subdomain,
        });

        const consents = cookies.get('consents');

        const checked = consents
          ? consents.consents.filter((item) => item.checked)
          : [];

        const con = props.data.publicLegalsite.consents.map(
          (obj) => checked.find((o) => o.id === obj.id) || obj
        );

        cookies.remove('Cookies Policy');
        cookies.remove('isAllAccepted');

        return {
          updatedConsents: con,
        };
      }
    }

    // if (
    //   props.data.publicConsents &&
    //   !state.publicConsents &&
    //   !props.location.state
    // ) {
    //   const consents = cookies.get('consents');

    //   if (!consents) {
    //     return {
    //       publicConsents: props.data.publicConsents,
    //     };
    //   }
    // }
  }

  checkConsent = (
    categoryName,
    id,
    smallDescription,
    completeDescription,
    manageableByVisitor,
    checked,
    dataProcessorConsents
  ) => {
    const arr1 = this.state.updatedConsents
      ? this.state.updatedConsents
      : publicConsents;
    const arr2 = [
      {
        categoryName,
        checked,
        id,
        smallDescription,
        completeDescription,
        manageableByVisitor,
        dataProcessorConsents,
      },
    ];

    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

    const res = arr1.map(
      (obj) => arr2.find((o) => o.categoryName === obj.categoryName) || obj
    );

    this.setState(
      {
        updatedConsents: res,
      },
      () => {
        cookies.set(
          'consents',
          {
            consents: this.state.updatedConsents,
          },
          {
            path: '/',
            expires: expireDate,
          }
        );
        cookies.set('acceptedAll', false, {
          path: '/',
          expires: expireDate,
        });
      }
    );

    // this.setState({
    //   publicConsents: res,
    // });

    // const selectedItems = res.map(item => {
    //   return {
    //     categoryName: item.categoryName,
    //     checked: item.checked,
    //     completeDescription: item.completeDescription,
    //     id: item.id,
    //     manageableByVisitor: item.manageableByVisitor,
    //     smallDescription: item.smallDescription,
    //     dataProcessorConsents: item.dataProcessorConsents,
    //   };
    // });

    // this.setState({
    //   selectedItems,
    // });
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  render() {
    const { loading, error } = this.props.data;

    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    publicConsents = this.state.updatedConsents
      ? this.state.updatedConsents
      : this.props.data &&
        this.props.data.publicLegalsite &&
        this.props.data.publicLegalsite.consents;

    const acceptedAll = cookies.get('acceptedAll');

    let selectedItems;
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

    if (acceptedAll === 'true' && publicConsents) {
      publicConsents = publicConsents.map((v) => ({ ...v, checked: true }));

      cookies.set(
        'consents',
        {
          consents: selectedItems,
        },
        {
          path: '/',
          expires: expireDate,
        }
      );
    }

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found! </Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data! </Alert>
      );

    return (
      <div className="manage-consent">
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />
        <LegalsiteNavbar subdomain={this.props.subdomain} />
        <div
          className="manage-consent__container h-auto"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodyBg
                ? publicLegalsiteCustomizationSettings.bodyBg
                : '#f4f6fc',
          }}
        >
          <div
            className="manage-consent__header"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBg
                  ? publicLegalsiteCustomizationSettings.headerBg
                  : '#dfe4ee',
            }}
          >
            <div className="container">
              <h1
                className="manage-consent__header-title"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleColor
                      ? publicLegalsiteCustomizationSettings.headerTitleColor
                      : '#333',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleSize
                      ? publicLegalsiteCustomizationSettings.headerTitleSize
                      : 56,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleFont
                      ? publicLegalsiteCustomizationSettings.headerTitleFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                Manage Consent{' '}
              </h1>{' '}
              <p
                className="manage-consent__header-text"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyColor
                      ? publicLegalsiteCustomizationSettings.headerBodyColor
                      : '#969696',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodySize
                      ? publicLegalsiteCustomizationSettings.headerBodySize
                      : 16,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyFont
                      ? publicLegalsiteCustomizationSettings.headerBodyFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {publicAccountInfo && publicAccountInfo.consentMessage
                  ? publicAccountInfo.consentMessage
                  : 'Here you can manage what data you agree to share with us. Don’t worry, you can change them anytime you like.'}{' '}
              </p>{' '}
            </div>{' '}
          </div>
          <div
            className="manage-consent__items container mb-5"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBg
                  ? publicLegalsiteCustomizationSettings.cardsBg
                  : null,
            }}
          >
            {' '}
            {publicConsents
              ? publicConsents.map((item) => (
                  <ManageConsentItem
                    key={item.id}
                    id={item.id}
                    publicLegalsiteCustomizationSettings={publicLegalsiteCustomizationSettings}
                    item={item}
                    title={item.categoryName}
                    text={item.smallDescription}
                    completeDescription={item.completeDescription}
                    hasToggle={item.manageableByVisitor}
                    checkConsent={this.checkConsent}
                    checked={item && item.checked === true ? true : false}
                    dataProcessorConsents={item.dataProcessorConsents}
                  />
                ))
              : null}{' '}
          </div>{' '}
        </div>
        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />{' '}
      </div>
    );
  }
}

export default graphql(MANAGE_CONSENT_QUERY)(ManageConsent);
