import gql from 'graphql-tag';

export const FOOTER_QUERY = gql`
  query($subdomain: String!) {
    publicLegalsite(subdomain: $subdomain) {
      companyLogo
      id
      access
      companyWebsite
      companyName
      customizationLegalsite {
        footerBg
        footerLinksAlign
        footerLinksBold
        footerLinksColor
        footerLinksFont
        footerLinksItalic
        footerLinksSize
        footerLogo
        footerTextAlign
        footerTextBold
        footerTextColor
        footerTextFont
        footerTextItalic
        footerTextSize
        showLegalsiteBranding
      }
      customizationDontSellMyData {
        buttonType
        color
        id
        snippet
        targetAudience
        text
        textColor
        textFont
        textSize
      }
      dataProtectionOfficer {
        id
        show
      }
      legalInformation {
        id
      }
      actualPages {
        description
        id
        title
        version
        updatedAt
        sections {
          id
          position
          title
          text
        }
      }
      consents {
        categoryName
        completeDescription
        id
        manageableByVisitor
        smallDescription
        dataProcessorConsents {
          id
          consentName
          dataProcessorCompanyName
          dataProcessorLogo
          purpose
        }
      }
    }
  }
`;
