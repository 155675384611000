import PropTypes from 'prop-types';
import React from 'react';

const TrashIcon = props => (
    <svg
        height={19}
        viewBox='0 0 17 19'
        width={17}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: 'middle',
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1321.000000, -1059.000000)" fill={props.color} fillRule="nonzero">
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-4" transform="translate(0.000000, 52.000000)">
                            <g id="Group-5" transform="translate(1140.000000, 3.000000)">
                                <g id="trash-alt">
                                    <path d="M6.61111111,15.2 C7.13271337,15.2 7.55555556,14.7746705 7.55555556,14.25 L7.55555556,8.55 C7.55555556,8.02532949 7.13271337,7.6 6.61111111,7.6 C6.08950885,7.6 5.66666667,8.02532949 5.66666667,8.55 L5.66666667,14.25 C5.66666667,14.7746705 6.08950885,15.2 6.61111111,15.2 Z M16.0555556,3.8 L12.2777778,3.8 L12.2777778,2.85 C12.2777778,1.27598846 11.0092512,0 9.44444444,0 L7.55555556,0 C5.99074876,0 4.72222222,1.27598846 4.72222222,2.85 L4.72222222,3.8 L0.944444444,3.8 C0.422842181,3.8 0,4.22532949 0,4.75 C0,5.27467051 0.422842181,5.7 0.944444444,5.7 L1.88888889,5.7 L1.88888889,16.15 C1.88888889,17.7240115 3.15741543,19 4.72222222,19 L12.2777778,19 C13.8425846,19 15.1111111,17.7240115 15.1111111,16.15 L15.1111111,5.7 L16.0555556,5.7 C16.5771578,5.7 17,5.27467051 17,4.75 C17,4.22532949 16.5771578,3.8 16.0555556,3.8 Z M6.61111111,2.85 C6.61111111,2.32532949 7.03395329,1.9 7.55555556,1.9 L9.44444444,1.9 C9.96604671,1.9 10.3888889,2.32532949 10.3888889,2.85 L10.3888889,3.8 L6.61111111,3.8 L6.61111111,2.85 Z M13.2222222,16.15 C13.2222222,16.6746705 12.79938,17.1 12.2777778,17.1 L4.72222222,17.1 C4.20061996,17.1 3.77777778,16.6746705 3.77777778,16.15 L3.77777778,5.7 L13.2222222,5.7 L13.2222222,16.15 Z M10.3888889,15.2 C10.9104912,15.2 11.3333333,14.7746705 11.3333333,14.25 L11.3333333,8.55 C11.3333333,8.02532949 10.9104912,7.6 10.3888889,7.6 C9.86728663,7.6 9.44444444,8.02532949 9.44444444,8.55 L9.44444444,14.25 C9.44444444,14.7746705 9.86728663,15.2 10.3888889,15.2 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

TrashIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default TrashIcon;