import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { Alert } from 'reactstrap';
import { ACTUAL_PAGE_QUERY } from '../../graphql/actualPage';
import SyncLoader from 'react-spinners/SyncLoader';
import Cookies from 'universal-cookie';
import { ACCEPT_AGREEMENT } from '../../graphql/dataSubjectAcceptAgreement';
import { ADD_DATA_SUBJECT_TO_LEGALSITE } from '../../graphql/addDataSubjectToLegalsite';
import * as compose from 'lodash.flowright';
import './page.scss';
import Popup from './Popup';
import { getIp } from '../../api/getIp';
import Footer from '../Footer.js/Footer';
import ConfirmationModal from '../Modals/ConfirmationModal';
import LegalsiteNavbar from '../LegalsiteNavbar/LegalsiteNavbar';
import Fonts from '../../containers/LegalSite/Fonts';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';

const cookies = new Cookies();

class Page extends Component {
  static propTypes = {};

  state = {
    pageId: null,
    subdomain: '',
    hasPopup: true,
    ip: 'Undefined',
    acceptedConsents: null,
    isSignedConfirmation: false,
    pageName: null,
    fetchedPage: null,
    email: null,
    isConfirmationModalVisible: false,
    close: false,
    updatedConsents: null,
  };

  componentDidMount() {
    this.setState({
      email: null,
    });
    const visitorTracked = cookies.get('UUID');

    const consents = cookies.get('consents');

    if (consents && consents.consents && consents.consents.length > 0) {
      this.setState({
        updatedConsents: consents.consents,
      });
    }

    if (typeof visitorTracked === 'undefined') {
      this.props.addDataSubjectToLegalsite().then(({ data }) => {
        cookies.set('UUID', data.addDataSubjectToLegalsite.uid, { path: '/' });
      });
    }
    if (this.props.location.state) {
      const hasPopup = cookies.get(this.props.location.state.page.title);

      this.setState({
        hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
      });

      this.setState(
        {
          pageId: this.props.location.state.page.id,
        },
        () => {
          this.props.page.refetch({
            publicationId: this.state.pageId,
            category: this.props.location.state.page.title,
          });
        }
      );

      const pageVersion = cookies.get(
        `${this.props.location.state.page.title}-version`
      );

      if (pageVersion) {
        if (
          pageVersion !== JSON.stringify(this.props.location.state.page.version)
        ) {
          cookies.remove(`${this.props.location.state.page.title}`);
        }
      }

      if (consents && consents.consents && consents.consents.length > 1) {
        const acceptedConsents = consents.consents
          .filter((item) => item.checked === true || item.checked === undefined)
          .map((item) => item.categoryName);

        this.setState({
          acceptedConsents: acceptedConsents.toString(),
        });
      }
    } else if (!this.props.location.state) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pageId = params.get('id');
      let pageName = params.get('page');

      this.setState({
        pageId,
        pageName,
      });
    }

    this.showIp();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.state && props.location.state.page.id !== state.pageId) {
      const hasPopup = cookies.get(props.location.state.page.title);

      if (props.location.state) {
        props.page.refetch({
          publicationId: props.location.state.page.id,
          category: props.location.state.page.title,
        });

        const consents = cookies.get('consents');
        const pageVersion = cookies.get(
          `${props.location.state.page.title}-version`
        );

        if (pageVersion) {
          if (
            pageVersion !== JSON.stringify(props.location.state.page.version)
          ) {
            cookies.remove(`${props.location.state.page.title}`);
          }
        }

        if (consents && consents.consents && consents.consents.length > 1) {
          const acceptedConsents = consents.consents
            .filter(
              (item) => item.checked === true || item.checked === undefined
            )
            .map((item) => item.categoryName);

          return {
            pageId: props.location.state.page.id,
            hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
            pageName: null,
            acceptedConsents: acceptedConsents.toString(),
            email: null,
          };
        }

        return {
          pageId: props.location.state.page.id,
          hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
          pageName: null,
          email: null,
        };
      } else {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pageId = params.get('id');

        props.page.refetch({ publicationId: pageId });

        const consents = cookies.get('consents');
        const pageVersion = cookies.get(
          `${props.location.state.page.title}-version`
        );

        if (pageVersion) {
          if (
            pageVersion !== JSON.stringify(props.location.state.page.version)
          ) {
            cookies.remove(`${props.location.state.page.title}`);
          }
        }

        if (consents && consents.consents.length > 1) {
          const acceptedConsents = consents.consents
            .filter(
              (item) => item.checked === true || item.checked === undefined
            )
            .map((item) => item.categoryName);

          return {
            acceptedConsents: acceptedConsents.toString(),
            pageId,
            hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
            pageName: null,
            email: null,
          };
        }

        return {
          pageId,
          hasPopup: hasPopup === 'false' || !hasPopup ? false : true,
          pageName: null,
          email: null,
        };
      }
    } else if (
      state.pageName &&
      props.page &&
      props.page.actualLegalsitePages
    ) {
      const fetchedPage = props.page.actualLegalsitePages.filter(
        (item) =>
          item.title.replace('&', '').replace(/\s/g, '') ===
          state.pageName.replace(/\s/g, '')
      );

      const page = fetchedPage[0];

      const hasPopup = cookies.get(fetchedPage[0].title);

      props.page.refetch({
        publicationId: page.id,
        category: page.title,
      });

      return {
        hasPopup: hasPopup === undefined || hasPopup === 'false' ? false : true,
        pageId: page.id,
        fetchedPage: page,
        email: null,
      };
    }

    if (
      props.page.publicLegalsite &&
      props.page.publicLegalsite.consents &&
      state.updatedConsents
    ) {
      if (
        props.page.publicLegalsite.consents.length !==
        state.updatedConsents.length
      ) {
        let subdomain = props.subdomain;

        props.legalsite.refetch({
          subdomain,
        });

        const consents = cookies.get('consents');

        const checked = consents
          ? consents.consents.filter((item) => item.checked)
          : [];

        const con = props.legalsite.publicLegalsite.consents.map(
          (obj) => checked.find((o) => o.id === obj.id) || obj
        );

        cookies.remove('Cookies Policy');
        cookies.remove('isAllAccepted');

        let expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

        cookies.set(
          'consents',
          {
            consents: con,
          },
          {
            path: '/',
            expires: expireDate,
          }
        );
        return {
          updatedConsents: con,
        };
      }
    }
  }

  showIp = async () => {
    try {
      const ip = await getIp();

      this.setState({
        ip,
      });
    } catch (err) {
      console.log(err);
    }
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalVisible: !this.state.isConfirmationModalVisible,
    });
  };

  handleCookieAccept = () => {
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 180 * 1000 * 60 * 1000);

    const category = this.props.page.publicLegalsiteCustomizationPopup.title;
    const ipNumber = this.state.ip;
    const userAgent = navigator.userAgent;
    const originUrl = window.location.origin;

    this.setState({
      hasPopup: true,
    });

    this.props
      .acceptAgreement({
        variables: {
          subdomain: this.props.subdomain,
          uid: cookies.get('UUID'),
          category:
            this.props.page.publicLegalsiteCustomizationPopup.title !==
            'Cookie preferences'
              ? category
              : this.state.acceptedConsents
              ? this.state.acceptedConsents
              : 'all cookies',
          ipNumber,
          userAgent,
          originUrl,
          email: this.state.email ? this.state.email : '',
        },
      })
      .then(() => {
        cookies.set(
          `${
            this.state.fetchedPage
              ? this.state.fetchedPage.title
              : this.props.location.state.page.title
          }`,
          true,
          {
            path: '/',
            expires: expireDate,
          }
        );
        cookies.set(
          `${
            this.state.fetchedPage
              ? this.state.fetchedPage.title
              : this.props.location.state.page.title
          }-version`,
          this.state.fetchedPage
            ? `${this.state.fetchedPage.version}`
            : `${this.props.location.state.page.version}`,
          {
            path: '/',
            expires: expireDate,
          }
        );

        if (
          (this.state.fetchedPage &&
            this.state.fetchedPage.title === 'Cookies Policy') ||
          (this.props.location.state &&
            this.props.location.state.page &&
            this.props.location.state.page.title === 'Cookies Policy')
        ) {
          cookies.set('acceptedAll', true, {
            path: '/',
            expires: expireDate,
          });
        }

        if (
          this.props.page.publicLegalsiteCustomizationPopup &&
          this.props.page.publicLegalsiteCustomizationPopup.destinationPage
        ) {
          window.open(
            `//${this.props.page.publicLegalsiteCustomizationPopup.destinationPage}`,
            '_blank'
          );
        }

        if (
          this.props.page.publicLegalsiteCustomizationPopup &&
          this.props.page.publicLegalsiteCustomizationPopup.catchSignature
        ) {
          this.showConfirmationModal();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      hasPopup: true,
    });
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  closePopup = () => {
    this.setState({
      hasPopup: true,
      close: true,
    });
  };

  formatPageName = (title) => {
    return title.replace('&', '').replace(/\s/g, '');
  };

  scrollToSection = (section) => {
    this.refs[section].scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  render() {
    const {
      loading,
      error,
      actualLegalsitePage,
      publicLegalsiteCustomizationPopup,
    } = this.props.page;

    const publicConsents =
      this.props.page &&
      this.props.page.publicLegalsite &&
      this.props.page.publicLegalsite.consents;

    const actualLegalsitePages =
      this.props.page &&
      this.props.page.publicLegalsite &&
      this.props.page.publicLegalsite.actualPages;

    const publicLegalsiteCustomizationSettings =
      this.props.page &&
      this.props.page.publicLegalsite &&
      this.props.page.publicLegalsite.customizationLegalsite;

    const { hasPopup } = this.state;

    let page;

    if (this.state.pageName && actualLegalsitePages) {
      const fetchedPage = actualLegalsitePages.filter(
        (item) =>
          this.formatPageName(item.title) ===
          this.state.pageName.replace(/\s/g, '')
      );

      page = fetchedPage[0];
    }

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="terms">
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />

        <LegalsiteNavbar isPage={true} subdomain={this.props.subdomain} />

        <div
          className="terms__term"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.headerBg
                ? publicLegalsiteCustomizationSettings.headerBg
                : '#dfe4ee',
          }}
        >
          <h1
            className="terms__term-title"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleColor
                  ? publicLegalsiteCustomizationSettings.headerTitleColor
                  : '#333',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleSize
                  ? publicLegalsiteCustomizationSettings.headerTitleSize
                  : 56,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleFont
                  ? publicLegalsiteCustomizationSettings.headerTitleFont
                  : null,
              textAlign:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                  ? publicLegalsiteCustomizationSettings.headerTitleAlign
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleBold !== null
                  ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                    true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerTitleItalic !== null
                  ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {page
              ? page.title
              : actualLegalsitePage
              ? actualLegalsitePage.title
              : null}
          </h1>
          <p
            className="terms__term-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyColor
                  ? publicLegalsiteCustomizationSettings.headerBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodySize
                  ? publicLegalsiteCustomizationSettings.headerBodySize
                  : 16,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyFont
                  ? publicLegalsiteCustomizationSettings.headerBodyFont
                  : null,
              textAlign:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyAlign
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            {page
              ? page.description
              : actualLegalsitePage
              ? actualLegalsitePage.description
              : null}
          </p>

          <span
            className="terms__term-text"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyColor
                  ? publicLegalsiteCustomizationSettings.headerBodyColor
                  : '#969696',
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodySize
                  ? publicLegalsiteCustomizationSettings.headerBodySize
                  : 16,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyFont
                  ? publicLegalsiteCustomizationSettings.headerBodyFont
                  : null,
              textAlign:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyAlign
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyBold === true
                    ? 900
                    : 500
                  : 500,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            Last updated at{' '}
            <Moment format="D MMMM YYYY">
              {page
                ? page.updatedAt
                : actualLegalsitePage
                ? actualLegalsitePage.updatedAt
                : ''}
            </Moment>
          </span>
        </div>

        <div
          className="terms__content"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodyBg
                ? publicLegalsiteCustomizationSettings.bodyBg
                : '#f4f6fc',
          }}
        >
          <div className="d-flex terms__container">
            <div className="terms__menu">
              {!page && actualLegalsitePage
                ? actualLegalsitePage.sections
                    .sort(
                      (a, b) => parseFloat(a.position) - parseFloat(b.position)
                    )
                    .map((section, i) =>
                      section.applicable ? (
                        <p
                          key={i}
                          className="terms__menu-title"
                          onClick={() => this.scrollToSection(section.title)}
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleColor
                                ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                                : null,
                            fontSize:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleSize
                                ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                                : null,
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleFont
                                ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                                : 'Roboto',
                            textAlign:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                                : null,
                            fontWeight:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                  true
                                  ? 900
                                  : 500
                                : 500,
                            cursor: 'pointer',
                            fontStyle:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                  true
                                  ? 'italic'
                                  : 'normal'
                                : 'normal',
                          }}
                        >
                          {section.title}
                        </p>
                      ) : null
                    )
                : null}

              {page
                ? page.sections
                    .sort(
                      (a, b) => parseFloat(a.position) - parseFloat(b.position)
                    )
                    .map((section, i) =>
                      section.applicable ? (
                        <p
                          onClick={() => this.scrollToSection(section.title)}
                          key={i}
                          className="terms__menu-title"
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleColor
                                ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                                : null,
                            fontSize:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleSize
                                ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                                : null,
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleFont
                                ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                                : 'Roboto',
                            textAlign:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                                : null,
                            fontWeight:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                  true
                                  ? 900
                                  : 500
                                : 500,
                            cursor: 'pointer',
                            fontStyle:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                  true
                                  ? 'italic'
                                  : 'normal'
                                : 'normal',
                          }}
                        >
                          {section.title}
                        </p>
                      ) : null
                    )
                : null}
            </div>
            <div className="terms__info">
              {!page && actualLegalsitePage
                ? actualLegalsitePage.sections
                    .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                    .map((section, i) =>
                      section.applicable ? (
                        <Fragment key={i}>
                          <h2
                            className="terms__info-title"
                            ref={section.title}
                            style={{
                              color:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleColor
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                                  : '#333',
                              fontSize:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleSize
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                                  : null,
                              fontFamily:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleFont
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                                  : null,
                              textAlign:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                                  : null,
                              fontWeight:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                    true
                                    ? 900
                                    : 500
                                  : 500,

                              fontStyle:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                    true
                                    ? 'italic'
                                    : 'normal'
                                  : 'normal',
                            }}
                          >
                            {section.title}
                          </h2>
                          <p
                            className="terms__info-text"
                            style={{
                              color:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextColor
                                  ? publicLegalsiteCustomizationSettings.bodyTextColor
                                  : '#969696',
                              fontSize:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextSize
                                  ? publicLegalsiteCustomizationSettings.bodyTextSize
                                  : null,
                              fontFamily:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextFont
                                  ? publicLegalsiteCustomizationSettings.bodyTextFont
                                  : null,
                              // textAlign:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextAlign !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextAlign
                              //     : null,
                              // fontWeight:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextBold !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextBold ===
                              //       true
                              //       ? 900
                              //       : 500
                              //     : 500,
                              // fontStyle:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextItalic !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                              //       true
                              //       ? 'italic'
                              //       : 'normal'
                              //     : 'normal',
                            }}
                          >
                            {ReactHtmlParser(section.text)}
                          </p>
                        </Fragment>
                      ) : null
                    )
                : null}

              {page
                ? page.sections
                    .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                    .map((section, i) =>
                      section.applicable ? (
                        <Fragment key={i}>
                          <h2
                            className="terms__info-title"
                            id={section.title}
                            ref={section.title}
                            style={{
                              color:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleColor
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                                  : '#333',
                              fontSize:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleSize
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                                  : null,
                              fontFamily:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleFont
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                                  : null,
                              textAlign:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                                  : null,
                              fontWeight:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                    true
                                    ? 900
                                    : 500
                                  : 500,

                              fontStyle:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                                  null
                                  ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                    true
                                    ? 'italic'
                                    : 'normal'
                                  : 'normal',
                            }}
                          >
                            {section.title}
                          </h2>
                          <p
                            className="terms__info-text"
                            style={{
                              color:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextColor
                                  ? publicLegalsiteCustomizationSettings.bodyTextColor
                                  : '#969696',
                              fontSize:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextSize
                                  ? publicLegalsiteCustomizationSettings.bodyTextSize
                                  : null,
                              fontFamily:
                                publicLegalsiteCustomizationSettings &&
                                publicLegalsiteCustomizationSettings.bodyTextFont
                                  ? publicLegalsiteCustomizationSettings.bodyTextFont
                                  : null,
                              // textAlign:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextAlign !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextAlign
                              //     : null,
                              // fontWeight:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextBold !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextBold ===
                              //       true
                              //       ? 900
                              //       : 500
                              //     : 500,
                              // fontStyle:
                              //   publicLegalsiteCustomizationSettings &&
                              //   publicLegalsiteCustomizationSettings.bodyTextItalic !==
                              //     null
                              //     ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                              //       true
                              //       ? 'italic'
                              //       : 'normal'
                              //     : 'normal',
                            }}
                          >
                            {ReactHtmlParser(section.text)}
                          </p>
                        </Fragment>
                      ) : null
                    )
                : null}
            </div>
          </div>
        </div>

        {!hasPopup && !this.state.close && publicLegalsiteCustomizationPopup ? (
          <Popup
            publicLegalsiteCustomizationPopup={
              publicLegalsiteCustomizationPopup
            }
            getEmail={(email) => this.setState({ email })}
            publicLegalsiteCustomizationSettings={
              publicLegalsiteCustomizationSettings
            }
            actualLegalsitePage={actualLegalsitePage}
            closePopup={this.closePopup}
            handleCookieAccept={this.handleCookieAccept}
            publicConsents={publicConsents}
            email={this.state.email}
            queryString={this.formatPageName(
              page
                ? page.title
                : actualLegalsitePage
                ? actualLegalsitePage.title
                : ''
            )}
          />
        ) : null}

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />

        <ConfirmationModal
          isConfirmationModalVisible={this.state.isConfirmationModalVisible}
          showConfirmationModal={this.showConfirmationModal}
          subdomain={this.props.subdomain}
        />
      </div>
    );
  }
}

export default compose(
  graphql(ACTUAL_PAGE_QUERY, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
        category: 'All pages in one pop-up',
        publicationId: '1',
      },
      fetchPolicy: 'no-cache',
    }),
    name: 'page',
  }),
  graphql(ACCEPT_AGREEMENT, {
    name: 'acceptAgreement',
  }),
  graphql(ADD_DATA_SUBJECT_TO_LEGALSITE, {
    options: (props) => ({
      variables: {
        subdomain: props.subdomain,
      },
    }),
    name: 'addDataSubjectToLegalsite',
  })
)(Page);
