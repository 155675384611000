import gql from 'graphql-tag';

export const DATA_ACCESS_QUERY = gql`
  query($subdomain: String!) {
    publicLegalsite(subdomain: $subdomain) {
      customizationLegalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnBg
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        cardsBg
        cardsBodyAlign
        cardsBodyBold
        cardsBodyColor
        cardsBodyFont
        cardsBodyItalic
        cardsBodySize
        cardsBtnAlign
        cardsBtnBg
        cardsBtnBold
        cardsBtnColor
        cardsBtnFont
        cardsBtnItalic
        cardsBtnSize
        cardsIconsColor
        cardsTitleAlign
        cardsTitleBold
        cardsTitleColor
        cardsTitleFont
        cardsTitleItalic
        cardsTitleSize
        footerLinksFont
        footerTextFont
        headerBg
        homepageTitle
        homepageDescription
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        navigationLinksFont
      }
    }
  }
`;
