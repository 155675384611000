import React from 'react';
import PropTypes from 'prop-types';
import FileCheck from '../../components/Icons/FileCheck';
import { Link } from 'react-router-dom';

CustomLegalsitePage.propTypes = {
  item: PropTypes.object,
  publicLegalsiteCustomizationSettings: PropTypes.object,
  publicAccountInfo: PropTypes.object,
  isDPO: PropTypes.bool,
};

function CustomLegalsitePage(props) {
  const {
    item,
    publicLegalsiteCustomizationSettings,
    publicAccountInfo,
    isDPO,
    dpo,
  } = props;

  return (
    <div
      key={isDPO ? null : item.id}
      className="customization-site__item"
      style={{
        background:
          publicLegalsiteCustomizationSettings &&
          publicLegalsiteCustomizationSettings.cardsBg
            ? publicLegalsiteCustomizationSettings.cardsBg
            : '#fafafa',
      }}
    >
      <div className="d-flex align-items-center">
        <FileCheck
          color={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsIconsColor
              ? publicLegalsiteCustomizationSettings.cardsIconsColor
              : '#7450c8'
          }
          className="mr-1"
        />

        <p
          className="customization-site__item-title ml-3"
          style={{
            color:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleColor
                ? publicLegalsiteCustomizationSettings.cardsTitleColor
                : '#333',
            fontSize:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleSize
                ? publicLegalsiteCustomizationSettings.cardsTitleSize
                : null,
            fontFamily:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleFont
                ? publicLegalsiteCustomizationSettings.cardsTitleFont
                : null,
            textAlign:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleAlign !== null
                ? publicLegalsiteCustomizationSettings.cardsTitleAlign
                : null,
            fontWeight:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleBold !== null
                ? publicLegalsiteCustomizationSettings.cardsTitleBold === true
                  ? 900
                  : 500
                : 500,
            fontStyle:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.cardsTitleItalic !== null
                ? publicLegalsiteCustomizationSettings.cardsTitleItalic === true
                  ? 'italic'
                  : 'normal'
                : 'normal',
          }}
        >
          {isDPO ? 'Legal Information' : item.title}
        </p>
      </div>
      <p
        className="customization-site__item-text"
        style={{
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyColor
              ? publicLegalsiteCustomizationSettings.cardsBodyColor
              : '#969696',
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodySize
              ? publicLegalsiteCustomizationSettings.cardsBodySize
              : null,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : null,
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyAlign !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyBold !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
              ? publicLegalsiteCustomizationSettings.cardsBodyItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        {isDPO ? 'Here you can find our Legal Information' : item.description}
      </p>

      <Link
        to={
          isDPO
            ? { pathname: '/legal' }
            : {
                pathname: `/page?page=${props.formatPageName(item.title)}`,
                state: { page: item, publicAccountInfo, uid: props.uid },
              }
        }
        className="customization-site__item-btn"
        style={{
          fontSize:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnSize
              ? publicLegalsiteCustomizationSettings.cardsBtnSize
              : null,
          fontFamily:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto',
          color:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnColor
              ? publicLegalsiteCustomizationSettings.cardsBtnColor
              : '#fff',
          backgroundColor:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnBg
              ? publicLegalsiteCustomizationSettings.cardsBtnBg
              : '#7450C8',
          textAlign:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnAlign
              : null,
          fontWeight:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnBold !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                ? 900
                : 500
              : 500,
          fontStyle:
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
              ? publicLegalsiteCustomizationSettings.cardsBtnItalic === true
                ? 'italic'
                : 'normal'
              : 'normal',
        }}
      >
        See more
      </Link>
    </div>
  );
}

export default CustomLegalsitePage;
