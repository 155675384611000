import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FaCaret from 'react-icons/lib/fa/angle-down';
import FaCaretUp from 'react-icons/lib/fa/angle-up';
import Switch from 'react-switch';
import 'react-table-6/react-table.css';
import ReactTable from 'react-table-6';
import './manage-consent.scss';

export default class ManageConsentItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    hasToggle: PropTypes.bool,
    checkConsent: PropTypes.func.isRequired,
  };

  state = {
    isMore: false,
    checked: this.props.checked,
  };

  showMore = () => {
    this.setState({
      isMore: !this.state.isMore,
    });
  };

  handleChange = (checked) => {
    this.setState({ checked });

    this.props.checkConsent(
      this.props.title,
      this.props.id,
      this.props.text,
      this.props.completeDescription,
      this.props.hasToggle,
      checked,
      this.props.dataProcessorConsents
    );
  };

  render() {
    const { isMore } = this.state;
    const {
      title,
      text,
      hasToggle,
      publicLegalsiteCustomizationSettings,
    } = this.props;

    const columns = [
      {
        Header: 'Data Processor:',
        accessor: '',
        Cell: (props) => (
          <span className="d-flex">
            {props.value.dataProcessorLogo ? (
              <img
                className="manage-consent__item-logo"
                src={props.value.dataProcessorLogo}
                alt="logo"
              />
            ) : (
              <span className="manage-consent__item-placeholder" />
            )}{' '}
            {props.value.dataProcessorCompanyName}
          </span>
        ),
        width: '200px',
      },
      {
        Header: 'Category:',
        accessor: 'consentName',
        Cell: (props) => props.value,
        width: '200px',
      },
      {
        Header: 'Purpose:',
        accessor: 'purpose',
        Cell: (props) => <span>{props.value}</span>,
        width: '200px',
      },
    ];

    return (
      <div className="d-flex manage-consent__item">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <span
              className="manage-consent__item-title"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyColor
                    ? publicLegalsiteCustomizationSettings.cardsBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodySize
                    ? publicLegalsiteCustomizationSettings.cardsBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyFont
                    ? publicLegalsiteCustomizationSettings.cardsBodyFont
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              {title}
            </span>
            <span
              className="manage-consent__item-text"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyColor
                    ? publicLegalsiteCustomizationSettings.cardsBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodySize
                    ? publicLegalsiteCustomizationSettings.cardsBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyFont
                    ? publicLegalsiteCustomizationSettings.cardsBodyFont
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              {text}
            </span>
          </div>
          <div>
            {!hasToggle || title === 'Essential' ? (
              <span
                className="manage-consent__item-text"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyColor
                      ? publicLegalsiteCustomizationSettings.cardsBodyColor
                      : null,
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodySize
                      ? publicLegalsiteCustomizationSettings.cardsBodySize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyFont
                      ? publicLegalsiteCustomizationSettings.cardsBodyFont
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.cardsBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                Necessary
                {this.props.completeDescription ? (
                  isMore ? (
                    <FaCaretUp
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  ) : (
                    <FaCaret
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  )
                ) : null}
              </span>
            ) : (
              <span className="manage-consent__item-text manage-consent__item-text d-flex align-items-center">
                <Switch
                  onColor={'#1DDCC0'}
                  offColor={'#B0BAC9'}
                  height={20}
                  width={36}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.handleChange}
                  checked={this.props.checked}
                />

                {this.props.completeDescription ||
                (this.props.dataProcessorConsents &&
                  this.props.dataProcessorConsents.length) ? (
                  isMore ? (
                    <FaCaretUp
                      className="ml-3 manage-consent__more"
                      size="20"
                      onClick={this.showMore}
                    />
                  ) : (
                    <FaCaret
                      className="ml-3 manage-consent__more"
                      size="20"
                      onClick={this.showMore}
                    />
                  )
                ) : (
                  <span className="empty-description" />
                )}
              </span>
            )}
          </div>
        </div>
        {isMore ? (
          <div
            className="manage-consent__item-more"
            style={{
              color:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyColor
                  ? publicLegalsiteCustomizationSettings.cardsBodyColor
                  : null,
              fontSize:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodySize
                  ? publicLegalsiteCustomizationSettings.cardsBodySize
                  : null,
              fontFamily:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyFont
                  ? publicLegalsiteCustomizationSettings.cardsBodyFont
                  : null,
              fontWeight:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyBold === true
                    ? 900
                    : 500
                  : 500,
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBg
                  ? publicLegalsiteCustomizationSettings.cardsBg
                  : null,
              fontStyle:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                  ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                    true
                    ? 'italic'
                    : 'normal'
                  : 'normal',
            }}
          >
            <p
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyColor
                    ? publicLegalsiteCustomizationSettings.cardsBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodySize
                    ? publicLegalsiteCustomizationSettings.cardsBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyFont
                    ? publicLegalsiteCustomizationSettings.cardsBodyFont
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              {this.props.completeDescription}
            </p>

            {this.props.dataProcessorConsents &&
            this.props.dataProcessorConsents.length ? (
              <ReactTable
                data={this.props.dataProcessorConsents}
                columns={columns}
                minRows={0}
                showPagination={false}
                showPageSizeOptions={false}
                defaultPageSize={100}
                showPageJump={false}
                resizable={false}
                noDataText="No Users"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
