import gql from 'graphql-tag';

export const REQUEST_QUERY = gql`
  query($token: String!) {
    requestByToken(verificationToken: $token) {
      id
      code
      category
      receivedDate
      dueDate
      status
      dataSubject {
        id
        name
        email
      }
      dataSubject {
        avatar
        company
        addedAt
        dpaSigned
        email
        id
        interactions {
          createdAt
          id
          requestNumber
          activity
        }
        logged
        name
        phoneNumber
        source
        verified
      }
      dataController {
        avatar
        company
        addedAt
        dpaSigned
        email
        id
        interactions {
          createdAt
          id
          requestNumber
          activity
        }
        name
        phoneNumber
      }
    }
  }
`;
