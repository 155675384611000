import PropTypes from "prop-types";
import React from "react";

const Comments = props => (
  <svg
    height={27}
    viewBox="0 0 27 27"
    width={27}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="3.3--Cookies-preview-1---tooltip"
        transform="translate(-1096.000000, -390.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-2">
          <g id="cards" transform="translate(220.000000, 358.000000)">
            <g id="contact-" transform="translate(780.000000, 0.000000)">
              <g id="comments" transform="translate(96.000000, 32.000000)">
                <path
                  d="M25.1466667,23.2533333 C26.8392066,20.9845874 27.4107293,18.0703362 26.7004634,15.3303687 C25.9901974,12.5904012 24.0748935,10.3207956 21.4933333,9.16 C20.7328628,4.78469103 17.3411296,1.33831032 12.9785334,0.508009864 C8.61593726,-0.322290595 4.19533961,1.63722732 1.88081365,5.42729509 C-0.433712305,9.21736285 -0.15785826,14.044919 2.57333333,17.5466667 L0.72,19.3866667 C0.341673917,19.7700809 0.23120436,20.3434705 0.44,20.84 C0.644538131,21.3379007 1.12839749,21.6639798 1.66666667,21.6666667 L9.25333333,21.6666667 C10.7953355,24.9175127 14.0686513,26.9925148 17.6666667,27 L25.6666667,27 C26.2049358,26.9973131 26.6887952,26.671234 26.8933333,26.1733333 C27.102129,25.6768039 26.9916594,25.1034143 26.6133333,24.72 L25.1466667,23.2533333 Z M8.33333333,17.6666667 C8.33516485,18.1131671 8.37082245,18.5588872 8.44,19 L4.88,19 L5.34666667,18.5466667 C5.59908353,18.2963112 5.74106443,17.9555167 5.74106443,17.6 C5.74106443,17.2444833 5.59908353,16.9036888 5.34666667,16.6533333 C3.83715404,15.160381 2.99148134,13.1230786 3,11 C3,6.581722 6.581722,3 11,3 C14.3945982,2.97960038 17.4247011,5.124806 18.5333333,8.33333333 C18.24,8.33333333 17.96,8.33333333 17.6666667,8.33333333 C12.512009,8.33333333 8.33333333,12.512009 8.33333333,17.6666667 Z M22.3866667,24.3333333 L22.4533333,24.4 L17.6666667,24.4 C14.486505,24.3943203 11.7531183,22.1432068 11.1378612,19.0231236 C10.522604,15.9030403 12.1967968,12.782785 15.1367389,11.5702665 C18.076681,10.357748 21.4637357,11.3905962 23.2268571,14.0372665 C24.9899786,16.6839368 24.6382197,20.2074549 22.3866667,22.4533333 C22.1354476,22.6995073 21.9916761,23.0349743 21.9866667,23.3866667 C21.9881459,23.7429945 22.1321925,24.0839048 22.3866667,24.3333333 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Comments.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default Comments;
