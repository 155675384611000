import gql from 'graphql-tag';

export const COOKIE_POPUP_QUERY = gql`
  query($subdomain: String!, $category: String!) {
    publicLegalsiteCustomizationPopup(
      subdomain: $subdomain
      category: $category
    ) {
      title
    }
  }
`;
