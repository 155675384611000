import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { graphql } from 'react-apollo';
import SyncLoader from 'react-spinners/SyncLoader';
import './dpo.scss';
import { DPO_QUERY } from '../../graphql/dpo';
import uuid from 'react-uuid';
import Footer from '../../components/Footer.js/Footer';
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';
import Fonts from '../LegalSite/Fonts';
import Moment from 'react-moment';
import DpoContent from './DpoContent';
import ImpressumContent from './ImpressumContent';

class DPO extends Component {
  static propTypes = {};

  state = {
    subdomain: '',
    uid: uuid(),
  };

  componentDidMount() {
    let subdomain = this.props.subdomain;

    this.setState(
      {
        subdomain,
      },
      () => {
        this.props.data.refetch({ subdomain: this.state.subdomain });
      }
    );
  }

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  render() {
    const { loading, error } = this.props.data;

    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicLegalInformation =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.legalInformation;

    const publicDataProtectionOfficer =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.dataProtectionOfficer;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    const dpo = publicDataProtectionOfficer;

    if (this.props.subdomain === 'legalsites')
      return <Alert color="danger"> No subdomain found!</Alert>;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={'px'}
          size={10}
          color={'#7450c8'}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );
    return (
      <div className="dpo">
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />

        <LegalsiteNavbar subdomain={this.props.subdomain} />

        <div
          className="dpo__container"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodyBg
                ? publicLegalsiteCustomizationSettings.bodyBg
                : '#f4f6fc',
          }}
        >
          <div
            className="dpo__header"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBg
                  ? publicLegalsiteCustomizationSettings.headerBg
                  : '#dfe4ee',
            }}
          >
            <h1
              className="dpo__header-title"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : '#333',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : 56,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Legal Information
            </h1>
            <p
              className="dpo__header-text"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : '#969696',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : 16,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Here you can find our Legal Information.
            </p>

            <span
              className="dpo__header-text"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : '#969696',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : 16,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Last updated at{' '}
              <Moment format="D MMMM YYYY">{dpo ? dpo.lastUpdated : ''}</Moment>
            </span>
          </div>
          <div
            className="dpo__page-content"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.cardsBg
                  ? publicLegalsiteCustomizationSettings.cardsBg
                  : '#fafafa',
            }}
          >
            {publicLegalInformation ? (
              <ImpressumContent
                dpo={dpo}
                publicLegalsiteCustomizationSettings={
                  publicLegalsiteCustomizationSettings
                }
                publicLegalInformation={publicLegalInformation}
              />
            ) : null}
            {dpo && dpo.show && publicLegalInformation ? (
              <div className="dpo__divider" />
            ) : null}

            {dpo && dpo.show ? (
              <DpoContent
                publicLegalsiteCustomizationSettings={
                  publicLegalsiteCustomizationSettings
                }
                dpo={dpo}
                publicAccountInfo={publicAccountInfo}
              />
            ) : null}
          </div>
        </div>

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />
      </div>
    );
  }
}

export default graphql(DPO_QUERY)(DPO);
