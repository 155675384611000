import React from 'react';
import img from '../../images/Request Access/email.svg';

function ThankYou(props) {
  return (
    <div className="thanks container">
      <img className="thanks__img" src={img} alt="email" />
      <p className="thanks__title">Thank you!</p>
      <p className="thanks__text">You have successfully solved the request! </p>
    </div>
  );
}

export default ThankYou;
