import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import './modals.scss';
import SignaturePad from 'react-signature-canvas';
import ReactFilestack from 'filestack-react';
import * as filestack from 'filestack-js';
import TrashIcon from '../Icons/TrashIcon';
import Webcam from 'react-webcam';

const client = filestack.init('AJNM9qOpGRljTn17sgxrfz');

class AddSignature extends Component {
  static propTypes = {
    showSignatureModal: PropTypes.func.isRequired,
    isShowSignatureModalVisible: PropTypes.bool.isRequired,
  };

  state = {
    btnActive: 'draw',
    showBtns: false,
    trimmedDataURL: null,
    files: [],
    screenshot: null,
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  clear = () => {
    this.sigPad.clear();

    this.setState({
      showBtns: false,
    });
  };

  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
    });

    const file = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    const name = 'signature';

    const token = {};
    const onRetry = obj => {
      console.log(
        `Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`
      );
    };

    client
      .upload({ file, name }, { onRetry }, { filename: 'signature.jpg' }, token)
      .then(res => this.saveSignature(res.url));
  };

  saveSignature = img => {
    this.props.getSignature(img);
    this.props.showSignatureModal();
  };

  capture = () => {
    const screenshot = this.refs.webcam.getScreenshot();
    this.setState({ screenshot: screenshot });

    const file = screenshot;
    const name = 'signature';

    const token = {};
    const onRetry = obj => {
      console.log(
        `Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`
      );
    };

    client
      .upload({ file, name }, { onRetry }, { filename: 'signature.jpg' }, token)
      .then(res => this.saveSignature(res.url));
  };

  render() {
    const { btnActive, showBtns, files } = this.state;
    const { publicLegalsiteCustomizationSettings } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isShowSignatureModalVisible}
          toggle={this.props.showSignatureModal}
          className="modal-signature"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showSignatureModal}
            />
            <p
              className="modal-assign__title mt-5 mb-4"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : '#333',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Add Signature
            </p>

            <div className="d-flex modal-signature__btns">
              <Button
                className={`bg-transparent modal-signature__btn ${
                  btnActive !== 'draw' ? '-is-disabled' : ''
                }`}
                onClick={() =>
                  this.setState({
                    btnActive: 'draw',
                    showBtns: false,
                  })
                }
                style={{
                  height: 40,
                  width: 136,
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  borderColor:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnSize
                      ? publicLegalsiteCustomizationSettings.bodyBtnSize
                      : 14,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnFont
                      ? publicLegalsiteCustomizationSettings.bodyBtnFont
                      : 'Roboto',
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnAlign
                      ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBold
                      ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                        true
                        ? 900
                        : 300
                      : 600,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnItalic
                      ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                Draw
              </Button>
              <Button
                className={`modal-signature__btn bg-transparent ${
                  btnActive !== 'upload' ? '-is-disabled' : ''
                }`}
                style={{
                  height: 40,
                  width: 136,
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  borderColor:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnSize
                      ? publicLegalsiteCustomizationSettings.bodyBtnSize
                      : 14,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnFont
                      ? publicLegalsiteCustomizationSettings.bodyBtnFont
                      : 'Roboto',
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnAlign
                      ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBold
                      ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                        true
                        ? 900
                        : 300
                      : 600,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnItalic
                      ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
                onClick={() =>
                  this.setState({
                    btnActive: 'upload',
                    showBtns: false,
                  })
                }
              >
                Upload
              </Button>
              <Button
                className={`bg-transparent modal-signature__btn ${
                  btnActive !== 'camera' ? '-is-disabled' : ''
                }`}
                style={{
                  height: 40,
                  width: 136,
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  borderColor:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBg
                      ? publicLegalsiteCustomizationSettings.bodyBtnBg
                      : '#7450C8',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnSize
                      ? publicLegalsiteCustomizationSettings.bodyBtnSize
                      : 14,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnFont
                      ? publicLegalsiteCustomizationSettings.bodyBtnFont
                      : 'Roboto',
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnAlign
                      ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnBold
                      ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                        true
                        ? 900
                        : 300
                      : 600,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBtnItalic
                      ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
                onClick={() =>
                  this.setState({
                    btnActive: 'camera',
                    showBtns: false,
                  })
                }
              >
                Camera
              </Button>
            </div>

            <p
              className="text-center modal-signature__title"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : '#969696',
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : 16,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              {btnActive === 'draw'
                ? 'Sign below using your trackpad or mouse.'
                : btnActive === 'camera'
                ? 'Sign your name on white paper and hold it in front of the camera.'
                : 'Upload an image from your library.'}
            </p>
            {btnActive === 'draw' ? (
              <div onClick={() => this.setState({ showBtns: true })}>
                <SignaturePad
                  canvasProps={{
                    width: 500,
                    height: 121,
                    className: 'sigCanvas border-bottom',
                  }}
                  ref={ref => {
                    this.sigPad = ref;
                  }}
                />
              </div>
            ) : btnActive === 'upload' ? (
              <Fragment>
                {files.length ? (
                  <div className="modal-signature__file mb-4">
                    <div className="modal-signature__file-type">JPG</div>
                    <p className="modal-signature__file-name">
                      {files[0].filename}
                    </p>
                  </div>
                ) : (
                  <ReactFilestack
                    apikey={'AJNM9qOpGRljTn17sgxrfz'}
                    componentDisplayMode={{
                      type: 'link',
                      customText: (
                        <Button
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnColor
                                ? publicLegalsiteCustomizationSettings.bodyBtnColor
                                : '#fff',
                            fontSize:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnSize
                                ? publicLegalsiteCustomizationSettings.bodyBtnSize
                                : 14,
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnFont
                                ? publicLegalsiteCustomizationSettings.bodyBtnFont
                                : 'Roboto',
                            textAlign:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnAlign
                                ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                                : null,
                            backgroundColor:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnBg
                                ? publicLegalsiteCustomizationSettings.bodyBtnBg
                                : '#7450C8',
                            fontWeight:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnBold
                                ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                                  true
                                  ? 900
                                  : 300
                                : 600,
                            fontStyle:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyBtnItalic
                                ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                                  true
                                  ? 'italic'
                                  : 'normal'
                                : 'normal',
                          }}
                          className="border-0 mt-5 mx-auto d-flex align-items-center justify-content-center"
                        >
                          Upload Signature
                        </Button>
                      ),
                      customClass: 'show-upload',
                    }}
                    onSuccess={this.onFiles}
                    actionOptions={{
                      accept: ['image/*'],
                      maxFiles: 1,
                    }}
                  />
                )}

                {files.length ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      onClick={() =>
                        this.saveSignature(this.state.files[0].url)
                      }
                      className="border-0"
                      style={{
                        height: 40,
                        width: 136,
                        color:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnColor
                            ? publicLegalsiteCustomizationSettings.bodyBtnColor
                            : '#fff',
                        fontSize:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnSize
                            ? publicLegalsiteCustomizationSettings.bodyBtnSize
                            : 14,
                        fontFamily:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnFont
                            ? publicLegalsiteCustomizationSettings.bodyBtnFont
                            : 'Roboto',
                        textAlign:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnAlign
                            ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                            : null,
                        backgroundColor:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnBg
                            ? publicLegalsiteCustomizationSettings.bodyBtnBg
                            : '#7450C8',
                        fontWeight:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnBold
                            ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                              true
                              ? 900
                              : 300
                            : 600,
                        fontStyle:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnItalic
                            ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                              true
                              ? 'italic'
                              : 'normal'
                            : 'normal',
                      }}
                    >
                      Save
                    </Button>
                    <div
                      className="modal-signature__clear mb-0 ml-2"
                      onClick={() => this.setState({ files: [] })}
                      style={{
                        color:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnColor
                            ? publicLegalsiteCustomizationSettings.bodyBtnColor
                            : '#7450C8',
                        fontSize:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnSize
                            ? publicLegalsiteCustomizationSettings.bodyBtnSize
                            : 14,
                        fontFamily:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnFont
                            ? publicLegalsiteCustomizationSettings.bodyBtnFont
                            : 'Roboto',
                        fontWeight:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnBold
                            ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                              true
                              ? 900
                              : 300
                            : 600,
                        fontStyle:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnItalic
                            ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                              true
                              ? 'italic'
                              : 'normal'
                            : 'normal',
                      }}
                    >
                      <TrashIcon
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.bodyBtnColor
                            ? publicLegalsiteCustomizationSettings.bodyBtnColor
                            : '#7450C8'
                        }
                      />
                      &nbsp; Clear
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ) : (
              <Fragment>
                <Webcam
                  audio={false}
                  height={220}
                  ref="webcam"
                  className="mx-auto d-flex"
                  screenshotFormat="image/jpeg"
                  width={500}
                  videoConstraints={{
                    width: 500,
                    height: 220,
                    facingMode: 'user',
                  }}
                />

                <Button
                  className="modal-signature__btn mr-4 mb-5 mt-4 mx-auto d-flex align-items-center justify-content-center border-0"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnColor
                        ? publicLegalsiteCustomizationSettings.bodyBtnColor
                        : '#fff',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnSize
                        ? publicLegalsiteCustomizationSettings.bodyBtnSize
                        : 14,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnFont
                        ? publicLegalsiteCustomizationSettings.bodyBtnFont
                        : 'Roboto',
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnAlign
                        ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                        : null,
                    backgroundColor:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBg
                        ? publicLegalsiteCustomizationSettings.bodyBtnBg
                        : '#7450C8',
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBold
                        ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                          true
                          ? 900
                          : 300
                        : 600,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnItalic
                        ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                  onClick={this.capture}
                >
                  Take photo
                </Button>
              </Fragment>
            )}

            {showBtns ? (
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  className="border-0 modal-signature__btn mr-4 mb-5"
                  onClick={this.trim}
                  style={{
                    height: 40,
                    width: 136,
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnColor
                        ? publicLegalsiteCustomizationSettings.bodyBtnColor
                        : '#fff',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnSize
                        ? publicLegalsiteCustomizationSettings.bodyBtnSize
                        : 14,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnFont
                        ? publicLegalsiteCustomizationSettings.bodyBtnFont
                        : 'Roboto',
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnAlign
                        ? publicLegalsiteCustomizationSettings.bodyBtnAlign
                        : null,
                    backgroundColor:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBg
                        ? publicLegalsiteCustomizationSettings.bodyBtnBg
                        : '#7450C8',
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBold
                        ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                          true
                          ? 900
                          : 300
                        : 600,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnItalic
                        ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                >
                  Save
                </Button>
                <div
                  className="modal-signature__clear mb-5"
                  onClick={this.clear}
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnColor
                        ? publicLegalsiteCustomizationSettings.bodyBtnColor
                        : '#7450C8',
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnSize
                        ? publicLegalsiteCustomizationSettings.bodyBtnSize
                        : 14,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnFont
                        ? publicLegalsiteCustomizationSettings.bodyBtnFont
                        : 'Roboto',
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnBold
                        ? publicLegalsiteCustomizationSettings.bodyBtnBold ===
                          true
                          ? 900
                          : 300
                        : 600,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnItalic
                        ? publicLegalsiteCustomizationSettings.bodyBtnItalic ===
                          true
                          ? 'italic'
                          : 'normal'
                        : 'normal',
                  }}
                >
                  <TrashIcon
                    color={
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.bodyBtnColor
                        ? publicLegalsiteCustomizationSettings.bodyBtnColor
                        : '#7450C8'
                    }
                  />
                  &nbsp; Clear
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AddSignature;
