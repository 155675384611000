import PropTypes from "prop-types";
import React from "react";

const ShieldCheck = props => (
  <svg
    height={32}
    viewBox="0 0 25 32"
    width={25}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="3.3--Cookies-preview-1---tooltip"
        transform="translate(-708.000000, -390.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-2">
          <g id="cards" transform="translate(220.000000, 358.000000)">
            <g id="terms" transform="translate(391.000000, 0.000000)">
              <g id="shield-check" transform="translate(97.000000, 32.000000)">
                <path
                  d="M24.421875,2.578125 C24.0542079,2.28070652 23.5716227,2.16580529 23.109375,2.265625 C19.7347586,2.97285786 16.2178827,2.25478191 13.390625,0.28125 C12.8550084,-0.0903235582 12.1449916,-0.0903235582 11.609375,0.28125 C8.78211733,2.25478191 5.2652414,2.97285786 1.890625,2.265625 C1.42837734,2.16580529 0.945792142,2.28070652 0.578125,2.578125 C0.210950835,2.87598446 -0.00160759438,3.32408061 -1.9493088e-13,3.796875 L-1.9493088e-13,15.4375 C-0.00281847205,19.9828525 2.19150569,24.2492705 5.890625,26.890625 L11.59375,30.953125 C12.1361942,31.3393407 12.8638058,31.3393407 13.40625,30.953125 L19.109375,26.890625 C22.8084943,24.2492705 25.0028185,19.9828525 25,15.4375 L25,3.796875 C25.0016076,3.32408061 24.7890492,2.87598446 24.421875,2.578125 Z M21.875,15.4375 C21.8774317,18.9715308 20.1721547,22.2889639 17.296875,24.34375 L12.5,27.765625 L7.703125,24.34375 C4.82784527,22.2889639 3.12256826,18.9715308 3.125,15.4375 L3.125,5.59375 C6.40066935,5.87411412 9.68131339,5.11409825 12.5,3.421875 C15.3186866,5.11409825 18.5993306,5.87411412 21.875,5.59375 L21.875,15.4375 Z M14.90625,11.859375 L10.703125,16.078125 L9.3125,14.671875 C8.6998091,14.0591841 7.7064409,14.0591841 7.09375002,14.671875 C6.48105913,15.2845659 6.48105912,16.2779341 7.09375,16.890625 L9.59375,19.390625 C9.88713531,19.686426 10.2865038,19.8528099 10.703125,19.8528099 C11.1197462,19.8528099 11.5191147,19.686426 11.8125,19.390625 L17.1875,14.0625 C17.8001909,13.4498091 17.8001909,12.4564409 17.1875,11.84375 C16.5748091,11.2310591 15.5814409,11.2310591 14.96875,11.84375 L14.90625,11.859375 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ShieldCheck.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default ShieldCheck;