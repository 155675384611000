import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import Email from '../../images/Request Access/Document.svg';
import MdArrow from 'react-icons/lib/ti/arrow-right';
import { CONFIRMATION_MODAL_QUERY } from '../../graphql/confirmationModal';
import { graphql } from 'react-apollo';
import './modals.scss';

class ConfirmationModal extends Component {
  static propTypes = {
    isConfirmationModalVisible: PropTypes.bool.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
  };

  state = {};

  redirectToDashboard = () => {
    window.location.href = '/';
  };

  render() {
    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    return (
      <div>
        <Modal
          isOpen={this.props.isConfirmationModalVisible}
          toggle={this.props.showConfirmationModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={() => {
                this.props.showConfirmationModal();
              }}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p
              className="modal-assign__title mt-3"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Request submitted
            </p>
            <p
              className="modal-assign__message mb-4"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              We’ll get back to you soon with a signed Data Processing
              Agreement.
            </p>
            <div
              onClick={() => {
                this.redirectToDashboard();
                this.props.showConfirmationModal();
              }}
              className="modal-assign__link mb-3"
              style={{
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnSize
                    ? publicLegalsiteCustomizationSettings.cardsBtnSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnFont
                    ? publicLegalsiteCustomizationSettings.cardsBtnFont
                    : null,
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBg
                    ? publicLegalsiteCustomizationSettings.cardsBtnBg
                    : '#7450c8',
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Home page &nbsp;
              <MdArrow size={20} />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(CONFIRMATION_MODAL_QUERY, {
  options: props => ({
    variables: {
      subdomain: props.subdomain,
    },
    fetchPolicy: 'no-cache',
  }),
})(ConfirmationModal);
