import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import Email from '../../images/Request Access/Document.svg';
import MdArrow from 'react-icons/lib/ti/arrow-right';
import { Link } from 'react-router-dom';
import './modals.scss';

export default class ReceivedRequest extends Component {
  static propTypes = {
    isReceivedModalVisible: PropTypes.bool.isRequired,
    showReceivedModal: PropTypes.func.isRequired,
  };

  state = {};

  redirectToHomepage = () => {
    window.location.href = '/';
  };

  render() {
    const { publicLegalsiteCustomizationSettings } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isReceivedModalVisible}
          toggle={this.props.showReceivedModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={() => {
                this.props.showReceivedModal();
                this.redirectToHomepage();
              }}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p
              className="modal-assign__title mt-3"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleColor
                    ? publicLegalsiteCustomizationSettings.headerTitleColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleSize
                    ? publicLegalsiteCustomizationSettings.headerTitleSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleFont
                    ? publicLegalsiteCustomizationSettings.headerTitleFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleBold !== null
                    ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerTitleItalic !==
                    null
                    ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Message submitted
            </p>
            <p
              className="modal-assign__message mb-3"
              style={{
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyColor
                    ? publicLegalsiteCustomizationSettings.headerBodyColor
                    : null,
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodySize
                    ? publicLegalsiteCustomizationSettings.headerBodySize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyFont
                    ? publicLegalsiteCustomizationSettings.headerBodyFont
                    : null,
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyAlign !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyBold !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                      true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.headerBodyItalic !== null
                    ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Your message was received and we'll get back to you shortly
            </p>
            <Link
              to="/"
              className="modal-assign__link mb-3"
              style={{
                fontSize:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnSize
                    ? publicLegalsiteCustomizationSettings.cardsBtnSize
                    : null,
                fontFamily:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnFont
                    ? publicLegalsiteCustomizationSettings.cardsBtnFont
                    : null,
                color:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBg
                    ? publicLegalsiteCustomizationSettings.cardsBtnBg
                    : '#7450c8',
                textAlign:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnAlign !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnAlign
                    : null,
                fontWeight:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnBold !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnBold === true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.cardsBtnItalic !== null
                    ? publicLegalsiteCustomizationSettings.cardsBtnItalic ===
                      true
                      ? 'italic'
                      : 'normal'
                    : 'normal',
              }}
            >
              Home page &nbsp;
              <MdArrow size={20} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
