import gql from 'graphql-tag';

export const HANDLE_REQUEST = gql`
  mutation(
    $verificationToken: String!
    $actions: RequestActionEnum!
    $certifiedBy: String
    $clientMutationId: String
  ) {
    handleRequestWithToken(
      input: {
        verificationToken: $verificationToken
        actions: $actions
        certifiedBy: $certifiedBy
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
