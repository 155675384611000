import gql from 'graphql-tag';

export const REQUEST_SIGNED_DPA = gql`
  mutation($accountId: ID!, $email: String!, $clientMutationId: String) {
    requestSignedDpaOnCookie(
      input: {
        accountId: $accountId
        email: $email
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
