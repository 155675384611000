import gql from 'graphql-tag';

export const LEGALSITE_NAVBAR_QUERY = gql`
  query($subdomain: String!) {
    publicLegalsite(subdomain: $subdomain) {
      companyLogo
      id
      access
      companyName
      favicon
      customizationLegalsite {
        navigationBg
        navigationLinksAlign
        navigationLinksBold
        navigationLinksColor
        navigationLinksFont
        navigationLinksItalic
        navigationLinksSize
        showLegalsiteBranding
      }
    }
  }
`;
