import React, { Component, Fragment } from 'react';
import MdArrow from 'react-icons/lib/ti/arrow-right';
import CheckEmail from '../Modals/CheckEmail';
import ComplaintForm from './ComplaintForm';
import RequestForm from './RequestForm';
import { graphql } from 'react-apollo';
import uuid from 'react-uuid';
import { REQUEST_ACCESS_QUERY } from '../../graphql/requestAccess';
import './request-access.scss';
import Footer from '../Footer.js/Footer';
import ReceivedRequest from '../Modals/ReceivedRequest';
import LegalsiteNavbar from '../LegalsiteNavbar/LegalsiteNavbar';
import Fonts from '../../containers/LegalSite/Fonts';

class RequestAccess extends Component {
  static propTypes = {};

  state = {
    isComplaint: false,
    isCheckEmailModalVisible: false,
    isForm: false,
    type: null,
    accountId: '0',
    uid: uuid(),
    publicAccountInfo: null,
    isDefault: true,
    isReceivedModalVisible: false,
  };

  componentWillMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isSell
    ) {
      this.setState({
        isForm: true,
        type: 'sell',
      });
    }
  }

  showReceivedModal = () => {
    this.setState({
      isReceivedModalVisible: !this.state.isReceivedModalVisible,
    });
  };

  showComplaint = (type) => {
    this.setState({
      isComplaint: !this.state.isComplaint,
      type,
    });
  };

  showCheckEmailModal = () => {
    this.setState({
      isCheckEmailModalVisible: !this.state.isCheckEmailModalVisible,
    });
  };

  showRequestForm = (type) => {
    this.setState({
      isForm: !this.state.isForm,
      type,
    });
  };

  redirectToLandingPage = () => {
    window.open('http://legalsite.co/', '_blank');
  };

  render() {
    const { isComplaint, isForm, isDefault, type } = this.state;

    const publicAccountInfo =
      this.props.data && this.props.data.publicLegalsite;

    const publicDataProtectionOfficer =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.dataProtectionOfficer;

    const publicLegalInformation =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.legalInformation;

    const publicLegalsiteCustomizationSettings =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationLegalsite;

    const accountId = publicAccountInfo ? publicAccountInfo.id : null;
    const mail =
      publicDataProtectionOfficer &&
      publicDataProtectionOfficer.email &&
      publicDataProtectionOfficer.show
        ? publicDataProtectionOfficer.email
        : publicLegalInformation && publicLegalInformation.email
        ? publicLegalInformation.email
        : null;

    const customizationDontSellMyData =
      this.props.data &&
      this.props.data.publicLegalsite &&
      this.props.data.publicLegalsite.customizationDontSellMyData;

    return (
      <div className="request-access">
        <Fonts
          font1={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.navigationLinksFont
              ? publicLegalsiteCustomizationSettings.navigationLinksFont
              : 'Roboto'
          }
          font2={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerTitleFont
              ? publicLegalsiteCustomizationSettings.headerTitleFont
              : 'Roboto'
          }
          font3={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.headerBodyFont
              ? publicLegalsiteCustomizationSettings.headerBodyFont
              : 'Roboto'
          }
          font4={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsTitleFont
              ? publicLegalsiteCustomizationSettings.cardsTitleFont
              : 'Roboto'
          }
          font5={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBodyFont
              ? publicLegalsiteCustomizationSettings.cardsBodyFont
              : 'Roboto'
          }
          font6={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.cardsBtnFont
              ? publicLegalsiteCustomizationSettings.cardsBtnFont
              : 'Roboto'
          }
          font7={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTitleFont
              ? publicLegalsiteCustomizationSettings.bodyTitleFont
              : 'Roboto'
          }
          font8={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodySubtitleFont
              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
              : 'Roboto'
          }
          font9={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyTextFont
              ? publicLegalsiteCustomizationSettings.bodyTextFont
              : 'Roboto'
          }
          font10={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.bodyBtnFont
              ? publicLegalsiteCustomizationSettings.bodyBtnFont
              : 'Roboto'
          }
          font11={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerLinksFont
              ? publicLegalsiteCustomizationSettings.footerLinksFont
              : 'Roboto'
          }
          font12={
            publicLegalsiteCustomizationSettings &&
            publicLegalsiteCustomizationSettings.footerTextFont
              ? publicLegalsiteCustomizationSettings.footerTextFont
              : 'Roboto'
          }
        />
        <LegalsiteNavbar subdomain={this.props.subdomain} />

        <div
          className="request-access__container"
          style={{
            background:
              publicLegalsiteCustomizationSettings &&
              publicLegalsiteCustomizationSettings.bodyBg
                ? publicLegalsiteCustomizationSettings.bodyBg
                : '#f4f6fc',
          }}
        >
          <div
            className="request-access__header"
            style={{
              background:
                publicLegalsiteCustomizationSettings &&
                publicLegalsiteCustomizationSettings.headerBg
                  ? publicLegalsiteCustomizationSettings.headerBg
                  : '#dfe4ee',
            }}
          >
            <div className="container">
              <h1
                className="request-access__header-title"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleColor
                      ? publicLegalsiteCustomizationSettings.headerTitleColor
                      : '#333',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleSize
                      ? publicLegalsiteCustomizationSettings.headerTitleSize
                      : 56,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleFont
                      ? publicLegalsiteCustomizationSettings.headerTitleFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerTitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {type === 'data access' || type === 'data transfer'
                  ? 'Request access'
                  : type === 'data erasure'
                  ? 'Data Erasure Request'
                  : type === 'sell'
                  ? 'Request access'
                  : 'Requests'}
              </h1>
              <p
                className="request-access__header-text"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyColor
                      ? publicLegalsiteCustomizationSettings.headerBodyColor
                      : '#292e31',
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodySize
                      ? publicLegalsiteCustomizationSettings.headerBodySize
                      : 16,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyFont
                      ? publicLegalsiteCustomizationSettings.headerBodyFont
                      : null,
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyBold !== null
                      ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBodyItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                        true
                        ? 'italic'
                        : 'normal'
                      : 'normal',
                }}
              >
                {type
                  ? 'Review the data we have collected.'
                  : 'Send data requests, questions or complaints'}
              </p>
            </div>
          </div>

          {isDefault ? (
            <div className="request-access__help container">
              {!isComplaint && !isForm ? (
                <Fragment>
                  <h4
                    className="request-access__help-title"
                    style={{
                      color:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleColor
                          ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                          : '#333',
                      fontSize:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleSize
                          ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                          : null,
                      fontFamily:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleFont
                          ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                          : 'Roboto',
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                          : null,
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                            true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                          null
                          ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                            true
                            ? 'italic'
                            : 'normal'
                          : 'normal',
                    }}
                  >
                    How can we help you?
                  </h4>
                  <p
                    className="request-access__help-text"
                    style={{
                      color:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodyColor
                          ? publicLegalsiteCustomizationSettings.cardsBodyColor
                          : '#292e31',
                      fontSize:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodySize
                          ? publicLegalsiteCustomizationSettings.cardsBodySize
                          : null,
                      fontFamily:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodyFont
                          ? publicLegalsiteCustomizationSettings.cardsBodyFont
                          : null,
                      textAlign:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodyAlign !==
                          null
                          ? publicLegalsiteCustomizationSettings.cardsBodyAlign
                          : null,
                      fontWeight:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodyBold !==
                          null
                          ? publicLegalsiteCustomizationSettings.cardsBodyBold ===
                            true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        publicLegalsiteCustomizationSettings &&
                        publicLegalsiteCustomizationSettings.cardsBodyItalic !==
                          null
                          ? publicLegalsiteCustomizationSettings.cardsBodyItalic ===
                            true
                            ? 'italic'
                            : 'normal'
                          : 'normal',
                    }}
                  >
                    Select a category.
                  </p>

                  <div className="d-flex request-access__item-container">
                    <div
                      className="request-access__item"
                      style={{
                        background:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsBg
                            ? publicLegalsiteCustomizationSettings.cardsBg
                            : '#fafafa',
                      }}
                      onClick={() => this.showRequestForm('data access')}
                    >
                      <span
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleColor
                              ? publicLegalsiteCustomizationSettings.cardsTitleColor
                              : '#333',
                          fontSize:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleSize
                              ? publicLegalsiteCustomizationSettings.cardsTitleSize
                              : null,
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleFont
                              ? publicLegalsiteCustomizationSettings.cardsTitleFont
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                true
                                ? 'italic'
                                : 'normal'
                              : 'normal',
                        }}
                      >
                        Request data access
                      </span>
                      <MdArrow
                        size={25}
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsIconsColor
                            ? publicLegalsiteCustomizationSettings.cardsIconsColor
                            : '#7450c8'
                        }
                      />
                    </div>

                    <div
                      className="request-access__item"
                      style={{
                        background:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsBg
                            ? publicLegalsiteCustomizationSettings.cardsBg
                            : '#fafafa',
                      }}
                      onClick={() => this.showComplaint('question')}
                    >
                      <span
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleColor
                              ? publicLegalsiteCustomizationSettings.cardsTitleColor
                              : '#333',
                          fontSize:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleSize
                              ? publicLegalsiteCustomizationSettings.cardsTitleSize
                              : null,
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleFont
                              ? publicLegalsiteCustomizationSettings.cardsTitleFont
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                true
                                ? 'italic'
                                : 'normal'
                              : 'normal',
                        }}
                      >
                        Question
                      </span>
                      <MdArrow
                        size={25}
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsIconsColor
                            ? publicLegalsiteCustomizationSettings.cardsIconsColor
                            : '#7450c8'
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex request-access__item-container">
                    <div
                      className="request-access__item"
                      style={{
                        background:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsBg
                            ? publicLegalsiteCustomizationSettings.cardsBg
                            : '#fafafa',
                      }}
                      onClick={() => this.showRequestForm('data erasure')}
                    >
                      <span
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleColor
                              ? publicLegalsiteCustomizationSettings.cardsTitleColor
                              : '#333',
                          fontSize:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleSize
                              ? publicLegalsiteCustomizationSettings.cardsTitleSize
                              : null,
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleFont
                              ? publicLegalsiteCustomizationSettings.cardsTitleFont
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                true
                                ? 'italic'
                                : 'normal'
                              : 'normal',
                        }}
                      >
                        Request data erasure
                      </span>
                      <MdArrow
                        size={25}
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsIconsColor
                            ? publicLegalsiteCustomizationSettings.cardsIconsColor
                            : '#7450c8'
                        }
                      />
                    </div>

                    <div
                      className="request-access__item"
                      style={{
                        background:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsBg
                            ? publicLegalsiteCustomizationSettings.cardsBg
                            : '#fafafa',
                      }}
                      onClick={() => this.showComplaint('complaint')}
                    >
                      <span
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleColor
                              ? publicLegalsiteCustomizationSettings.cardsTitleColor
                              : '#333',
                          fontSize:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleSize
                              ? publicLegalsiteCustomizationSettings.cardsTitleSize
                              : null,
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleFont
                              ? publicLegalsiteCustomizationSettings.cardsTitleFont
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                true
                                ? 'italic'
                                : 'normal'
                              : 'normal',
                        }}
                      >
                        Complaint
                      </span>
                      <MdArrow
                        size={25}
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsIconsColor
                            ? publicLegalsiteCustomizationSettings.cardsIconsColor
                            : '#7450c8'
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex request-access__item-container">
                    <div
                      className="request-access__item"
                      style={{
                        background:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsBg
                            ? publicLegalsiteCustomizationSettings.cardsBg
                            : '#fafafa',
                      }}
                      onClick={() => this.showRequestForm('data transfer')}
                    >
                      <span
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleColor
                              ? publicLegalsiteCustomizationSettings.cardsTitleColor
                              : '#333',
                          fontSize:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleSize
                              ? publicLegalsiteCustomizationSettings.cardsTitleSize
                              : null,
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleFont
                              ? publicLegalsiteCustomizationSettings.cardsTitleFont
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                true
                                ? 'italic'
                                : 'normal'
                              : 'normal',
                        }}
                      >
                        Request data transfer
                      </span>
                      <MdArrow
                        size={25}
                        color={
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.cardsIconsColor
                            ? publicLegalsiteCustomizationSettings.cardsIconsColor
                            : '#7450c8'
                        }
                      />
                    </div>
                    {customizationDontSellMyData ? (
                      <div
                        className="request-access__item"
                        style={{
                          background:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsBg
                              ? publicLegalsiteCustomizationSettings.cardsBg
                              : '#fafafa',
                        }}
                        onClick={() => this.showRequestForm('sell')}
                      >
                        <span
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.cardsTitleColor
                                ? publicLegalsiteCustomizationSettings.cardsTitleColor
                                : '#333',
                            fontSize:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.cardsTitleSize
                                ? publicLegalsiteCustomizationSettings.cardsTitleSize
                                : null,
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.cardsTitleFont
                                ? publicLegalsiteCustomizationSettings.cardsTitleFont
                                : null,
                            fontWeight:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.cardsTitleBold !==
                                null
                                ? publicLegalsiteCustomizationSettings.cardsTitleBold ===
                                  true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.cardsTitleItalic !==
                                null
                                ? publicLegalsiteCustomizationSettings.cardsTitleItalic ===
                                  true
                                  ? 'italic'
                                  : 'normal'
                                : 'normal',
                          }}
                        >
                          Do Not Sell My Personal Information
                        </span>
                        <MdArrow
                          size={25}
                          color={
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.cardsIconsColor
                              ? publicLegalsiteCustomizationSettings.cardsIconsColor
                              : '#7450c8'
                          }
                        />
                      </div>
                    ) : null}
                  </div>

                  {mail ? (
                    <p className="request-access__email">
                      Or send an e-mail to <a href={`mailto:${mail}`}>{mail}</a>
                    </p>
                  ) : null}
                </Fragment>
              ) : isComplaint ? (
                <ComplaintForm
                  showCheckEmailModal={this.showCheckEmailModal}
                  type={this.state.type}
                  accountId={accountId}
                  publicLegalsiteCustomizationSettings={
                    publicLegalsiteCustomizationSettings
                  }
                />
              ) : (
                <RequestForm
                  showCheckEmailModal={this.showCheckEmailModal}
                  type={this.state.type}
                  mail={mail}
                  accountId={accountId}
                  publicLegalsiteCustomizationSettings={
                    publicLegalsiteCustomizationSettings
                  }
                />
              )}
            </div>
          ) : null}
        </div>

        <CheckEmail
          showCheckEmailModal={this.showCheckEmailModal}
          isCheckEmailModalVisible={this.state.isCheckEmailModalVisible}
          showComplaint={
            isComplaint ? this.showComplaint : this.showRequestForm
          }
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
        />

        <ReceivedRequest
          isReceivedModalVisible={this.state.isReceivedModalVisible}
          showReceivedModal={this.showReceivedModal}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
        />

        <Footer
          subdomain={this.props.subdomain}
          publicLegalsiteCustomizationSettings={
            publicLegalsiteCustomizationSettings
          }
          color={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.subtitleColor
              : null
          }
          bg={
            publicLegalsiteCustomizationSettings
              ? publicLegalsiteCustomizationSettings.background1
              : null
          }
        />
      </div>
    );
  }
}

export default graphql(REQUEST_ACCESS_QUERY, {
  options: (props) => ({
    variables: {
      subdomain: props.subdomain,
    },
    fetchPolicy: 'no-cache',
  }),
})(RequestAccess);
