import React from "react";
import { Link } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Found from "../../images/NotFound/Group 4.svg";
import "./not-found.scss";
import LegalsiteNavbar from '../../components/LegalsiteNavbar/LegalsiteNavbar';

function NotFound(props) {
  return (
    <div className='not-found'>
      <LegalsiteNavbar subdomain={props.subdomain} />

      <div className='not-found__content container'>
        <div className='not-found__item'>
          <img src={Found} alt='not found' />
          <h3 className='not-found__item-title'>Page not found</h3>
          <p className='not-found__item-text'>
            The requested URL was not found on this
            <br />
            server.
          </p>
          <Link className='not-found__item-home' to='/'>
            Home page <MdArrow size={20} className='ml-2 my-0' />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
