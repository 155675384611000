import React from "react";
import { Link } from "react-router-dom";
import MdClose from "react-icons/lib/md/close";
import { Button, FormGroup, Input, Label } from "reactstrap";
import FontLoader from "../../containers/LegalSite/FontLoader";
import ReactHtmlParser from "react-html-parser";
import "./popup.scss";

function PopupContent(props) {
  const {
    publicLegalsiteCustomizationPopup,
    actualLegalsitePage,
    closePopup,
    handleCookieAccept,
    isAll,
    actualLegalsitePages,
    publicLegalsiteCustomizationSettings,
    account,
    widget,
  } = props;
  return (
    <div>
      <FontLoader
        font1={
          publicLegalsiteCustomizationPopup.titleFont
            ? publicLegalsiteCustomizationPopup.titleFont
            : "Roboto"
        }
        font2={
          publicLegalsiteCustomizationPopup.bodyFont
            ? publicLegalsiteCustomizationPopup.bodyFont
            : "Roboto"
        }
        font3={
          publicLegalsiteCustomizationPopup.secondaryButtonFont
            ? publicLegalsiteCustomizationPopup.secondaryButtonFont
            : "Roboto"
        }
        font4={
          publicLegalsiteCustomizationPopup.buttonFont
            ? publicLegalsiteCustomizationPopup.buttonFont
            : "Roboto"
        }
      />
      <div
        style={{
          background: publicLegalsiteCustomizationPopup.backgroundColor
            ? publicLegalsiteCustomizationPopup.backgroundColor
            : "#fafafa",
        }}
        className={`${
          publicLegalsiteCustomizationPopup.template === "squared popup"
            ? "customization-cookie__small"
            : publicLegalsiteCustomizationPopup.template === "vertical popup" ||
              !publicLegalsiteCustomizationPopup.template
            ? "customization-cookie__vertical"
            : publicLegalsiteCustomizationPopup.template === "overlay popup"
            ? "customization-cookie__overlay"
            : "customization-cookie__overlay customization-cookie__view"
        } position-relative`}
      >
        {publicLegalsiteCustomizationPopup.template !== "page view" ? (
          <MdClose
            className="popup__cookie-close"
            color={"#969696"}
            size={20}
            onClick={closePopup}
          />
        ) : null}
        {publicLegalsiteCustomizationPopup.template === "page view" ? (
          <div className="page-view__container position-relative">
            <MdClose
              className="page-view__close"
              color={"#969696"}
              size={20}
              onClick={closePopup}
            />
            {isAll && actualLegalsitePages && actualLegalsitePages.length ? (
              <div
                className="page-view__pages"
                style={{
                  backgroundColor: publicLegalsiteCustomizationSettings
                    ? publicLegalsiteCustomizationSettings.navigationBg
                    : null,
                }}
              >
                {actualLegalsitePages.map((item) =>
                  item.title !== "Data Processing Agreement" ? (
                    <p
                      key={item.id}
                      className="page-view__pages-title"
                      onClick={() => props.getPage(item.title)}
                      style={{
                        color: publicLegalsiteCustomizationSettings
                          ? publicLegalsiteCustomizationSettings.navigationLinksColor
                          : null,
                        fontSize: publicLegalsiteCustomizationSettings
                          ? publicLegalsiteCustomizationSettings.navigationSize
                          : null,
                        fontFamily: publicLegalsiteCustomizationSettings
                          ? publicLegalsiteCustomizationSettings.navigationFont
                          : null,
                        textAlign: publicLegalsiteCustomizationSettings
                          ? publicLegalsiteCustomizationSettings.navigationLinksAlign
                          : null,
                        fontWeight:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.navigationLinksBold
                            ? publicLegalsiteCustomizationSettings.navigationLinksBold ===
                              true
                              ? 900
                              : 300
                            : null,
                        fontStyle:
                          publicLegalsiteCustomizationSettings &&
                          publicLegalsiteCustomizationSettings.navigationLinksItalic
                            ? publicLegalsiteCustomizationSettings.navigationLinksItalic ===
                              true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      {item.title}
                    </p>
                  ) : null
                )}
              </div>
            ) : null}
            <div
              className="h-100 w-100 page-view__pages-container"
              style={{
                background:
                  publicLegalsiteCustomizationSettings &&
                  publicLegalsiteCustomizationSettings.bodyBg
                    ? publicLegalsiteCustomizationSettings.bodyBg
                    : "#f4f6fc",
              }}
            >
              <div
                className="page-view__header"
                style={{
                  background:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.headerBg
                      ? publicLegalsiteCustomizationSettings.headerBg
                      : "#dfe4ee",
                }}
              >
                <p
                  className="page-view__title"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleColor
                        ? publicLegalsiteCustomizationSettings.headerTitleColor
                        : "#333",
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleSize
                        ? publicLegalsiteCustomizationSettings.headerTitleSize
                        : 56,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleFont
                        ? publicLegalsiteCustomizationSettings.headerTitleFont
                        : null,
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerTitleAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerTitleBold ===
                          true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerTitleItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerTitleItalic ===
                          true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  {actualLegalsitePage ? actualLegalsitePage.title : null}
                </p>
                <p
                  className="page-view__subtitle"
                  style={{
                    color:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodyColor
                        ? publicLegalsiteCustomizationSettings.headerBodyColor
                        : "#969696",
                    fontSize:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodySize
                        ? publicLegalsiteCustomizationSettings.headerBodySize
                        : 16,
                    fontFamily:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodyFont
                        ? publicLegalsiteCustomizationSettings.headerBodyFont
                        : null,
                    textAlign:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodyAlign !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerBodyAlign
                        : null,
                    fontWeight:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodyBold !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerBodyBold ===
                          true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      publicLegalsiteCustomizationSettings &&
                      publicLegalsiteCustomizationSettings.headerBodyItalic !==
                        null
                        ? publicLegalsiteCustomizationSettings.headerBodyItalic ===
                          true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  {actualLegalsitePage ? actualLegalsitePage.description : null}
                </p>
              </div>
              <div
                className="page-view__content"
                style={{
                  color:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleColor
                      ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                      : "#333",
                  fontSize:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleSize
                      ? publicLegalsiteCustomizationSettings.bodySubtitleSize
                      : null,
                  fontFamily:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleFont
                      ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                      : "Roboto",
                  textAlign:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                      null
                      ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                      : null,
                  fontWeight:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                      null
                      ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                        true
                        ? 900
                        : 500
                      : 500,
                  background:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodyBg
                      ? publicLegalsiteCustomizationSettings.bodyBg
                      : "#f4f6fc",
                  fontStyle:
                    publicLegalsiteCustomizationSettings &&
                    publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                      null
                      ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                        true
                        ? "italic"
                        : "normal"
                      : "normal",
                }}
              >
                <div className="page-view__left-menu">
                  {actualLegalsitePage.sections
                    .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                    .map((item) => (
                      <p
                        key={item.id}
                        className="page-view__left-menu-item"
                        style={{
                          color:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.bodySubtitleColor
                              ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                              : "#333",
                          fontFamily:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.bodySubtitleFont
                              ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                              : "Roboto",
                          textAlign:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                              null
                              ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                              : null,
                          fontWeight:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                              null
                              ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            publicLegalsiteCustomizationSettings &&
                            publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                              null
                              ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        {item.title}
                      </p>
                    ))}
                </div>
                <div className="page-view__publication">
                  {actualLegalsitePage.sections
                    .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                    .map((item) => (
                      <div key={item.id}>
                        <p
                          className="page-view__publication-title"
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleColor
                                ? publicLegalsiteCustomizationSettings.bodySubtitleColor
                                : "#333",
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleFont
                                ? publicLegalsiteCustomizationSettings.bodySubtitleFont
                                : null,
                            textAlign:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleAlign !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleAlign
                                : null,
                            fontWeight:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleBold !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleBold ===
                                  true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodySubtitleItalic !==
                                null
                                ? publicLegalsiteCustomizationSettings.bodySubtitleItalic ===
                                  true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {item.title}
                        </p>
                        <p
                          className="page-view__publication-text"
                          style={{
                            color:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyTextColor
                                ? publicLegalsiteCustomizationSettings.bodyTextColor
                                : "#969696",
                            fontSize:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyTextSize
                                ? publicLegalsiteCustomizationSettings.bodyTextSize
                                : null,
                            fontFamily:
                              publicLegalsiteCustomizationSettings &&
                              publicLegalsiteCustomizationSettings.bodyTextFont
                                ? publicLegalsiteCustomizationSettings.bodyTextFont
                                : null,
                            // textAlign:
                            //   publicLegalsiteCustomizationSettings &&
                            //   publicLegalsiteCustomizationSettings.bodyTextAlign !==
                            //     null
                            //     ? publicLegalsiteCustomizationSettings.bodyTextAlign
                            //     : null,
                            // fontWeight:
                            //   publicLegalsiteCustomizationSettings &&
                            //   publicLegalsiteCustomizationSettings.bodyTextBold !==
                            //     null
                            //     ? publicLegalsiteCustomizationSettings.bodyTextBold ===
                            //       true
                            //       ? 900
                            //       : 500
                            //     : 500,
                            // fontStyle:
                            //   publicLegalsiteCustomizationSettings &&
                            //   publicLegalsiteCustomizationSettings.bodyTextItalic !==
                            //     null
                            //     ? publicLegalsiteCustomizationSettings.bodyTextItalic ===
                            //       true
                            //       ? 'italic'
                            //       : 'normal'
                            //     : 'normal',
                          }}
                        >
                          {ReactHtmlParser(item.text)}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={`${
            publicLegalsiteCustomizationPopup.template === "page view"
              ? "customization-cookie__page-view w-100"
              : !publicLegalsiteCustomizationPopup.template
              ? "w-100"
              : ""
          }`}
          style={
            publicLegalsiteCustomizationPopup.template === "page view"
              ? {
                  background: publicLegalsiteCustomizationPopup.backgroundColor
                    ? publicLegalsiteCustomizationPopup.backgroundColor
                    : "#fafafa",
                }
              : null
          }
        >
          <p
            style={{
              textAlign: publicLegalsiteCustomizationPopup
                ? publicLegalsiteCustomizationPopup.titleAlign
                : null,
              fontWeight:
                publicLegalsiteCustomizationPopup &&
                publicLegalsiteCustomizationPopup.titleBold
                  ? publicLegalsiteCustomizationPopup.titleBold === true
                    ? 900
                    : 300
                  : null,
              fontStyle:
                publicLegalsiteCustomizationPopup &&
                publicLegalsiteCustomizationPopup.titleItalic
                  ? publicLegalsiteCustomizationPopup.titleItalic === true
                    ? "italic"
                    : "normal"
                  : "normal",
              color: publicLegalsiteCustomizationPopup.titleColor
                ? publicLegalsiteCustomizationPopup.titleColor
                : "#7450C8",
              fontSize: publicLegalsiteCustomizationPopup.titleSize
                ? publicLegalsiteCustomizationPopup.titleSize
                : 22,
              fontFamily: publicLegalsiteCustomizationPopup.titleFont
                ? publicLegalsiteCustomizationPopup.titleFont
                : "Roboto",
            }}
            className="customization-cookie__popup-title"
          >
            {publicLegalsiteCustomizationPopup.title
              ? publicLegalsiteCustomizationPopup.title
              : actualLegalsitePage.title}
          </p>

          <p
            style={{
              color: publicLegalsiteCustomizationPopup.bodyColor
                ? publicLegalsiteCustomizationPopup.bodyColor
                : "#969696",
              fontSize: publicLegalsiteCustomizationPopup.bodySize
                ? publicLegalsiteCustomizationPopup.bodySize
                : 14,
              fontFamily: publicLegalsiteCustomizationPopup.bodyFont
                ? publicLegalsiteCustomizationPopup.bodyFont
                : "Roboto",
              textAlign: publicLegalsiteCustomizationPopup
                ? publicLegalsiteCustomizationPopup.bodyAlign
                : null,
              fontWeight:
                publicLegalsiteCustomizationPopup &&
                publicLegalsiteCustomizationPopup.bodyBold
                  ? publicLegalsiteCustomizationPopup.bodyBold === true
                    ? 900
                    : 300
                  : null,
              fontStyle:
                publicLegalsiteCustomizationPopup &&
                publicLegalsiteCustomizationPopup.bodyItalic
                  ? publicLegalsiteCustomizationPopup.bodyItalic === true
                    ? "italic"
                    : "normal"
                  : "normal",
            }}
            className="customization-cookie__popup-text"
          >
            {publicLegalsiteCustomizationPopup.body
              ? publicLegalsiteCustomizationPopup.body
              : actualLegalsitePage.description}
          </p>

          {publicLegalsiteCustomizationPopup.catchEmail ? (
            <FormGroup className="w-100">
              {publicLegalsiteCustomizationPopup.template ===
              "squared popup" ? (
                <Label className="customization-cookie__label mb-0">
                  E-mail:
                </Label>
              ) : null}
              <Input
                type="text"
                placeholder="Write your email"
                className="bg-white w-100 customization-cookie__input-text"
                onChange={(e) => props.getEmail(e.target.value)}
              />
            </FormGroup>
          ) : null}

          <div className="d-flex align-items-center">
            <Button
              onClick={handleCookieAccept}
              disabled={
                !props.email && publicLegalsiteCustomizationPopup.catchEmail
                  ? true
                  : false
              }
              className={`${
                publicLegalsiteCustomizationPopup.buttonShapes === "rounded"
                  ? "btn-round"
                  : ""
              } customization-cookie__popup-accept`}
              style={{
                color: publicLegalsiteCustomizationPopup.buttonColor
                  ? publicLegalsiteCustomizationPopup.buttonColor
                  : "#fff",
                fontSize: publicLegalsiteCustomizationPopup.buttonSize
                  ? publicLegalsiteCustomizationPopup.buttonSize
                  : 14,
                backgroundColor: publicLegalsiteCustomizationPopup.buttonBgColor
                  ? publicLegalsiteCustomizationPopup.buttonBgColor
                  : "#7450C8",
                fontFamily: publicLegalsiteCustomizationPopup.buttonFont
                  ? publicLegalsiteCustomizationPopup.buttonFont
                  : "Roboto",
                fontWeight:
                  publicLegalsiteCustomizationPopup &&
                  publicLegalsiteCustomizationPopup.buttonBold
                    ? publicLegalsiteCustomizationPopup.buttonBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  publicLegalsiteCustomizationPopup &&
                  publicLegalsiteCustomizationPopup.buttonItalic
                    ? publicLegalsiteCustomizationPopup.buttonItalic === true
                      ? "italic"
                      : "normal"
                    : "normal",
              }}
            >
              {publicLegalsiteCustomizationPopup.buttonText
                ? publicLegalsiteCustomizationPopup.buttonText
                : "ACCEPT"}
            </Button>
            <Link
              to={
                publicLegalsiteCustomizationPopup.title ===
                  "Cookie preferences" ||
                publicLegalsiteCustomizationPopup.title === "Legal preferences"
                  ? !widget && {
                      pathname: "/manage-consent",
                      state: { publicConsents: props.publicConsents },
                    }
                  : !widget && { pathname: "/" }
              }
              onClick={
                publicLegalsiteCustomizationPopup.title ===
                  "Cookie preferences" ||
                publicLegalsiteCustomizationPopup.title === "Legal preferences"
                  ? () =>
                      widget
                        ? window.open(
                            `http://${account}.legalsites.app/manage-consent`
                          )
                        : (window.location.href = "/manage-consent")
                  : () =>
                      widget
                        ? window.open(`http://${account}.legalsites.app/`)
                        : (window.location.href = props.queryString
                            ? `/page?page=${props.queryString}`
                            : "/")
              }
              className="customization-cookie__popup-options"
              style={{
                color: publicLegalsiteCustomizationPopup.secondaryButtonColor
                  ? publicLegalsiteCustomizationPopup.secondaryButtonColor
                  : "#7450C8",
                fontSize: publicLegalsiteCustomizationPopup.secondaryButtonSize
                  ? publicLegalsiteCustomizationPopup.secondaryButtonSize
                  : 14,
                fontFamily: publicLegalsiteCustomizationPopup.secondaryButtonFont
                  ? publicLegalsiteCustomizationPopup.secondaryButtonFont
                  : "Roboto",
              }}
            >
              {publicLegalsiteCustomizationPopup.secondaryButtonText
                ? publicLegalsiteCustomizationPopup.secondaryButtonText
                : publicLegalsiteCustomizationPopup.title ===
                  "Cookie preferences"
                ? "OPTIONS"
                : "SEE LEGALSITE"}
            </Link>
          </div>

          {publicLegalsiteCustomizationPopup &&
          publicLegalsiteCustomizationPopup.showLegalsiteBranding ? (
            <span
              onClick={() => window.open("http://legalsite.co/", "_blank")}
              style={{
                color: publicLegalsiteCustomizationPopup.secondaryButtonColor
                  ? publicLegalsiteCustomizationPopup.secondaryButtonColor
                  : "#7450C8",
                fontSize: publicLegalsiteCustomizationPopup.secondaryButtonSize
                  ? publicLegalsiteCustomizationPopup.secondaryButtonSize
                  : 12,
                fontFamily: publicLegalsiteCustomizationPopup.secondaryButtonFont
                  ? publicLegalsiteCustomizationPopup.secondaryButtonFont
                  : "Roboto",
              }}
              className={`${
                !publicLegalsiteCustomizationPopup.template
                  ? "w-100 d-flex justify-content-end"
                  : ""
              } customization-cookie__get`}
            >
              Get your LegalSite
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default PopupContent;
