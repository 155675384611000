import React, { Component, Fragment } from 'react';
import UserInfo from './UserInfo';
import { Button, Input } from 'reactstrap';
import MdClose from 'react-icons/lib/md/close';
import './requests.scss';
import RequestStats from './RequestStats';
import ReactFilestack from 'filestack-react';
import ThankYou from './ThankYou';
import { HANDLE_REQUEST } from '../../graphql/handleRequestWithToken';
import { graphql } from 'react-apollo';
import { UPLOAD_FILE } from '../../graphql/uploadFile';
import * as compose from 'lodash.flowright';

class DataErasure extends Component {
  static propTypes = {};

  state = {
    isSolve: false,
    selectedRequestFile: [],
    isCertify: false,
    fullname: null,
  };

  setCategory = value => {
    this.props.setCategory(value);
  };

  showStatus = status => {
    if (status === 'SOLVED') return 'Solved';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'ACTION_REQUIRED') return 'Action required';
    else if (status === 'DELAYED') return 'Delayed';
    else return status;
  };

  onFiles = res => {
    this.setState({ selectedRequestFile: res.filesUploaded });

    this.props
      .uploadFile({
        variables: {
          requestId: this.props.selectedRequest.id,
          type: 'INTERNAL_USER_DATA',
          url: this.state.selectedRequestFile[0].key,
          verificationToken: this.props.token,
        },
      })
      .catch(error => {
        console.log(error);
      });
  };

  submitData = action => {
    this.props
      .handleRequest({
        variables: {
          verificationToken: this.props.token,
          actions: action,
          certifiedBy: this.state.fullname,
        },
      })
      .then(() => {
        this.props.showThankYou(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { isSolve, selectedRequestFile, isCertify } = this.state;
    const { isShowThankYou, selectedRequest } = this.props;

    return !isShowThankYou ? (
      <div className="requests__request-container inbox">
        <UserInfo selectedRequest={selectedRequest} />

        <RequestStats
          selectedRequest={selectedRequest}
          showStatus={this.showStatus}
        />

        <Fragment>
          {!isSolve ? (
            <div className="inbox__data-actions">
              <p className="inbox__data-stats-label">
                Choose a course of action for this request:
              </p>

              <div className="inbox__data-actions-btns d-flex justify-content-between">
                <Button
                  className="btn--primary inbox__data-actions-solve"
                  onClick={() => this.setState({ isSolve: true })}
                >
                  Solve request
                </Button>

                <Button
                  className="btn--secondary inbox__data-actions-solve"
                  onClick={() => this.submitData('CANT_FIND_DATA')}
                >
                  Can't find data
                </Button>

                <Button
                  className="btn--secondary inbox__data-actions-solve"
                  onClick={() => this.submitData('DELAY')}
                >
                  Delay
                </Button>
              </div>
            </div>
          ) : (
            <div className="inbox__data-actions">
              <p className="inbox__data-stats-label">
                Click on the checkbox if you have already erased the user’s data
                from your company’s internal systems, and you have the option to
                send us a data erasure confirmation log.
              </p>

              <div className="d-flex align-items-center inbox__create mt-5 mb-5">
                <span
                  className={`${
                    isCertify ? '-is-active' : ''
                  } inbox__create-check mr-3`}
                  onClick={() =>
                    this.setState({ isCertify: !this.state.isCertify })
                  }
                />
                I certify that we have erased this person’s data from our
                company's systems, including our data processors.
              </div>

              <Input
                className="mb-5"
                type="text"
                placeholder="Enter your full name"
                onChange={e => this.setState({ fullname: e.target.value })}
                name="fullname"
              />

              <p className="inbox__data-stats-label mb-2">Upload file</p>
              <ReactFilestack
                apikey={'AJNM9qOpGRljTn17sgxrfz'}
                componentDisplayMode={{
                  type: 'link',
                  customText: (
                    <Button className="btn--primary inbox__internal-btn">
                      Upload file
                    </Button>
                  ),
                  customClass: 'show-upload',
                }}
                onSuccess={res => this.onFiles(res)}
                actionOptions={{
                  fromSources: [
                    'local_file_system',
                    'googledrive',
                    'dropbox',
                    'onedrive',
                  ],
                  accept: [
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ],
                  maxFiles: 1,
                }}
              />

              <div className="d-flex flex-wrap justify-content-between">
                {selectedRequestFile && selectedRequestFile.length ? (
                  <div className="inbox__internal-file mb-4">
                    <div className="inbox__internal-file-type">DOC</div>
                    <p className="inbox__internal-file-name">
                      {selectedRequestFile[0].key}
                    </p>
                    {/* <MdClose
                      onClick={() => this.removeFile()}
                      className="inbox__internal-file-remove"
                      size={20}
                      color={'#969696'}
                    /> */}
                  </div>
                ) : null}
              </div>

              <div className="d-flex mt-5 inbox__solve-actions">
                <Button
                  className="btn--primary mr-4"
                  onClick={() => this.submitData('SEND')}
                >
                  Send
                </Button>
                <Button
                  className="btn--secondary mr-4"
                  onClick={() => this.submitData('CANT_FIND_DATA')}
                >
                  Can't find data
                </Button>
                <Button
                  className="btn--secondary mr-4"
                  onClick={() => this.submitData('DELAY')}
                >
                  Delay
                </Button>
              </div>
            </div>
          )}
        </Fragment>
      </div>
    ) : (
      <ThankYou />
    );
  }
}

export default compose(
  graphql(HANDLE_REQUEST, {
    name: 'handleRequest',
  }),
  graphql(UPLOAD_FILE, {
    name: 'uploadFile',
  })
)(DataErasure);
