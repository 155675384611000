import gql from 'graphql-tag';

export const REQUEST_QUERY = gql`
  query($subdomain: String!) {
    publicLegalsite(subdomain: $subdomain) {
      companyLogo
      id
      access
      customizationLegalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnBg
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        cardsBg
        cardsBodyAlign
        cardsBodyBold
        cardsBodyColor
        cardsBodyFont
        cardsBodyItalic
        cardsBodySize
        cardsBtnAlign
        cardsBtnBg
        cardsBtnBold
        cardsBtnColor
        cardsBtnFont
        cardsBtnItalic
        cardsBtnSize
        cardsIconsColor
        cardsTitleAlign
        cardsTitleBold
        cardsTitleColor
        cardsTitleFont
        cardsTitleItalic
        cardsTitleSize
        footerBg
        footerLinksAlign
        footerLinksBold
        footerLinksColor
        footerLinksFont
        footerLinksItalic
        footerLinksSize
        footerLogo
        footerTextAlign
        footerTextBold
        footerTextColor
        footerTextFont
        footerTextItalic
        footerTextSize
        headerBg
        homepageTitle
        homepageDescription
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        navigationBg
        navigationLinksAlign
        navigationLinksBold
        navigationLinksColor
        navigationLinksFont
        navigationLinksItalic
        navigationLinksSize
      }
      actualPages {
        description
        updatedAt
        id
        title
        version
        sections {
          position
          id
          title
          text
        }
      }
    }
  }
`;
